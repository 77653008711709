 import apiService from "../../service/apiService";
 //import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        items: [],
        pageitems: [],
        searched_items:[],
        inbound_searched_items:[],
        po_searched_items:[],
        stock_adjustment_searched_items:[],
        internaltransfer_searched_items:[],
        mr_searched_items:[],
        pfilters:{
            searchprdtname:'',
            searchprdtcode:'',
            viewinactive:false,
        }

    }),
    mutations: {

        setItems(state, data) {
            state.items = data

        },
        setpagetems(state, data) {
            state.pageitems = data

        },
        resetinboundsearchitems(state) {
            state.inbound_searched_items =[]

        },
        resetposearchitems(state) {
            state.po_searched_items =[]

        },
        resetstockadjustmentsearchitems(state) {
            state.stock_adjustment_searched_items =[]

        },
        resetinternaltransfersearchitems(state) {
            state.internaltransfer_searched_items =[]

        },
        resetmrsearchitems(state) {
            state.mr_searched_items =[]

        },
        addSearchedItems(state,data){
            let mapid=Math.random()
            let original_price=null
            if(data.original_price){
               // alert(data.original_price)
                original_price= data.original_price
            } else {
                original_price=data.defaultPrice
            }
            let datawithmapid = { ...data, original_price:original_price, mapid: mapid };
            state.searched_items.push(datawithmapid)
        },
        addInboundSearchedItems(state,data){

            state.inbound_searched_items.push(data)
        },
        addpoSearchedItems(state,data){

            state.po_searched_items.push(data)
        },
        addStockAdjustmentSearchedItems(state,data){
            let mapid=Math.random()
            let datawithmapid = { ...data, mapid: mapid };
            state.stock_adjustment_searched_items.push(datawithmapid)
        },
        addmrSearchedItems(state,data){
            let mapid=Math.random()
            let datawithmapid = { ...data, mapid: mapid };
            state.mr_searched_items.push(datawithmapid)
        },
        addInternalTransferSearchedItems(state,data){
            let mapid=Math.random()
            let datawithmapid = { ...data, mapid: mapid, qty:data.box_qty };
            state.internaltransfer_searched_items.push(datawithmapid)
        },
        setforeditInternalTransferSearchedItems(state,data){
            let data2=data.map((item)=>{
                let mapitem={...item,mapid:Math.random()}
                return mapitem
            })
            state.internaltransfer_searched_items=data2
        },
        setforeditinboundSearchedItems(state,data){
            let data2=data.map((item)=>{
                let mapitem={...item,mapid:Math.random()}
                return mapitem
            })
            state.inbound_searched_items=data2
        },
        removeItem(state, map_id){
            console.log(state.searched_items,map_id)
          //  alert('removing')
           const filtered= state.searched_items.filter((item)=>item.mapid!=map_id)
            state.searched_items = filtered
        },
        removeinboundsearchedItem(state, map_id){
          //  console.log(state.searched_items,map_id)
            //  alert('removing')
            const filtered= state.inbound_searched_items.filter((item)=>item.mapid!=map_id)
            state.inbound_searched_items = filtered
        },
        removeposearchedItem(state, map_id){
            //  console.log(state.searched_items,map_id)
            //  alert('removing')
            const filtered= state.po_searched_items.filter((item)=>item.mapid!=map_id)
            state.po_searched_items = filtered
        },

        removestockadjustmentsearchedItem(state, map_id){
            //  console.log(state.searched_items,map_id)
            //  alert('removing')
            const filtered= state.stock_adjustment_searched_items.filter((item)=>item.mapid!=map_id)
            state.stock_adjustment_searched_items = filtered
        },
        removeinternaltransfersearchedItem(state, map_id){
            //  console.log(state.searched_items,map_id)
            //  alert('removing')
            const filtered= state.internaltransfer_searched_items.filter((item)=>item.mapid!=map_id)
            state.internaltransfer_searched_items = filtered
        },
        removemrsearchedItem(state, map_id){
            //  console.log(state.searched_items,map_id)
            //  alert('removing')
            const filtered= state.mr_searched_items.filter((item)=>item.mapid!=map_id)
            state.mr_searched_items = filtered
        },
        attachinvoiceitems(state, data){
            state.searched_items = data
        }


    },
    actions: {

        async getItems({commit}) {
            const {data} = await apiService.get(`/items`);


            let  itemdata=data.map((d)=>{
                let mapid=Math.random()
                return { ...d, qty: 1, picked:0 , mapid:mapid};
            })
            // this.items = itemdata;
           // this.$store.commit('items/setItems', itemdata);
            commit('setItems', itemdata)
            // console.log('ok')
        },
        // async getAllItems({commit},filters={}) {
        //     const apiToken = localStorage.getItem('token');
        //     if (apiToken) {
        //         // Set the Authorization header with the API token
        //         axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
        //     }
        //     const {data} = await apiService.post(`/allitems`,filters);
        //
        //
        //     let  itemdata=data.data.map((d)=>{
        //         let mapid=Math.random()
        //         return { ...d, qty: 1, picked:0 , mapid:mapid};
        //     })
        //     // this.items = itemdata;
        //     // this.$store.commit('items/setItems', itemdata);
        //     commit('setAllItems', itemdata)
        //     // console.log('ok')
        // },

        set_page_items({commit},item){
            commit('setpagetems', item)

        },

        add_searched_items({commit},item){
            commit('addSearchedItems', item)

        },
        add_inbound_searched_items({commit},item){
            commit('addInboundSearchedItems', item)

        },
        add_po_searched_items({commit},item){
            commit('addpoSearchedItems', item)

        },
        add_mr_searched_items({commit},item){
            commit('addmrSearchedItems', item)

        },
        add_stock_adjustment_searched_items({commit},item){
            commit('addStockAdjustmentSearchedItems', item)

        },
        add_internal_transfer_searched_items({commit},item){
            commit('addInternalTransferSearchedItems', item)

        },
        setforedit_internal_transfer_searched_items({commit},items){
            commit('setforeditInternalTransferSearchedItems', items)

        },
        setforedit_inbound_searched_items({commit},items){
            commit('setforeditinboundSearchedItems', items)

        },
        reset_inbound_searched_items({commit}){
            commit('resetinboundsearchitems')

        },
        reset_po_searched_items({commit}){
            commit('resetposearchitems')

        },
        reset_stock_adjustment_searched_items({commit}){
            commit('resetstockadjustmentsearchitems')

        },
        reset_internal_transfer_searched_items({commit}){
            commit('resetinternaltransfersearchitems')

        },
        reset_mr_searched_items({commit}){
            commit('resetmrsearchitems')

        },
        removeSearchedItem({commit},item_id){
            commit('removeItem', item_id)
        },
        removeinboundSearchedItem({commit},item_id){
            commit('removeinboundsearchedItem', item_id)
        },
        removepoSearchedItem({commit},item_id){
            commit('removeposearchedItem', item_id)
        },

        removestockadjustmentSearchedItem({commit},item_id){
            commit('removestockadjustmentsearchedItem', item_id)
        },
        removeinternaltransferSearchedItem({commit},item_id){
            commit('removeinternaltransfersearchedItem', item_id)
        },
        removemrSearchedItem({commit},item_id){
            commit('removemrsearchedItem', item_id)
        },

        attachinvoiceitems({commit},attacheditems){

          let itemsdata=attacheditems.map((d)=>{
              let mapid=Math.random()
                return { ...d, qty: d.pivot.quantity,picked: d.pivot.picked, itemDescription: d.pivot.itemDescription, mapid:mapid, defaultPrice:d.pivot.unitPrice, discount_applied:d.pivot.discount_applied,  original_price:d.pivot.original_price, max_discount:d.pivot.max_discount,discount:d.pivot.discount};
            })
            commit('attachinvoiceitems', itemsdata)
        }
    },

    // watch: {
    //     searched_items: {
    //         deep: true,
    //         handler(newVal) {
    //             newVal.forEach((item, index) => {
    //                 console.log(item.qty, item.picked, index)
    //             });
    //         },
    //     },
    // },

    getters: {
        subTotal: (state) => {
            return (state.searched_items.reduce((total, item) => total + item.defaultPrice * item.qty, 0)).toFixed(2);
        }
    }
}
