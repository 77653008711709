<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">
                <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
               <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 mt-2 pb-2 bgsecond">

                <div class="row   text-light">

                    <div class="col-md-8">
                        <b-form @submit.prevent="saveCustomer">
                            <div class="row ">
                                <h1 class="ml-1 mt-1">
                                    <span v-if="actiontype=='Save'">Enter</span>
                                    <span v-if="actiontype=='Update'">Update</span>
                                    Customer Details </h1>
                            </div>
                               <div class="row">
                                   <div class="col-md-6">
                                       <label for="firstName" class="form-label">Full Name</label>
                                       <b-form-input v-model="customer.lastName" type="text" class="form-control" id="firstName" required :disabled="actiontype=='View'"> </b-form-input>
                                   </div>
                                   <div class="col-md-3">
                                       <label for="title" class="form-label">Title</label>
                                       <b-form-input v-model="customer.title" type="text" class="form-control" id="title" :disabled="actiontype=='View'"> </b-form-input>
                                   </div>

<!--                                   <div class="col-md-3">-->
<!--                                       <label for="customerCode" class="form-label">Customer Code</label>-->
<!--                                       <b-form-input v-model="customer.customerCode" type="text" class="form-control" id="customerCode" :disabled="actiontype=='View'"> </b-form-input>-->
<!--                                   </div>-->
                                   <div class="col-md-3">
                                       <label for="mobile" class="form-label">Mobile</label>
                                       <b-form-input v-model="customer.mobile" type="text" class="form-control" id="mobile" :disabled="actiontype=='View'"> </b-form-input>
                                   </div>
                               </div>

                            <div class="row mt-3">
                                <div class="col-md-3">
                                    <label for="companyName" class="form-label">Company Name</label>
                                    <b-form-input v-model="customer.companyName" type="text" class="form-control" id="companyName" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="customerType" class="form-label">Customer Type</label>
                                    <b-form-select
                                        v-model="customer.customerType"
                                        :options="[
                                             { value: 'Retail', text: 'Retail' },
                                             { value: 'Trade', text: 'Trade' },
                                        ]"



                                        class=""
                                        :disabled="actiontype=='View'"

                                    >



                                    </b-form-select>
                                </div>

                                <div class="col-md-3">
                                    <label for="cashier" class="form-label">Cashier</label>
                                    <b-form-select
                                        v-model="customer.cashier_id"
                                        :options="cashiers"


                                        class=""
                                        :disabled="actiontype=='View'"

                                        value-field="id"
                                        text-field="fullName"
                                    >
                                        <template #first>
                                            <b-form-select-option value="">Select Cashier</b-form-select-option>
                                        </template>
                                        <template #option="{ option }">
                                            <div>{{ option.fullName }}</div>
                                        </template>

                                    </b-form-select>
                                </div>
                                <div class="col-md-3">
                                    <label for="email" class="form-label">Email</label>
                                    <b-form-input v-model="customer.email" type="text" class="form-control" id="email" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-3" v-if="authenticatedUser.permissions.includes('super')">
                                    <label for="postcode" class="form-label">Balance</label>
                                    <b-form-input v-model="customer.walletBalance" type="text" class="form-control" id="balance" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="postcode" class="form-label">Customer Discount</label>
                                    <b-form-input v-model="customer.cusDiscount" @keyup="max_dis(customer.cusDiscount)" @change="max_dis(customer.cusDiscount)" type="number" class="form-control" id="balance" :disabled="actiontype=='View'"> </b-form-input>
                                </div>


                                <div class="col-md-3">
                                    <label for="postcode" class="form-label">Company Number (optional)</label>
                                    <b-form-input v-model="customer.companyNumber"   type="number" class="form-control"  :disabled="actiontype=='View'"> </b-form-input>
                                </div>
<!--                                <div class="col-md-3">-->
<!--                                    <label for="postcode" class="form-label">Contact Name</label>-->
<!--                                    <b-form-input v-model="customer.contactPerson"  type="text" class="form-control" :disabled="actiontype=='View'"> </b-form-input>-->
<!--                                </div>-->


                                <div class="col-md-3">
                                    <label for="status" class="form-label">Status</label>
                                    <b-form-checkbox v-model="customer.status" name="check-button" switch class="mt-1" :disabled="actiontype=='View'">

                                    </b-form-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <label for="card" class="form-label">Assign Trade Card</label>
                                    <v-select
                                        v-if="!customer.trade_card"
                                        v-model="cardId"

                                        class="text-dark"
                                        placeholder="Choose Trade Card"
                                        :options="tradecards"
                                        label="trade_card_no"
                                        :reduce="option => option.id"

                                    />
                                    <b-form-input v-else="" v-model="customer.trade_card.trade_card_no" type="text" class="form-control" id="addressLine1" :disabled="true" > </b-form-input>
                                </div>
                                <div class="col-md-3"  v-if="authenticatedUser.permissions.includes('super')">
                                    <label for="card" class="form-label">Branch</label>
                                    <v-select

                                        v-model="customer.branch_id"

                                        class="text-dark"
                                        placeholder="Select Branch"
                                        :options="branches"
                                        label="name"
                                        :reduce="option => option.id"

                                    />

                                </div>
                            </div>
                            <div class="row mt-3">
                                <h3 class="col-md-12"> Shipping Address</h3>
                                <div class="col-md-3">
                                    <label for="addressLine1" class="form-label">AddressLine 1</label>
                                    <b-form-input v-model="customer.addressLine1" type="text" class="form-control" id="addressLine1" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="addressLine1" class="form-label">AddressLine 2</label>
                                    <b-form-input v-model="customer.addressLine2" type="text" class="form-control" id="addressLine1" :disabled="actiontype=='View'" > </b-form-input>
                                </div>

                                <div class="col-md-3">
                                    <label for="postcode" class="form-label">Post Code</label>
                                    <b-form-input v-model="customer.postcode" type="text" class="form-control" id="postcode" :disabled="actiontype=='View'"> </b-form-input>
                                </div>


                            </div>
                            <div class="row mt-3">
                                <h3 class="col-md-12">Registered Address</h3>
                                <div class="col-md-3">
                                    <label for="addressLine1" class="form-label">Registered AddressLine 1</label>
                                    <b-form-input v-model="customer.raddressLine1" type="text" class="form-control" id="addressLine1" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="addressLine1" class="form-label"> Registered AddressLine 2</label>
                                    <b-form-input v-model="customer.raddressLine2" type="text" class="form-control" id="addressLine1" :disabled="actiontype=='View'" > </b-form-input>
                                </div>

                                <div class="col-md-3">
                                    <label for="postcode" class="form-label"> Registered Post Code</label>
                                    <b-form-input v-model="customer.rpostcode" type="text" class="form-control" id="postcode" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <label for="textarea" class="form-label">Notes</label>
                                    <b-form-textarea
                                        id="textarea"
                                        v-model="customer.notes"
                                        placeholder=""
                                        rows="3"
                                        max-rows="6"
                                        :disabled="actiontype=='View'"
                                    ></b-form-textarea>
                                </div>
                            </div>


                            <button v-if="actiontype!='View'" type="submit" class="btn btn-primary mt-3"> {{actiontype}} Customer</button>
                            <img  v-else class="mt-3 cursor-pointer" src="@/assets/images/downbaricons/newpos.png" @click="newpos"  >
                        </b-form>

                    </div>

                    <div class="col-md-4">
                        <div class="row mt-5">
                           <div class="col-md-6">

                           </div>
                            <div class="col-md-5 mt-5" style="text-align: center; border: 6px solid green;">
                                <h1 >Orders</h1>
                                <h1>{{(this.customer.invoices)?(this.customer.invoices).length:0}}</h1>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-md-6">

                            </div>
                            <div class="col-md-5 mt-5" style="text-align: center; border: 6px solid green;">
                                <h1 >Revenue</h1>
                                <h1>{{revenue.toFixed(2)}}</h1>
                            </div>
                        </div>
                    </div>

                </div>



            </div>

            <div class="col-md-11 offset-md-1 mt-2" v-if="customer.invoices">
                <h1 class="text-light mt-2 text-capitalize">{{customer.lastName}} Orders</h1>

                <b-table id="my-table"  class="bg-light" :items="filteredOrders" :fields="fields" striped hover responsive :per-page="perPage" :current-page="currentPage">

                    <template #cell(customer)="data">
                        {{data.item.customer.lastName}}
                    </template>
                    <template #cell(cashier)="data">
                        {{data.item.cashier.fullName}}
                    </template>
<!--                    <template #cell(actions)="data">-->
<!--                        <b-button class="btn-info" @click="loadorder(data.item)">Load Order </b-button>-->
<!--                    </template>-->

                </b-table>
            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import {mapActions, mapState} from "vuex";
import { mapGetters } from 'vuex';
//import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import axios from "axios";
import {cloneDeep} from "lodash";
import Swal from "sweetalert2";
import showrealtime from "@/components/showrealtime";
import URL from "@/constants/baseurl"
import newinvoice from "@/constants/newinvoice";
//import newcustomer from "@/constants/newcustomer";
import newpayment from "@/constants/newpayment";
import newitem from "@/constants/newitem";
import vSelect from "vue-select";
import apiService from "@/service/apiService";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
//import vSelect from "vue-select";


export default {
    name: "customerform",

    components:{Welcomenote, 'v-select': vSelect, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['cashiers','branches']),
        ...mapState('customers', ['customers']),

        // ...mapState('invoice', ['branches']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },
        revenue() {
            if(this.customer.invoices){
                let paidinv=cloneDeep(this.customer.invoices).filter(pi=>pi.status!='Quote')
                return paidinv.reduce(
                    (total, invoice) => total + Number(invoice.grandTotal),
                    0
                );
            }
            else return 0

        },
        filteredOrders() {
            let forders=''
            if (this.searchQuery) {
                //  alert(this.cashier_id)
                const query = this.searchQuery.toLowerCase();
                forders =this.customer.invoices.filter(order => {
                    //   const mob = order.customer.mobile ? order.customer.mobile.toLowerCase() : '';
                    //  const name = order.customer.lastName ? order.customer.lastName.toLowerCase() : '';
                    //   const adr1 = order.customer.addressLine1 ? order.customer.addressLine1.toLowerCase() : '';
                    //   const adr2 = order.customer.addressLine2 ? order.customer.addressLine2.toLowerCase() : '';
                    //   const pscode = order.customer.postcode ? order.customer.postcode.toLowerCase() : '';


                    let filteredItems=''
                    if(order.items){
                        filteredItems = order.items.filter(item =>
                            item.itemCode.toLowerCase().includes(query) ||
                            item.itemName.toLowerCase().includes(query) ||
                            item.pivot.itemDescription.toLowerCase().includes(query)
                        );

                    }
                    // order.invoice_ref.toLowerCase().includes(query) ||
                    // order.cashier.fullName.toLowerCase().includes(query) ||
                    //  order.customer.lastName.toLowerCase().includes(query) ||
                    return   filteredItems.length > 0
                    //   mob.toLowerCase().includes(query) ||
                    // adr1.toLowerCase().includes(query) ||
                    // adr2.includes(query) ||
                    // name.includes(query) ||
                    // pscode.includes(query)





                    //  order.status.toLowerCase().includes(query)
                    // order.issueDate.includes(query)
                })



            } else {
                forders=this.customer.invoices;

            }

            if(this.branch_id){
                forders=forders.filter(dfo=>dfo.branch_id===this.branch_id)
            }
            if(this.cashier_id){
                forders=forders.filter(dfo=>dfo.cashier_id===this.cashier_id)
            }
            if(this.order_status){
                forders=forders.filter(dfo=>dfo.status===this.order_status)
            }
            if(this.searchcustname){
                let searchcustname=this.searchcustname.toLowerCase();

                forders=forders.filter(dfo=> {
                    const name = dfo.customer.lastName ? dfo.customer.lastName.toLowerCase() : '';
                    return name.includes(searchcustname)
                })
            }
            if(this.searchcustphone){
                let searchcustphone=this.searchcustphone.toLowerCase();

                forders=forders.filter(dfo=> {
                    const mob = dfo.customer.mobile ? dfo.customer.mobile.toLowerCase() : '';
                    return mob.includes(searchcustphone)
                })
            }
            console.log(forders,'forders')
            return forders
        },
        actiontype(){
            if (this.$route.query.type=='add'){
                return 'Save'
            }
            else if (this.$route.query.type=='edit'){
                return 'Update'
            }
            else {
                return 'View'
            }
        }

    },
    data() {
        return {
            roles: [],
            user: {},
            perPage:10,
            currentPage: 1,
            tradecards:[],
            cardId:'',
            customer: {
                id:"",
                branch_id: "",
                cashier_id: "",
                title: "",
                firstName: "",
                lastName: "",

                mobile: "",
                companyName: "",
                customerType: "",
                discountType: 1,
                addressLine1: "",
                addressLine2: "",
                postcode: "",
                raddressLine1: "",
                raddressLine2: "",
                rpostcode: "",

                email: "",
                notes: "",
                status: true,
                walletBalance: "",
                custDiscount: "",
            },
            fields: [

                { key: 'invoice_ref', label: 'Order ID' },
                { key: 'issueDate', label: 'Date' },
                { key: 'customer', label: 'Customer' },
                { key: 'cashier', label: 'Cashier' },
                { key: 'status', label: 'Status' },
               // 'actions'

            ],


        };
    },

    async created() {
        await this.getUsers();
        await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()
        this.viewType = this.$route.query.type;
        this.customer_id = this.$route.query.id;
        console.log("View type:", this.viewType);
        console.log("Customer ID:", this.customer_id);
        await this.getavailabletradecards()
        this.setCustomer()
        this.customer.status= this.customer.status==1 ? true:false



    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['updateFullInvoice']),
        ...mapActions('payment', ['setNewpayment']),
        ...mapActions('items',['add_searched_items','attachinvoiceitems']),
        ...mapActions('payment', ['setNewpayment']),
          async getavailabletradecards(){
               const {data} = await apiService.get(`/getavailabletradecards`);
               this.tradecards=data
           },
        max_dis(dis){
            let disc=Number(dis)

            if(disc>20){

               this.customer.cusDiscount=20
            }
            if(disc<0){
                this.customer.cusDiscount=0
            }

        },

        async signOut() {
            await this.processLogoutManual();
          //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },

        newpos(){
            let newinv=  cloneDeep(newinvoice)
            this.updateFullInvoice(newinv)

            this.attachinvoiceitems([])
            //  this.selectedCus('0123456789','yes');
            this.$store.commit('customers/setSelectedCustomer', cloneDeep(this.customer))

           //this.addsearchitem();
            this.add_searched_items(cloneDeep(newitem))

            this.setNewpayment(cloneDeep(newpayment))
            this.$router.push({ path: '/' })


        },

        async saveCustomer() {
            if(this.cardId){
                if(!this.customer.companyName){
                    Swal.fire('Error', 'Company name Is Required', 'error');
                    return
                }
                // if(!this.customer.contactPerson){
                //     Swal.fire('Error', 'Contact name Is Required', 'error');
                //     return
                // }
                if(!this.customer.raddressLine1){
                    Swal.fire('Error', 'Registered Address line 1 Is Required', 'error');
                    return
                }
                if(!this.customer.raddressLine2){
                    Swal.fire('Error', 'Registered Address line 2 Is Required', 'error');
                    return
                }
                if(!this.customer.rpostcode){
                    Swal.fire('Error', 'Registered Post Code Is Required', 'error');
                    return
                }
            }
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
            let customerdata=cloneDeep(this.customer)

            let cbranch_id;
            if(this.authenticatedUser.permissions.includes('super')){
                cbranch_id=this.customer.branch_id
            }
            else {
                cbranch_id=this.authenticatedUser.branch.id
            }

                customerdata.branch_id=cbranch_id
            let data={
                id:this.customer_id,
                customer:customerdata,
                tradecardid:this.cardId
            }
            try {
                const response = await axios.post(`${URL.BASEURL}/api/create-customer`,  data);
                console.log("Customer saved:", response.data);
               await Swal.fire('Success', 'Customer added successfully!', 'success');
                this.$router.push({ path: '/customers' })

                // this.customer = {
                //     id:"",
                //     branch_id: "",
                //     cashier_id: "",
                //     title: "",
                //     firstName: "",
                //     lastName: "",
                //     customerCode: "",
                //     mobile: "",
                //     companyName: "",
                //     customerType: "",
                //     discountType: 1,
                //     addressLine1: "",
                //     addressLine2: "",
                //     postcode: "",
                //     email: "",
                //     notes: "",
                //     status: true
                // };
            } catch (error) {
                console.error("Error saving customer:", error);
            }
        },
        setCustomer(){
            if(this.viewType!=='add'){
                let cus=cloneDeep(this.customers).filter(c=>c.id==this.customer_id)
                this.customer=cus[0]
                if(this.customer.trade_card){
                    this.cardId=this.customer.trade_card.id
                }

                // this.cardId=this.tradecards.find((c)=>c.assigned_customer==this.customer_id).id
              //  console.log(this.tradecards,'======================')
            }
        }
    }
};
</script>



<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
