export default {
    "invoice_id":null,
    "invoice_ref":null,
    "cashier_id":null,
    "payment_type": null,
    "reference":null,
    "payment_amount":0,
    "customer_id":null,
    "date":null
}
