<template>
    <div>
        <Header/>
        <PageHeader>
            <template #left>
                <b-icon-clipboard-plus/>
                Dashboard
            </template>
            <!--            <template #right>
                            <b-ico Alarmsn-clipboard-plus />

                        </template>-->
        </PageHeader>
        <b-container fluid>

        </b-container>
    </div>
</template>

<script>
import moment from "moment";



// import DatePicker from "vue2-datepicker";
// import DevicesDropdown from "@/components/DevicesDropdown";
import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";


export default {
    name: "Dashboard",
    components: {
        Header,
        PageHeader,

    },
    data() {
        return {

        };
    },
    created() {

    },
    mounted() {

    },

    computed: {},

    methods: {

    },
    filters: {
        moment: function (date) {
            return moment(date).format("MMM D h:mm a");
        },
    },
};
</script>

<style lang="scss">
//.barChart{height: 33vh; width: 100%}
.battery {
    transform: rotate(-90deg);
    font-size: 1.4rem;
}

.display-5 {
    font-size: 1.3rem;
}

@media all and (max-width: 1200) {
    .battery {
        font-size: 1.1rem;
    }
    .display-5 {
        font-size: 1rem;
    }
}
</style>
