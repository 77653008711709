


export default {
    namespaced: true,
    state: () => ({


        selected_poprint:null

    }),
    mutations: {



        setselected_poprint(state,data){
            state.selected_poprint=data
        },



    },
    actions: {




        add_selected_poprint({commit},selected_poprint){
            commit('setselected_poprint', selected_poprint)
        },

    },


}
