import apiService from "../../service/apiService";

export default {
    namespaced: true,
    state: () => ({
        display_alarm: false,
        muted: true,
        alarm_data: {},
        alarm_type:false,
    }),
    mutations: {
        setAlarmStatus(state, status) {
            state.display_alarm = status;
        },
        toggleMuteValue(state) {
            state.muted = !state.muted;
        },
        setAlarmData(state, data) {
            state.alarm_data = data
        },
        setAlarmType(state, data) {
            state.alarm_type = data
        }
    },
    actions: {
        async activate_audio({commit}){
            commit('toggleMuteValue')
        },
        async checkAlarmStatus({commit}) {
            const {data} = await apiService.get(`check_alarm_polling`);
            console.log(data.data);
            if (Object.keys(data.data.alarms).length > 0) {
                commit('setAlarmStatus',true)
                commit('setAlarmType',data.data.alarm_notify_type)
            }
            else if (Object.keys(data.data.system_alarm).length > 0){
                commit('setAlarmStatus',true)
                commit('setAlarmType',5)
            }
            else {


                commit('setAlarmStatus',false)
                commit('setAlarmType',false)
            }
        }
    },
    getters: {}
}
