<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
  <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white">





                <div class="row" v-if="showloader=='yes'">
                    <div class="col-md-12 text-center">
                        <loader></loader>
                    </div>
                </div>
                <div class="row " v-else="">
                    <div class="col-md-12">
                        <div class="col-md-3   text-dark">
                            <label class="text-light">
                                Select Branch
                            </label>
                            <v-select
                                v-model="selectedBranch"

                                class=""
                                placeholder="Choose Branch"
                                :options="userbranches"

                                label="name"
                                :reduce="option => option.id"
                                @input="getguide(false)"

                            />

                        </div>
                    </div>
                    <template v-for="location in result['locations']">
                    <div class="col-md-12 mt-1 p-1  "  :key="location.id" style="border: 1px solid green; border-radius: 5px" v-if=" result[location.id]">

                           <h1 class="text-center text-light">{{location.location_name}}</h1>
                           <table  class="table text-light ">
                               <thead >
                               <tr>
                                   <th></th>
                                   <th colspan="2" class="border">Farn Borough Stock</th>
                                   <th colspan="3" class=""></th>
                               </tr>
                               <th class="col-md-2">Item</th>
                               <th class="col-md-2">Description</th>
                               <th>Current</th>
                               <th>ForeCast</th>
                               <th>Needed</th>
                               <th v-if="location.id!='7'">Internal Transfer</th>
                               <th v-if="location.id!='7'">Pending Transfer</th>

                               </thead>

                               <tbody >

                      <template v-for="item in result[location.id]" >
                          <tr   :key="item.itemCode" v-if="Number(item.needed)>0" :class="item.needed<=item.pending_internal_transfer?'text-light':'Orng'">
                              <td> {{item.itemCode}}</td>
                              <td> {{item.itemDescription}}</td>
                              <td>{{Number(item.available_items_at_hub).toFixed(2)}}</td>
                              <td>{{Number(Number(item.available_items_at_hub)-Number(item.ordersitemsQtySum_at_hub)).toFixed(2)}}</td>
                              <!--                                    <td>{{-->
                              <!--                                    (Number(item.available_items_at_hub)-Number(item.ordersitemsQtySum_at_hub))+(Number(item.available_items)-Number(item.ordersitemsQtySum))-->
                              <!--                                        }}</td>-->
                              <!--                                    <td class="col-2"><b-input :value="(Number(item.available_items_at_hub)-Number(item.ordersitemsQtySum_at_hub))+(Number(item.available_items)-Number(item.ordersitemsQtySum))"></b-input></td>-->
                              <!--                                    -->
                              <td>{{Number(item.needed).toFixed(2)}}</td>
                              <td class="col-2"><b-input      type="number" v-model="item.internal_transfer" @change="handlechange(item.item_id, location.id)" v-if="location.id!='7'"></b-input></td>

                              <td v-if="location.id!='7'">{{Number(item.pending_internal_transfer).toFixed(2)}}</td>
                          </tr>
                      </template>


                               </tbody>
                           </table>

                           <div class="row mt-5" v-if="result[`date_${location.id}`] && location.id!='7' ">

                               <div class="col-md-3 text-light" >
                                   <label class="mt-1"><strong>Select Date</strong></label>
                                   <b-form-datepicker

                                       :reset-button=false
                                       reset-button-variant="btn btn-warning mtmb"


                                       v-model="result[`date_${location.id}`]"
                                       placeholder="Date"

                                       dropup  calendar-width="100%">

                                   </b-form-datepicker>
                               </div>

                               <div class="col-md-6 text-light">
                                   <label class="hide"><strong>Transfer Stock</strong></label>
                                   <button  type="button" class="btn custom-btn2 w-100 btn-lg text-light   mtmb" @click="transfer_stock(result[`date_${location.id}`],result[location.id])"
                                            :disabled="
                                         (!result[location.id]) || (result[location.id].filter((item)=>Number(item.internal_transfer)<0).length)>0?true:false
                                          || (result[location.id].filter((item)=>Number(item.internal_transfer)>0).length)==0?true:false || !(authenticatedUser.permissions.includes('super') || authenticatedUser.permissions.includes('warehouse-manager'))
                                          ">

                                       Internal Transfer</button>
                               </div>
                           </div>

                    </div>
                    </template>
                </div>




            </div>
            <!--            <div class="col-md-4 text-light ">-->
            <!--                <h1 class="text-center">Refunds</h1>-->
            <!--            </div>-->

        </div>

    </div>

</template>

<script>

import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
//import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";
import Loader from "@/components/loader";

import axios from "axios";
import URL from "@/constants/baseurl";
import Swal from "sweetalert2";
import apiService from "@/service/apiService";
import {cloneDeep} from "lodash";
import vSelect from "vue-select";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
//import axios from "axios";
//import URL from "@/constants/baseurl";
//import Swal from "sweetalert2";


export default {
    name: "reports",

    components:{Welcomenote, Loader, 'v-select': vSelect, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['allinvoices','cashiers','branches']),
        ...mapState('invoice', ['branches']),


        userbranches(){
            if(this.authenticatedUser.permissions.includes('super') || this.authenticatedUser.permissions.includes('warehouse-manager')){
                let branches=cloneDeep(this.branches)
                branches=[{id:'all', name:'All'},...branches]

                return branches
            }
            else {

                return this.branches.filter((branch)=>branch.id==this.authenticatedUser.branch.id)
            }
        },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {

            selectedBranch:'all',
            roles: [],
            user: {},
            branch_id:'',
            showloader:'yes',
            result:[]

        };
    },


    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        // await this.getbranches()
         await this.getcashiers()
        await this.reloadcb()
        await this.getguide(true)


    },
    async mounted() {



    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getcashiers' ,'getbranches']),

        handlechange(item_id,location_id) {
            console.log(item_id,location_id)
            let item = this.result[location_id].find((item) => item.item_id == item_id);
            let box_qty = Number(item.box_qty);
            let qty = Number(item.internal_transfer);

            let validqty = qty / box_qty
            if (box_qty) {
                if (validqty < 1) {
                    item.internal_transfer = Number(item.box_qty).toFixed(2)
                }
                    // else if (qty % box_qty == 0) {
                    //     //
                // }
                else {
                    item.internal_transfer = Number((Math.ceil(qty / box_qty)) * box_qty).toFixed(2)
                }
            }


        },

        async getguide(start=true){
       if(this.selectedBranch) {
           if (start) {
               let user = localStorage.getItem('user');
               user = JSON.parse(user)
               this.selectedBranch = user.branch.id

               if (user.permissions.includes('super') || user.permissions.includes('warehouse-manager')) {
                   this.selectedBranch = 'all'
               }
           } else {
               //
           }
           this.showloader='yes'
           const {data} = await apiService.get(`/gettransferguide/${this.selectedBranch}`);

           this.result = data
           this.showloader = 'no'
           // console.log(this.result,'=======================guide===========================')
       }
        },
        async transfer_stock(date,data){
            const confirmTransfer = await Swal.fire({
                title: 'Confirm Transfer',
                text: 'Are you sure you want to transfer the stock?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, transfer it!'
            });

            if (confirmTransfer.isConfirmed) {
                let items = data.map((item) => {
                    let newitem = {
                        itemCode: item.itemCode,
                        itemDescription: item.itemDescription,
                        qty: item.internal_transfer,
                        id: item.item_id
                    }
                    return newitem
                })
                // console.log(items)
                let ledger_entry = {
                    items: items,
                     location_id_from:7,
                    location_id_to: data[0].location_id,

                    date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
                    user_id: this.authenticatedUser.id

                }


                const apiToken = localStorage.getItem('token');
                if (apiToken) {
                    // Set the Authorization header with the API token
                    axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                }

                try {
                    const res = await axios.post(`${URL.BASEURL}/api/transfer-stock`, ledger_entry);

                    if (res) {

                        Swal.fire('Success', 'Done!', 'success');
                       await this.getguide(true)
                      //  console.log(res.data)


                    }
                } catch (error) {
                    console.error(error);
                    Swal.fire('Error', 'Failed to Add Stock.', 'info');
                }
            }
        },

        async reloadcb(){
            if(this.branches.length==0){
                //  alert(this.branches.length)
             await this.getbranches()

            }
            if(this.cashiers.length==0){
             //  await this.getcashiers()
            }
        },

        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },

        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}
.Orng{
    color: orange;
}



</style>
