<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light">

                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1>  Suppliers </h1>
                    </div>
                </div>
                <div class="row  mt-2">

                                        <div class="col-md-3">
                                            <b-link class="" :to="{ path: '/supplierform',query: { type: 'add'} }" >
                                                <b-button class="btn-info mb-2" > Create New Supplier</b-button>
                                            </b-link>
                                        </div>
                                        <div class="col-md-2 ">
                                            <b-input v-model="name" placeholder="name" type="text" @input="getSuppliers"></b-input>
                                        </div>
                                        <div class="col-md-2 ">
                                            <b-input v-model="sku" placeholder="sku" type="text" @input="getSuppliers"></b-input>
                                        </div>
                    <!--                    <div class="col-md-2 ">-->
                    <!--                        <b-input v-model="cardno" placeholder="Card No" type="number" @input="getPurchaseOders"></b-input>-->
                    <!--                    </div>-->

                    <!--                    <div class="col-md-2 ">-->
                    <!--                        <b-input v-model="searchprdtname" placeholder="Product Name"></b-input>-->
                    <!--                    </div>-->
                    <!--                    <div class="col-md-2">-->
                    <!--                        <b-input v-model="searchprdtcode" placeholder="SKU"></b-input>-->
                    <!--                    </div>-->
                    <!--                    <div class="col-md-2">-->
                    <!--                        <button class="btn btn-info" @click="reloaditems">-->
                    <!--                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>-->
                    <!--                            Refresh-->
                    <!--                        </button>-->
                    <!--                    </div>-->

                </div>

                <div class="row" v-if="showloader">
                    <div class="col-md-12 text-center">
                        <loader/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <table class="table text-light">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>SKU</th>
                                <th>Status</th>
                                <th>Action</th>

                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="sp in Suppliers" :key="sp.id">
                                <td> {{sp.id}} </td>
                                <td>{{sp.name}}</td>
                                <td><span >{{sp.sku}}</span></td>

                                <td>
                                    <span v-if="sp.status==1" >Active</span>
                                    <span v-else="" >InActive</span>
                                </td>
                                <td>  <b-link class="" :to="{ path: '/supplierform',query: { type: 'edit', 'id': sp.id } }">
                                    <b-button class="btn-info mb-2" > Edit </b-button>
                                </b-link>
                                </td>


                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <b-modal  v-model="modalShow"  centered  >
                    <div class="row mt-5">
                        <div class="col-md-12">
                            <h1 class=" text-center">Items Detail</h1>
                        </div>
                        <div class="col-md-12">
                            <table class="table table-hover" >
                                <thead >

                                <th class="col-md-1">ID</th>
                                <th class="col-md-3">Item Code</th>
                                <!--                                <th class="col-md-3">Description</th>-->
                                <th class="col-md-3">Quantity</th>


                                </thead>
                                <tbody >
                                <tr v-for="item in selected_po.po_items" :key="item.item_id">

                                    <td> {{item.item_id}} </td>
                                    <td>{{item.item_code}}</td>
                                    <!--                                    <td>{{item.itemDescription}}</td>-->
                                    <td>{{item.qty}}</td>

                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>


                </b-modal>
            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import apiService from "@/service/apiService";
import Swal from "sweetalert2";
import axios from "axios";
import URL from "@/constants/baseurl";
import Loader from "@/components/loader";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
import moment from "moment";

//import vSelect from "vue-select";
//import vSelect from "vue-select";


export default {
    name: "customers",

    components:{Welcomenote, Loader,'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('customers', ['customers']),
        ...mapState('items', ['allitems']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },
        // filteredProducts() {
        //
        //
        //     let  fproducts=this.allitems;
        //
        //     if(this.branch_id){
        //         fproducts=fproducts.filter(dfo=>dfo.branch_id===this.branch_id)
        //     }
        //     if(this.cashier_id){
        //         fproducts=fproducts.filter(dfo=>dfo.cashier_id===this.cashier_id)
        //     }
        //     if(this.order_status){
        //         fproducts=fproducts.filter(dfo=>dfo.status===this.order_status)
        //     }
        //     if(this.searchprdtname){
        //         let searchprdtname=this.searchprdtname.toLowerCase();
        //
        //         fproducts=fproducts.filter(product=> {
        //             const name = product.itemName ? product.itemName.toLowerCase() : '';
        //             return name.includes(searchprdtname)
        //         })
        //     }
        //     if(this.searchprdtcode){
        //         const searchprdtcode =this.searchprdtcode.toLowerCase();
        //
        //         fproducts=fproducts.filter(product=> {
        //             const code = product.itemCode ? product.itemCode.toLowerCase() : '';
        //             return code.includes(searchprdtcode)
        //         })
        //     }
        //
        //     if(this.searchcustemail){
        //         let searchcustemail=this.searchcustemail.toLowerCase();
        //
        //         fproducts=fproducts.filter(customer=> {
        //             const email = customer.email ? customer.email.toLowerCase() : '';
        //             return email.includes(searchcustemail)
        //         })
        //     }
        //     if(this.custtype){
        //         let custtype=this.custtype.toLowerCase();
        //
        //         fproducts=fproducts.filter(customer=> {
        //             const customerType = customer.customerType ? customer.customerType.toLowerCase() : '';
        //             return customerType.includes(custtype)
        //         })
        //     }
        //
        //     if(this.credit){
        //
        //
        //         fproducts=fproducts.filter(customer=> {
        //             const balance = customer.walletBalance ? Number(customer.walletBalance)>0:false;
        //             return balance
        //         })
        //     }
        //
        //     fproducts=fproducts.filter(item=> {
        //         if(this.viewinactive==true){
        //             if(item.status==0){
        //                 return true
        //             }
        //         }
        //         else {
        //             if(item.status==1){
        //                 return true
        //             }
        //         }
        //     })
        //
        //     return fproducts
        // },

    },
    data() {
        return {
            name:"",
            sku:"",
            selected_po:[],
            modalShow:false,
            viewinactive:false,
            loading:false,
            showloader:false,
            roles: [],
            user: {},
            perPage:5,
            currentPage: 1,

            cashier_id:'',
            order_status:'',
            branch_id:'',
            cardno:'',
            searchprdtcode:'',
            searchcustemail:'',
            custtype:'',
            credit:null,
            Suppliers:[],




        };
    },
    async created() {
        //  await this.getUsers();
        // await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()
        this.loading=true
        await this.getSuppliers();
        this.loading=false
        await this.getCustomers();

    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('customers', ['getCustomers']),
        ...mapActions('items', ['getAllItems']),
        formatDate(date){
            let date1 = new Date(date).toLocaleString('en-US', {timeZone: 'Europe/London'});
            let date2 =  moment(date1).format('D MMM, YYYY');

            return date2;
        },
        showmodaldata(po){
            this.selected_po=po
            this.modalShow = ! this.modalShow
        },
        async getSuppliers() {
            this.showloader=true
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
            try {
                let data={
                    sku:this.sku,
                    name:this.name
                }
                const res = await axios.post(`${URL.BASEURL}/api/paginated-suppliers`, data);

                if (res) {
                    this.showloader=false
                    //  Swal.fire('Success!', 'Payment Update Successfully!', 'success');


                    this.Suppliers=res.data.data




                }
            } catch (error) {
                console.error(error);
                // Swal.fire('Error', 'Failed to update invoice.', 'info');
            }
        },
        async reloaditems(){
            this.loading=true
            //  await this.getItems();
            await this.getAllItems();

            this.loading=false
        },

        async deleteproduct(item_id){

            const {data} = await apiService.get(`/code_used/${item_id}`);
            //   console.log('code_availability', data.code_availability)
            //   alert(data.code_availability)
            if(data.code_used){
                Swal.fire('Product is used!', 'You cannot delete used products.', 'info');
            }
            else {
                const result = await Swal.fire({
                    title: 'Are you sure you want to delete this product?',
                    text: 'This action cannot be undone.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                });
                if (result.isConfirmed) {

                    const apiToken = localStorage.getItem('token');
                    if (apiToken) {
                        // Set the Authorization header with the API token
                        axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                    }
                    try {
                        const res = await axios.delete(`${URL.BASEURL}/api/delete-item/${item_id}`);

                        if (res) {
                            this.getItems();

                            Swal.fire('success', 'Product  Deleted', 'success');

                        } else {
                            Swal.fire('info', 'Product Cant be Deleted', 'info');
                        }
                    } catch (error) {
                        console.error(error);
                        Swal.fire('Error', 'Failed to delete Product.', 'info');
                    }
                }
            }
        },
        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
