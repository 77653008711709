import {cloneDeep} from "lodash";
import newnote from "@/constants/newnote";


export default {
    namespaced: true,
    state: () => ({
        newnote:cloneDeep(newnote),
        added_notes:[]

    }),
    mutations: {


        setnewnote(state){
            state.newnote=cloneDeep(newnote)
        },
        addNewNote(state,data){
            let mapid=Math.random()
            let datawithmapid = { ...data, mapid: mapid };
            state.added_notes.push(datawithmapid)
        },

        attachinvoicenotes(state, data){
            state.added_notes = data
        }


    },
    actions: {



        add_new_note({commit},note){
            commit('addNewNote', note)
            commit('setnewnote')

        },

        attachinvoicenotes({commit},attachednotes){

            let notesdata=attachednotes.map((d)=>{
                let mapid=Math.random()
                return { ...d,  mapid:mapid};
            })
            commit('attachinvoicenotes', notesdata)
        }
    },


}
