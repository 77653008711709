<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light">

                <div class="row">
                    <div class="col-md-12 text-center">
                      <h1>  Purchase Orders </h1>
                    </div>
                </div>
                <div class="row  mt-2">

                    <div class="col-md-3">
                        <b-link class="mx-1" :to="{ path: '/poform',query: { type: 'add'} }" >
                            <b-button class="btn-info mb-2" > Create New PO </b-button>
                        </b-link>
                        <b-link class="mx-3" :to="{ path: '/suppliers' }" v-if="authenticatedUser.permissions.includes('super')">
                            <b-button class="btn-info mb-2" > Suppliers </b-button>
                        </b-link>
                        <b-link class="mx-1" :to="{ path: '/materialrequest' }" >
                            <b-button class="btn-info mb-2" > Material Request </b-button>
                        </b-link>
                    </div>

                    <div class="col-md-2 ">
                        <v-select
                            v-model="location_id"
                            class="text-light"
                            placeholder="Location"
                            :options="locations"
                            @input="getPurchaseOders"
                            label="location_name"
                            :reduce="option => option.id"


                        />
                    </div>
                    <div class="col-md-2 ">
                        <v-select
                            v-model="postatus"

                            class=""
                            placeholder="Status"
                            :options="['Draft','Submitted','Partial','Received']"
                            @input="getPurchaseOders"

                        />
                    </div>
                    <div class="col-md-2 ">
                        <b-input v-model="pono" placeholder="PO No" type="text" @input="getPurchaseOders"></b-input>
                    </div>

                    <!--                    <div class="col-md-2 ">-->
                    <!--                        <b-input v-model="searchprdtname" placeholder="Product Name"></b-input>-->
                    <!--                    </div>-->
                    <!--                    <div class="col-md-2">-->
                    <!--                        <b-input v-model="searchprdtcode" placeholder="SKU"></b-input>-->
                    <!--                    </div>-->
                    <!--                    <div class="col-md-2">-->
                    <!--                        <button class="btn btn-info" @click="reloaditems">-->
                    <!--                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>-->
                    <!--                            Refresh-->
                    <!--                        </button>-->
                    <!--                    </div>-->

                </div>

                <div class="row" v-if="showloader">
                    <div class="col-md-12 text-center">
                        <loader/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <table class="table text-light">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>PO No.</th>
                                <th>Created By</th>
                                <th>Delivery Location</th>
                                <th>Suppier</th>
                                <th>Expected Inbound Date</th>
                                <th>Status</th>

                                <th>Action</th>

                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="po in PurchaseOders" :key="po.id">
                                <td> <a href="#" @click.prevent="showmodaldata(po)">{{po.id}}</a> </td>
                                <td>{{po.purchase_order_ref}}</td>
                                <td>{{po.user.name}}</td>
                                <td><span >{{po.delivery_location.location_name}}</span></td>
                                <td><span v-if="po.supplier">{{po.supplier.name}}</span></td>
                                <td><span >{{formatDate(po.date)}}</span></td>
                                <td><span :class="po.status=='Partial'&&'text-danger'">{{po.status}}</span></td>

                                <td>
                                    <button class="btn btn-warning mx-1" style="margin-top: -7px" v-if="po.status=='Draft'" @click="submitpo(po.id)">Submit</button>
                                    <button class="btn btn-danger mx-1" style="margin-top: -7px" v-if="po.status=='Draft'" @click="deletepo(po.id)">Delete</button>
                                    <b-link v-if="po.status=='Draft'" class="mx-1" :to="{ path: '/poform',query: { type: 'edit',id:po.id} }" >
                                        <b-button class="btn-info mb-2" >Edit </b-button>
                                    </b-link>
                                    <b-link v-if="po.status!='Draft'"  class="mx-1" :to="{ path: '/poform',query: { type: 'view',id:po.id} }" >
                                        <b-button class="btn-info mb-2" >View </b-button>
                                    </b-link>

                                 <b-button v-if="po.status=='Submitted'" @click="print_po(po)" class="btn-info mb-2" >Print </b-button>
                                    <b-button v-if="po.status=='Submitted'" @click="generatePdf(po)" class="btn-info mb-2 mx-1" >

                                        <span v-if="selected_poprint">

                                            <span v-if="selected_poprint.showspinnermail" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </span>
                                        Send
                                    </b-button>


                                </td>


                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <b-modal  v-model="modalShow"  centered  >
                    <div class="row mt-5">
                        <div class="col-md-12">
                            <h1 class=" text-center">Items Detail</h1>
                        </div>
                        <div class="col-md-12">
                            <table class="table table-hover" >
                                <thead >

                                <th class="col-md-1">ID</th>
                                <th class="col-md-3">Item Code</th>
                                <!--                                <th class="col-md-3">Description</th>-->
                                <th class="col-md-3">Quantity</th>
                                <th>Received</th>


                                </thead>
                                <tbody >
                                <tr v-for="item in selected_po.po_items" :key="item.item_id">

                                    <td> {{item.item_id}} </td>
                                    <td>{{item.item_code}}</td>
                                    <!--                                    <td>{{item.itemDescription}}</td>-->
                                    <td>{{item.qty}}</td>
                                    <td>{{item.received}}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>


                </b-modal>

                <VueHtml2pdf  v-if="selected_poprint"
                    shrink-to-fit=yes
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="false"
                    :preview-modal="false"

                    filename="tiles_purchase_order"
                    :pdf-quality="1"
                    :manual-pagination="true"
                    pdf-content-width="1100px"
                    @beforeDownload="beforeDownload($event)"

                    ref="html2Pdf"
                    :html-to-pdf-options="{
            margin:[0,0,0,0],
          filename:`TilesPO-${this.selected_poprint.purchase_order_ref}`,
          jsPDF: {
            format: [1300, 1100],
            unit: 'px'
          },
          // html2canvas: {
          //   useCORS: true,
          //   dpi: 192,
          //   letterRendering: true,
          //   scale: 1, // resolution
          // },
             pagebreak: { mode: 'avoid-all', after: '.avoidThisRow' }


       }"


                >
                    <section slot="pdf-content">
                        <!-- PDF Content Here -->
                        <printpo mode="send" />
                    </section>
                </VueHtml2pdf>
            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import VueHtml2pdf from 'vue-html2pdf'
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import apiService from "@/service/apiService";
import Swal from "sweetalert2";
import axios from "axios";
import URL from "@/constants/baseurl";
import Loader from "@/components/loader";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
import moment from "moment";
import Printpo from "@/pages/admin/mypos/printpo";
import vSelect from "vue-select";

//import vSelect from "vue-select";
//import vSelect from "vue-select";


export default {
    name: "customers",

    components:{'v-select': vSelect,Printpo,'VueHtml2pdf': VueHtml2pdf, Welcomenote, Loader,'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('customers', ['customers']),
        ...mapState('items', ['allitems']),
        ...mapState('purchaseorder',['selected_poprint',]),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },
        // filteredProducts() {
        //
        //
        //     let  fproducts=this.allitems;
        //
        //     if(this.branch_id){
        //         fproducts=fproducts.filter(dfo=>dfo.branch_id===this.branch_id)
        //     }
        //     if(this.cashier_id){
        //         fproducts=fproducts.filter(dfo=>dfo.cashier_id===this.cashier_id)
        //     }
        //     if(this.order_status){
        //         fproducts=fproducts.filter(dfo=>dfo.status===this.order_status)
        //     }
        //     if(this.searchprdtname){
        //         let searchprdtname=this.searchprdtname.toLowerCase();
        //
        //         fproducts=fproducts.filter(product=> {
        //             const name = product.itemName ? product.itemName.toLowerCase() : '';
        //             return name.includes(searchprdtname)
        //         })
        //     }
        //     if(this.searchprdtcode){
        //         const searchprdtcode =this.searchprdtcode.toLowerCase();
        //
        //         fproducts=fproducts.filter(product=> {
        //             const code = product.itemCode ? product.itemCode.toLowerCase() : '';
        //             return code.includes(searchprdtcode)
        //         })
        //     }
        //
        //     if(this.searchcustemail){
        //         let searchcustemail=this.searchcustemail.toLowerCase();
        //
        //         fproducts=fproducts.filter(customer=> {
        //             const email = customer.email ? customer.email.toLowerCase() : '';
        //             return email.includes(searchcustemail)
        //         })
        //     }
        //     if(this.custtype){
        //         let custtype=this.custtype.toLowerCase();
        //
        //         fproducts=fproducts.filter(customer=> {
        //             const customerType = customer.customerType ? customer.customerType.toLowerCase() : '';
        //             return customerType.includes(custtype)
        //         })
        //     }
        //
        //     if(this.credit){
        //
        //
        //         fproducts=fproducts.filter(customer=> {
        //             const balance = customer.walletBalance ? Number(customer.walletBalance)>0:false;
        //             return balance
        //         })
        //     }
        //
        //     fproducts=fproducts.filter(item=> {
        //         if(this.viewinactive==true){
        //             if(item.status==0){
        //                 return true
        //             }
        //         }
        //         else {
        //             if(item.status==1){
        //                 return true
        //             }
        //         }
        //     })
        //
        //     return fproducts
        // },

    },
    data() {
        return {

            locations:[],
            location_id:'',
            selected_po:[],
            modalShow:false,
            viewinactive:false,
            loading:false,
            showloader:false,
            roles: [],
            user: {},
            perPage:5,
            currentPage: 1,

            cashier_id:'',
            order_status:'',
            branch_id:'',
            pono:'',
            postatus:'',
            searchprdtcode:'',
            searchcustemail:'',
            custtype:'',
            credit:null,
            PurchaseOders:[],




        };
    },
    async created() {
        //  await this.getUsers();
        // await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()
        this.loading=true
        await this.getPurchaseOders();
        this.loading=false
        await this.getlocations()
        await this.getCustomers();

    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('customers', ['getCustomers']),
        ...mapActions('items', ['getAllItems']),
        ...mapActions('purchaseorder', ['add_selected_poprint']),

        generatePdf(po) {



            this.add_selected_poprint(po)
            let enterorconfirm=''
            let supemail=''
            if (this.selected_poprint.supplier.email){
                enterorconfirm='Confirm'
               supemail=this.selected_poprint.supplier.email
            }
            else {
                enterorconfirm='Enter'
            }

            Swal.fire({
                title: `${enterorconfirm} Email`,
                html: `
                   <div class="row">
                    <input id="swal-input-1"  class="swal2-input col-md-10" placeholder="Please Enter Email of Supplier" value=${supemail}>

                       </div>

                               `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: 'Send',
                cancelButtonText: 'Cancel',
                preConfirm: () => {
                    const email = Swal.getPopup().querySelector('#swal-input-1').value;

                    if (!email) {
                        Swal.showValidationMessage('Email is Required!');
                    }
                    this.selected_poprint.supplier.email=email
                },

            }).then((result) => {
                if (result.isConfirmed) {

                    this.$refs.html2Pdf.generatePdf()
                }
            });


        },
        async sendpdf(pdfBlob){

            const formData = new FormData();
            formData.append('pdfFile', pdfBlob, `TilesPO-${this.selected_poprint.purchase_order_ref}.pdf`);
            formData.append('sup_id', this.selected_poprint.supplier.id);
            formData.append('sup_email', this.selected_poprint.supplier.email);
            // console.log(formData, 'pdf');

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                this.selected_poprint.showspinnermail=true
                const res = await axios.post(`${URL.BASEURL}/api/sendpo`, formData, {
                    // No need to manually set 'Content-Type' header; it will be set automatically to 'multipart/form-data'
                });

                console.log(res);
                if (res.data.message=='File uploaded successfully'){

                    this.selected_poprint.showspinnermail=false
                    Swal.fire('Success', 'PO has been sent', 'success');
                } else {
                    Swal.fire('Error', 'PO could  sent', 'error');
                }
            } catch (error) {
                console.error(error);
            }
        },

        async beforeDownload ({ html2pdf, options, pdfContent }) {

            await html2pdf().set(options).from(pdfContent).output("blob").then(async (pdfBlob) => {
                // Use an arrow function instead of a regular function to preserve 'this' context
                await this.sendpdf(pdfBlob);
            });
        },

        print_po(po){
        // console.log(po,'====================poprint')
            this.add_selected_poprint(po)
            const routeParams = {
                name: 'printpo',

            };
            this.$router.push(routeParams);

        },

        formatDate(date){
            let date1 = new Date(date).toLocaleString('en-US', {timeZone: 'Europe/London'});
            let date2 =  moment(date1).format('D MMM, YYYY');

            return date2;
        },
        async getlocations(){
            const {data} = await apiService.get(`/locations`);
            //  console.log('branches', data)
            this.locations=data
        },
        showmodaldata(po){
            this.selected_po=po
            this.modalShow = ! this.modalShow
        },
        async submitpo(po_id) {
            const confirmed = await Swal.fire({
                title: 'Are you sure?',
                text: 'You are about to submit this purchase order. This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
            });

            if (confirmed.isConfirmed) {
                const res = await apiService.get(`/submit-po/${po_id}`);
                console.log(res);
                if (res) {
                    Swal.fire('Success!', 'PO Submitted Successfully!', 'success');
                    await this.getPurchaseOders();
                }
            }
        },


        async deletepo(po_id) {
            const confirmed = await Swal.fire({
                title: 'Are you sure?',
                text: 'You are about to delete this purchase order. This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
            });

            if (confirmed.isConfirmed) {
                const res = await apiService.get(`/delete-po/${po_id}`);
                console.log(res);
                if (res) {
                    Swal.fire('Success!', 'PO Deleted Successfully!', 'success');
                    await this.getPurchaseOders();
                }
            }
        },

        async getPurchaseOders() {
            this.showloader=true
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
            try {
                let data={
                   pono:this.pono,
                   postatus:this.postatus,
                   location_id:this.location_id,
                }
                const res = await axios.post(`${URL.BASEURL}/api/purchase-orders`, data);

                if (res) {
                    this.showloader=false
                    //  Swal.fire('Success!', 'Payment Update Successfully!', 'success');


                    this.PurchaseOders=res.data.data




                }
            } catch (error) {
                console.error(error);
                // Swal.fire('Error', 'Failed to update invoice.', 'info');
            }
        },
        async reloaditems(){
            this.loading=true
            //  await this.getItems();
            await this.getAllItems();

            this.loading=false
        },

        async deleteproduct(item_id){

            const {data} = await apiService.get(`/code_used/${item_id}`);
            //   console.log('code_availability', data.code_availability)
            //   alert(data.code_availability)
            if(data.code_used){
                Swal.fire('Product is used!', 'You cannot delete used products.', 'info');
            }
            else {
                const result = await Swal.fire({
                    title: 'Are you sure you want to delete this product?',
                    text: 'This action cannot be undone.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                });
                if (result.isConfirmed) {

                    const apiToken = localStorage.getItem('token');
                    if (apiToken) {
                        // Set the Authorization header with the API token
                        axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                    }
                    try {
                        const res = await axios.delete(`${URL.BASEURL}/api/delete-item/${item_id}`);

                        if (res) {
                            this.getItems();

                            Swal.fire('success', 'Product  Deleted', 'success');

                        } else {
                            Swal.fire('info', 'Product Cant be Deleted', 'info');
                        }
                    } catch (error) {
                        console.error(error);
                        Swal.fire('Error', 'Failed to delete Product.', 'info');
                    }
                }
            }
        },
        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
