// import apiService from "../../service/apiService";


export default {
    namespaced: true,
    state: () => ({

    orderfilters:{
        cashier_id:'',
        order_status:'',
        branch_id:'',
        searchcustname:'',
        searchcustphone:'',
        searchcustpostcode:'',
        order_id:'',
        delivery_date:'',
        totalamount:'',
        searchQuery: '',

    }

    }),
    mutations: {



    },


    actions: {


    },


}
