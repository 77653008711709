import { render, staticRenderFns } from "./supplierform.vue?vue&type=template&id=86270a8e&scoped=true&"
import script from "./supplierform.vue?vue&type=script&lang=js&"
export * from "./supplierform.vue?vue&type=script&lang=js&"
import style0 from "./supplierform.vue?vue&type=style&index=0&id=86270a8e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86270a8e",
  null
  
)

export default component.exports