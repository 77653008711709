<template>

    <div class="col-md-1">

        <div class="row">
            <div class="col-md-12">
                <b-link class="" :to="{ path: '/'}">
                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">POS</span> </b-button>
                </b-link>  </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <b-link class="" :to="{ path: '/orders'}">
                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">ORDERS</span> </b-button>
                </b-link>  </div>

        </div>

        <!--                <div class="row">-->
        <!--                    <img class="col-md-12 w-100 mt-3 cursor-pointer" src="@/assets/images/sidebaricons/quotes.png" >-->
        <!--                </div>-->
        <div class="row">
            <div class="col-md-12">
                <b-link class="" :to="{ path: '/products'}">
                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">STOCK_CHECK</span> </b-button>
                </b-link>  </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <b-link class="" :to="{ path: '/customers'}">
                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">CUSTOMERS</span> </b-button>
                </b-link>  </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <b-link class="" :to="{ path: '/trade-card' }">
                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">TRADE</span> </b-button>
                </b-link>  </div>

        </div>




<!--        <div class="row">-->
<!--            <div class="col-md-12">-->
<!--                <b-link class="" :to="{ path: '/deliveries'}">-->
<!--                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">DELIVERIES</span> </b-button>-->
<!--                </b-link>  </div>-->

<!--        </div>-->
        <div class="row">
            <div class="col-md-12">
                <b-link class="" :to="{ path: '/dispatch-planner' }">
                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">DISPATCH</span> </b-button>
                </b-link>  </div>

        </div>



        <div class="row">
            <div class="col-md-12">
                <b-link class="" :to="{ path: '/settings' }">
                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">Settings</span> </b-button>
                </b-link>  </div>

        </div>




<!--        <div class="row">-->
<!--            <div class="col-md-12">-->
<!--                <b-link class="" :to="{ path: '/cash-collections' }">-->
<!--                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">Collections</span> </b-button>-->
<!--                </b-link>  </div>-->

<!--        </div>-->
        <div class="row">
            <div class="col-md-12">
                <b-link class="" :to="{ path: '/transfer-guide' }">
                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">TRANSFERS</span> </b-button>
                </b-link>  </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <b-link class="" :to="{ path: '/purchase-orders' }">
                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">PURCHASES</span> </b-button>
                </b-link>  </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <b-link class="" :to="{ path: '/stock' }">
                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">WAREHOUSE</span> </b-button>
                </b-link>  </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <b-link class="" :to="{ path: '/branch-reports'}">
                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">REPORTS</span> </b-button>
                </b-link>  </div>

        </div>

<!--        <div class="row">-->
<!--            <div class="col-md-12">-->
<!--                <b-link class="" :to="{ path: '/stock-calculation' }">-->
<!--                    <b-button  type="button" class="btn send w-100 btn-lg  mt-2"  ><span class="collections">Calculation</span> </b-button>-->
<!--                </b-link>  </div>-->

<!--        </div>-->
<!--        <div class="row">-->
<!--            <div class="col-md-12">-->
<!--                <b-link class="" :to="{ path: '/dispatch-planner' }">-->
<!--                    <b-button  type="button" class="btn send w-100 btn-lg  mt-3"  ><span class="collections">Planner</span> </b-button>-->
<!--                </b-link>  </div>-->

<!--        </div>-->









    </div>

</template>

<script>




import {mapState} from "vuex";

export default {
    name: "sidebar",
    props: {
        generatePdf: { type: Function },
    },
    methods: {
        send() {

            this.generatePdf();

        }
    },
    computed:{
        ...mapState('invoice', ['invoice']),

        authenticatedUser() {
            let user = localStorage.getItem('user');
            user=  JSON.parse(user)
            return user
        },
    }

}
</script>

<style scoped>





</style>
