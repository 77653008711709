// import apiService from "../../service/apiService";

import apiService from "@/service/apiService";
import newinvoice from "@/constants/newinvoice";
import {cloneDeep} from "lodash";
import axios from "axios";
import URL from "@/constants/baseurl";
import Swal from "sweetalert2";

export default {
    namespaced: true,
    state: () => ({
        invoice: cloneDeep(newinvoice),
        loaded_order:{order_id:null,order_index:null},
        latestinvoices:null,
        allinvoices:[],
        cashiers:[],
        branches:[],
        locations:[],
        users:[]


    }),
    mutations: {

        reset_loaded_order(state) {

            state.loaded_order.order_index = null;
        },

        updateInvoice(state, payload) {
            const { key, value } = payload;
            state.invoice[key] = value;
        },
        updateFullInvoice(state, payload){

            state.invoice=payload
        },

        LatestInvoices(state, payload){

            state.latestinvoices=payload
        },
        AllInvoices(state, payload){

            state.allinvoices=payload
        },
        setCashiers(state, payload){

            state.cashiers=payload
        },
        setUsers(state, payload){

            state.users=payload
        },
        setBranches(state, payload){

            state.branches=payload
        },
        setLocations(state, payload){

            state.locations=payload
        },




    },


    actions: {
        updateInvoice({ commit }, payload) {
            commit('updateInvoice', payload);
        },
        updateFullInvoice({ commit }, payload){
            commit('updateFullInvoice', payload);
        },
        // saveinvoice({ state }) {
        //     alert(JSON.stringify(state.invoice))
        //     console.log(state.invoice);
        // }
        async getLatestInvoices({commit}, odfilters={}){

            let user = localStorage.getItem('user');
            user=  JSON.parse(user)
            const branch_id=user.branch.id
            commit('LatestInvoices', []);
            commit('reset_loaded_order')


            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                console.log(odfilters,'n')
                const res = await axios.post(`${URL.BASEURL}/api/getlatestinvoices/${branch_id}/${odfilters.page}`, odfilters);

                if (res) {
                    //  Swal.fire('Success!', 'Payment Update Successfully!', 'success');
                    // console.log(res.data)
                    commit('LatestInvoices', res.data);

                }
            } catch (error) {
               // console.error(error);
               Swal.fire('Error', 'Failed to get Orders', 'info');
            }



        },
        async getAllInvoices({commit}){

            commit('AllInvoices', []);
            const {data} = await apiService.get(`/getallinvoices`);
            commit('AllInvoices', data);

        },
        async getcashiers({ commit }){
            const {data} = await apiService.get(`/cashiers`);
          //  console.log('cashiers', data)

            commit('setCashiers', data)
        },
        async getusers({ commit }){
            const {data} = await apiService.get(`/users`);
            //  console.log('cashiers', data)

            commit('setUsers', data)
        },
        async getbranches({ commit }){
            const {data} = await apiService.get(`/branches`);
            //  console.log('branches', data)

            commit('setBranches', data)
        },
        async getlocations({ commit }){
            const {data} = await apiService.get(`/locations`);
            //  console.log('branches', data)

            commit('setLocations', data)
        },

    },



    getters: {

         paid: (state) => {
             if(state.invoice.status!='Quote') {
                return true
            }
            else {
                return false
            }
        },
        deposit: (state) => {
            if(state.invoice.status=='Deposit') {
                return true
            }
            else {
                return false
            }
        }
    }
}
