<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-3 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 mt-2 pb-2 bgsecond">

                <div class="row   text-light">

                    <div class="col-md-12">

                            <div class="row ">
                                <h1 class="ml-1 mt-1">
                                    <span v-if="actiontype=='Save'">Enter</span>
                                    <span v-if="actiontype=='Update'">Update</span>
                                    Product Details </h1>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <label for="firstName" class="form-label">Product Name</label>
                                    <b-form-input v-model="item.itemName" type="text" class="form-control" id="itemName" required :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="title" class="form-label">SKU Code</label>
                                    <b-form-input v-model="item.itemCode" @change="codeavailability" type="text" class="form-control" :state="code_availability"  id="itemCode" :disabled="actiontype=='View' || code_used"> </b-form-input>
                                </div>


                                <div class="col-md-3">
                                    <label for="email" class="form-label">Delivery Note</label>
                                    <b-form-input v-model="item.delivery_note_name" type="text" class="form-control" id="delivery_note_name" :disabled="actiontype=='View'"> </b-form-input>
                                </div>

                            </div>

                            <div class="row mt-3">
                                <div class="col-md-3" v-if="authenticatedUser.permissions.includes('super')">
                                    <label for="companyName" class="form-label">Cost Price</label>
                                    <b-form-input v-model="item.costPrice" type="number" step="any" class="form-control" id="costPrice" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="customerCode" class="form-label">Default Price (£/M2+VAT)</label>
                                    <b-form-input v-model="item.defaultPrice" type="number" step="any" class="form-control" id="defaultPrice" :disabled="actiontype=='View'"> </b-form-input>
                                </div>

                                <div class="col-md-2">
                                    <label for="box_qty" class="form-label">Box Quantity</label>
                                    <b-form-input v-model="item.box_qty" type="number" step="any" class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>
                                </div>


                                <div class="col-md-1">
                                    <label for="mobile" class="form-label">Status</label>
                                    <b-form-checkbox v-model="item.status" id="status" :disabled="actiontype=='View'"> Active </b-form-checkbox>
                                </div>

                            </div>
                        <div class="row mt-3">
                            <div class="col-md-3" v-if="authenticatedUser.permissions.includes('super')">
                                <label for="companyName" class="form-label">Max Discount</label>
                                <b-form-input v-model="item.max_discount" type="text"  class="form-control" id="costPrice" :disabled="actiontype=='View'"> </b-form-input>
                            </div>
                            <div class="col-md-3">
                                <label for="customerCode" class="form-label">Trade Card</label>
                                <b-form-input v-model="item.trade_card" type="text"  class="form-control" id="defaultPrice" :disabled="actiontype=='View'"> </b-form-input>
                            </div>

                            <div class="col-md-3">
                                <label for="box_qty" class="form-label">Normal/Sale/Clearance</label>
                                <b-form-input v-model="item.normal_sale_clearance" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>
                            </div>

                        </div>
                        <div class="row mt-3">
                            <div class="col-md-3">
                                <label for="box_qty" class="form-label">Min Quantity</label>
                                <b-form-input v-model="item.min_qty" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>
                            </div>
                            <div class="col-md-3">
                                <label for="box_qty" class="form-label">Default Supplier</label>
                                <b-form-select
                                    v-model="item.def_supplier_id"
                                    :options="suppliers"
                                    class="bgsecond bform text-white mb-2 "

                                    value-field="id"
                                    text-field="name"
                                >

                                    <template #first>
                                        <b-form-select-option value="" >Select One</b-form-select-option>
                                    </template>
                                    <template #option="{ option }">
                                        <div>{{ option.name }}</div>
                                    </template>

                                </b-form-select>                            </div>
                            <div class="col-md-3">
                                <label for="box_qty" class="form-label">Supplier Sku</label>
                                <b-form-input v-model="item.supplier_sku" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>
                            </div>
                        </div>

                            <div class="row mt-3">
                                <div class="col-md-9">
                                    <label for="delivery_note_name" class="form-label">Product Description</label>
                                    <b-form-textarea v-model="item.itemDescription" rows="2" class="form-control" id="delivery_note_name" :disabled="actiontype=='View'"></b-form-textarea>
                                </div>

                            </div>



                            <button v-if="actiontype!='View'" type="submit" class="btn btn-primary mt-3" @click="saveProduct" :disabled="makedis"> {{actiontype}} Product</button>



                    </div>

                </div>
                <div class="row" v-if="actiontype=='View'">
                    <h1 class="col-md-12 "> <hr class="hr0"></h1>
                  <div class="col-md-3 mx-2">
                      <table class="table text-light mt-5" >
                          <thead >

                          <th class="col-md-3">Stock Level</th>
                          <th class="col-md-3">Actual</th>
                          <th class="col-md-3">Forecast</th>
                          </thead>
                          <tbody >
                          <tr >
                              <td >Isleworth Warehouse</td>
                              <td >{{Number(item.IsleworthWarehouseStock).toFixed(2)}}</td>
                              <td >{{Number(item.IsleworthWarehouseAfterOpen).toFixed(2)}}</td>
                          </tr>
<!--                          <tr >-->
<!--                              <td >Isleworth Shop Floor </td>-->
<!--                              <td >{{item.IsleworthShopFloorStock}}</td>-->
<!--                              <td >{{item.IsleworthShopFloorAfterOpen}}</td>-->
<!--                          </tr>-->
                          <tr >
                              <td >Bounds Green </td>
                              <td >{{Number(item.BoundsGreenStock).toFixed(2)}}</td>
                              <td >{{Number(item.BoundsGreenAfterOpen).toFixed(2)}}</td>
                          </tr>
                          <tr >
                              <td >Hanworth</td>
                              <td >{{Number(item.HanworthStock).toFixed(2)}}</td>
                              <td >{{Number(item.HanworthAfterOpen).toFixed(2)}}</td>
                          </tr>
                          <tr >
                              <td >Crawley </td>
                              <td >{{Number(item.CrawleyStock).toFixed(2)}}</td>
                              <td >{{Number(item.CrawleyAfterOpen).toFixed(2)}}</td>
                          </tr>
<!--                          <tr >-->
<!--                              <td >Farn borough Shop</td>-->
<!--                              <td >{{item.FarnboroughShopStock}}</td>-->
<!--                              <td >{{item.FarnboroughShopAfterOpen}}</td>-->
<!--                          </tr>-->
                          <tr >
                              <td >Farnborough Warehouse</td>
                              <td >{{Number(item.FarnboroughWarehouseStock).toFixed(2)}}</td>
                              <td >{{Number(item.FarnboroughWarehouseAfterOpen).toFixed(2)}}</td>
                          </tr>

                          </tbody>
                      </table>
                  </div>

                </div>

                <div class="row" v-if="actiontype=='View'">
                    <div class="col-md-12 text-light">
                        <h1 class="text-center">Ledger History</h1>
                        <table class="table text-light">
                            <thead >

                            <th>Branch In</th>
                            <th>Branch Out</th>
                            <th>Mode</th>
                            <th>Date</th>
                            <th>Ref</th>
                            <th>Qty</th>
                            </thead>
                            <tbody>
                            <tr v-for="led in item.ledger_history" :key="led.id">
                                <td > <span v-if="Number(led.quantity)>0"> {{led.location.location_name}}</span>   </td>
                                <td>  <span v-if="Number(led.quantity)<0"> {{led.location.location_name}}</span>  </td>
                                <td>  {{led.mode}}  </td>
                                <td>  {{formatDate(led.date_time)}}  </td>
                                <td>  {{led.dispatch_item.dispatch.purchase_order_ref}}  </td>
                                <td>  {{led.quantity}}  </td>


                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>



            </div>



        </div>

    </div>

</template>

<script>
import apiService from "@/service/apiService";
import {mapActions, mapState} from "vuex";
import { mapGetters } from 'vuex';
//import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import axios from "axios";
import {cloneDeep} from "lodash";
import Swal from "sweetalert2";
import showrealtime from "@/components/showrealtime";
import URL from "@/constants/baseurl"
import Welcomenote from "@/pages/admin/mypos/welcomenote";
import moment from "moment";
//import newinvoice from "@/constants/newinvoice";
//import newcustomer from "@/constants/newcustomer";
//import newpayment from "@/constants/newpayment";
//import newitem from "@/constants/newitem";
//import vSelect from "vue-select";


export default {
    name: "customerform",

    components:{Welcomenote, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['cashiers']),
        ...mapState('customers', ['customers']),
        ...mapState('items', ['pageitems']),

        // ...mapState('invoice', ['branches']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

        makedis(){
            if(this.code_availability && ! this.item.itemCode=="" && ! this.item.itemName==""){
                return false
            }
            else  return true
        },

        actiontype(){
            if (this.$route.query.type=='add'){
                return 'Save'
            }
            else if (this.$route.query.type=='edit'){
                return 'Update'
            }
            else {
                return 'View'
            }
        }

    },
    data() {
        return {

            suppliers:[],
            code_availability:true,
            code_used:false,
            roles: [],
            user: {},
            perPage:10,
            currentPage: 1,

            item: {
                id:"",
                itemName: "",
                itemCode: "",
                itemDescription: "",
                defaultPrice: "",
                status: true,
                costPrice: "",
                delivery_note_name: "",
                box_qty: "",
                max_discount: "",
                trade_card: "",
                normal_sale_clearance: "",
                def_supplier_id:"",
                min_qty:"",
                supplier_sku:""

            },



        };
    },
    async created() {
     //   await this.getUsers();
        await this.getRoles();
         await this.getsuppliers()
        // await this.getcashiers()
        this.viewType = this.$route.query.type;
        this.item_id = this.$route.query.id;

        this.setItem()
        this.item.status= this.item.status==1 ? true:false
      await  this.code_is_used()


    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['updateFullInvoice']),
      //  ...mapActions('payment', ['setNewpayment']),
        ...mapActions('items',['add_searched_items','getAllItems']),
       // ...mapActions('payment', ['setNewpayment']),

        formatDate(date){
            let date1 = new Date(date).toLocaleString('en-US', {timeZone: 'Europe/London'});
            let date2 =  moment(date1).format('D MMM, YYYY, hh:mm A');

            return date2;
        },
        async codeavailability(){
            let code=this.item.itemCode
                const {data} = await apiService.get(`/item_code_availability/${code}`);
             //   console.log('code_availability', data.code_availability)
             //   alert(data.code_availability)
            this.code_availability=data.code_availability
        },

        async code_is_used(){

            const {data} = await apiService.get(`/code_used/${this.item_id}`);
            //   console.log('code_availability', data.code_availability)
            //   alert(data.code_availability)
            this.code_used=data.code_used
        },

        async signOut() {
            await this.processLogoutManual();
          //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        async getsuppliers(){
            const {data} = await apiService.get(`/suppliers`);
          //  console.log('suppliers', data)
            this.suppliers=data
        },



        async saveProduct() {
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
           // let productdata=cloneDeep(this.customer)
            let productdata=cloneDeep(this.item)
            // if(this.suppliers.find((s)=>s.id==productdata.def_supplier_id)){
            //     productdata.supplier_sku=this.suppliers.find((s)=>s.id==productdata.def_supplier_id).sku
            // }


            // let cbranch_id;
            // if(this.authenticatedUser.permissions.includes('super')){
            //     cbranch_id=this.customer.branch_id
            // }
            // else {
            //     cbranch_id=this.authenticatedUser.branch.id
            // }

           // productdata.branch_id=cbranch_id
            try {
                const response = await axios.post(`${URL.BASEURL}/api/add-item`,  productdata);
                console.log("Product saved:", response.data);
               await this.getAllItems();
                await Swal.fire('Success', 'Product added successfully!', 'success');
                this.$router.push({ path: '/products' })

                // this.customer = {
                //     id:"",
                //     branch_id: "",
                //     cashier_id: "",
                //     title: "",
                //     firstName: "",
                //     lastName: "",
                //     customerCode: "",
                //     mobile: "",
                //     companyName: "",
                //     customerType: "",
                //     discountType: 1,
                //     addressLine1: "",
                //     addressLine2: "",
                //     postcode: "",
                //     email: "",
                //     notes: "",
                //     status: true
                // };
            } catch (error) {
                console.error("Error saving customer:", error);
            }
        },
        setItem(){
            if(this.viewType!=='add'){

                this.item=cloneDeep(this.pageitems).find(c=>c.id==this.item_id)



            }
        }
    }
};
</script>



<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.hr0{
    margin-top: 15px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid #ffffff;
    max-width: 100%;

}



</style>
