export default {
    "id":'',
    "lastName":'',
    "mobile":'',
    "addressLine1":'',
    "addressLine2":'',
    "postcode":'',
    "cashier_id":'',
    "notes":'',
}
