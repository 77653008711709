export default {
    id:0,
    defaultPrice: '',
    itemCode: "",
    itemDescription: "",
    itemName: "newitem",
    discount_applied:false,
    qty: '',
    status: 0
}
