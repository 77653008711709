export default {

    "customer_id":'',
    "invoice_ref":"Not Created",
    "parent_inv_id":"",
    "branch_id":'',
    "cashier_id":'',
    "issueDate": "",
    "dueDate":"",
    "subTotal":0,
    "custDiscount":0,
    "voucherDiscount":0,
    "vat":0,
    "grandTotal":0,
    "totalPaid":0,
    "balance":0,
    "cardPayment":0,
    "cardReference":"",
    "cashPayment":0,
    "cashReference":"",
    "depositPayment":0,
    "depositReference":"",
    "bankPayment":0,
    "bankReference":"",
    "deliveryOption":'',
    "deliveryDate":"",
    "deliveryEstimated":0,
    "deliveryAddress1":"",
    "deliveryAddress2":"",
    "deliveryPostcode":"",
    "cusMobile":"",
    "status":"Quote",
    "depositDate":null,
    "cardDate":null,
    "cashDate":null,
    "bankDate":null,
    "refund":0,
    "payments":[],
    "delivery_location_id":"",
    "pickup_location_id":"",

}
