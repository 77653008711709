<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
               <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light">

                <div class="row mt-1">

                    <div class="col-md-1 offset-4">
                        <button class="btn-sm btn-info"     :disabled="itemsdata.current_page==1" @click="getAllItems('prev')">Previous</button>
                    </div>
                    <div class="col-md-3">
                        <span class="text-light mx-2 px-2"  style="border: 3px solid white; border-radius: 5px"> showing  ( <span v-if="itemsdata.to-10>0">{{itemsdata.to-10}}</span> <span v-else="">0</span>  to {{itemsdata.to}}) out of {{itemsdata.total}} records</span>
                    </div>
                    <div class="col-md-1">

                        <button class="btn-sm btn-info "    :disabled="itemsdata.current_page==totalPages" @click="getAllItems('next')">Next</button>
                    </div>



                    <h1 class="col-md-12 "> <hr class="hr0"></h1>
                </div>
                <div class="row  mt-3">

                    <b-link class="" :to="{ path: '/productform',query: { type: 'add'} }" v-if="authenticatedUser.permissions.includes('super')">
                        <b-button class="btn-info mb-2" > Add New Product </b-button>
                    </b-link>

                    <div class="col-md-2 ">
                        <b-input v-model="pfilters.searchprdtname"  placeholder="Product Name" @input="getAllItems"></b-input>
                    </div>
                    <div class="col-md-2">
                        <b-input v-model="pfilters.searchprdtcode" placeholder="SKU" @input="getAllItems"></b-input>
                    </div>
                   <div class="col-md-2">
                       <button class="btn btn-info" @click="reloaditems">
                           <span v-if="showloader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                           Refresh
                       </button>
                   </div>
                    <div class="col-md-2  text-light">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="pfilters.viewinactive"
                            name="checkbox-1"
                            @change="getAllItems"
                        >
                            View In Active
                        </b-form-checkbox>
                    </div>





                    <table class="bg-light table table-striped table-hover table-responsive">
                        <thead>
                        <tr>
                            <th class="bold-columns col-md-1" >Product Name</th>
                            <th class="bold-columns col-md-2">SKU</th>
                            <th class="bold-columns col-md-2">Note Name</th>
                            <th class="bold-columns">Default Price</th>
                            <th class="bold-columns">Box Quantity</th>
                            <th class="bold-columns">Stock</th>
                            <th class="bold-columns">Farnborough</th>
                            <th class="bold-columns">Isleworth</th>
                            <th class="bold-columns">Bounds G.</th>
                            <th class="bold-columns">Crawley</th>
                            <th class="bold-columns">Hanworth</th>
                            <th class="bold-columns">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in allitems" :key="index">
                            <td>{{ item.itemName }}</td>
                            <td>{{ item.itemCode }}</td>
                            <td>{{ item.delivery_note_name }}</td>
                            <td>{{ item.defaultPrice }}</td>
                            <td>{{ item.box_qty }}</td>
                            <td>
                                <strong>
                                    &nbsp; {{
                                        (
                                            Number(item.FarnboroughWarehouseAfterOpen) +
                                            Number(item.IsleworthWarehouseAfterOpen) +
                                            Number(item.BoundsGreenAfterOpen) +
                                            Number(item.CrawleyAfterOpen) +
                                            Number(item.HanworthAfterOpen)
                                        ).toFixed(2)
                                    }}
                                </strong>
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.FarnboroughWarehouseAfterOpen).toFixed(2) }} </strong>
                                ({{ Number(item.FarnboroughWarehouseStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.IsleworthWarehouseAfterOpen).toFixed(2) }} </strong>
                                ({{ Number(item.IsleworthWarehouseStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.BoundsGreenAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.BoundsGreenStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.CrawleyAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.CrawleyStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.HanworthAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.HanworthStock).toFixed(2) }})
                            </td>
                            <td>
                                <b-link v-if="authenticatedUser.permissions.includes('super')" :to="{ path: '/productform', query: { type: 'edit', 'id': item.id } }">
                                    <b-button class="btn-info mb-2 mx-1"> Edit </b-button>
                                </b-link>
                                <b-link :to="{ path: '/productform', query: { type: 'view', 'id': item.id } }">
                                    <b-button class="btn-info mb-2"> View </b-button>
                                </b-link>
                                <b-button @click="deleteproduct(item.id)" v-if="authenticatedUser.permissions.includes('super')" class="btn-danger mb-2"> Delete </b-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>



            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import apiService from "@/service/apiService";
import Swal from "sweetalert2";
import axios from "axios";
import URL from "@/constants/baseurl";
import Welcomenote from "@/pages/admin/mypos/welcomenote";

//import vSelect from "vue-select";
//import vSelect from "vue-select";


export default {
    name: "customers",

    components:{Welcomenote, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('customers', ['customers']),
        ...mapState('items', ['pfilters','pageitems']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },
        // filteredProducts() {
        //
        //
        //     let  fproducts=this.allitems;
        //
        //     if(this.branch_id){
        //         fproducts=fproducts.filter(dfo=>dfo.branch_id===this.branch_id)
        //     }
        //     if(this.cashier_id){
        //         fproducts=fproducts.filter(dfo=>dfo.cashier_id===this.cashier_id)
        //     }
        //     if(this.order_status){
        //         fproducts=fproducts.filter(dfo=>dfo.status===this.order_status)
        //     }
        //     if(this.searchprdtname){
        //         let searchprdtname=this.searchprdtname.toLowerCase();
        //
        //         fproducts=fproducts.filter(product=> {
        //             const name = product.itemName ? product.itemName.toLowerCase() : '';
        //             return name.includes(searchprdtname)
        //         })
        //     }
        //     if(this.searchprdtcode){
        //         const searchprdtcode =this.searchprdtcode.toLowerCase();
        //
        //         fproducts=fproducts.filter(product=> {
        //             const code = product.itemCode ? product.itemCode.toLowerCase() : '';
        //             return code.includes(searchprdtcode)
        //         })
        //     }
        //
        //     if(this.searchcustemail){
        //         let searchcustemail=this.searchcustemail.toLowerCase();
        //
        //         fproducts=fproducts.filter(customer=> {
        //             const email = customer.email ? customer.email.toLowerCase() : '';
        //             return email.includes(searchcustemail)
        //         })
        //     }
        //     if(this.custtype){
        //         let custtype=this.custtype.toLowerCase();
        //
        //         fproducts=fproducts.filter(customer=> {
        //             const customerType = customer.customerType ? customer.customerType.toLowerCase() : '';
        //             return customerType.includes(custtype)
        //         })
        //     }
        //
        //     if(this.credit){
        //
        //
        //         fproducts=fproducts.filter(customer=> {
        //             const balance = customer.walletBalance ? Number(customer.walletBalance)>0:false;
        //             return balance
        //         })
        //     }
        //
        //     fproducts=fproducts.filter(item=> {
        //        if(this.viewinactive==true){
        //            if(item.status==0){
        //                return true
        //            }
        //        }
        //        else {
        //            if(item.status==1){
        //                return true
        //            }
        //        }
        //     })
        //
        //     return fproducts
        // },

    },
    data() {
        return {
            showloader:false,
            allitems:[],
            itemsdata:'',

            loading:false,
            roles: [],
            user: {},
            perPage:10,
            currentPage: 1,

            cashier_id:'',
            order_status:'',
            branch_id:'',

            searchcustemail:'',
            custtype:'',
            credit:null,



            fields: [

                { key: 'itemName', label: 'Product Name' },
                { key: 'itemCode', label: 'SKU' },
                { key: 'delivery_note_name', label: 'Note Name' },
                { key: 'defaultPrice', label: 'Default Price' },

                { key: 'box_qty', label: 'Box Quantity' },


                { key: 'forecaststock', label: 'Stock' },

                { key: 'Farnborough', label: 'Farnborough' },
                { key: 'Isleworth', label: 'Isleworth' },
                { key: 'Bounds_Green', label: 'Bounds G. ' },
                { key: 'Crawley', label: 'Crawley' },
                { key: 'Hanworth', label: 'Hanworth' },
                'actions'

            ],

        };
    },
    async created() {

        // await this.getbranches()
        // await this.getcashiers()
        this.loading=true
        await this.getAllItems();
        this.loading=false
        await this.getCustomers();
        await this.getUsers();
        await this.getRoles();

    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('customers', ['getCustomers']),
        ...mapActions('items', ['set_page_items']),

        async getAllItems(button=null) {
            let current_page= this.itemsdata.current_page
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
          let filters={
                name:this.pfilters.searchprdtname,
                sku:this.pfilters.searchprdtcode,
                status:this.pfilters.viewinactive,
                page:current_page
            }
            if(button=='next'){

                filters.page=current_page+1

            }
            if(button=='prev'){
                filters.page=current_page-1


            }
            this.showloader=true
            const {data} = await apiService.post(`/allitems`,filters);
            this.showloader=false

            let  itemdata=data.data.map((d)=>{
                let mapid=Math.random()
                return { ...d, qty: 1, picked:0 , mapid:mapid};
            })
            this.itemsdata=data
            this.allitems=itemdata
            this.set_page_items(itemdata)

            // this.items = itemdata;
            // this.$store.commit('items/setItems', itemdata);

            // console.log('ok')
        },
         async reloaditems(){
            this.loading=true
           //  await this.getItems();
             await this.getAllItems();

             this.loading=false
         },

        async deleteproduct(item_id){

            const {data} = await apiService.get(`/code_used/${item_id}`);
            //   console.log('code_availability', data.code_availability)
            //   alert(data.code_availability)
            if(data.code_used){
                Swal.fire('Product is used!', 'You cannot delete used products.', 'info');
            }
            else {
                const result = await Swal.fire({
                    title: 'Are you sure you want to delete this product?',
                    text: 'This action cannot be undone.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                });
                if (result.isConfirmed) {

                    const apiToken = localStorage.getItem('token');
                    if (apiToken) {
                        // Set the Authorization header with the API token
                        axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                    }
                    try {
                        const res = await axios.delete(`${URL.BASEURL}/api/delete-item/${item_id}`);

                        if (res) {
                              this.getItems();

                            Swal.fire('success', 'Product  Deleted', 'success');

                        } else {
                            Swal.fire('info', 'Product Cant be Deleted', 'info');
                        }
                    } catch (error) {
                        console.error(error);
                        Swal.fire('Error', 'Failed to delete Product.', 'info');
                    }
                }
            }
        },
        async signOut() {
            await this.processLogoutManual();
          //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.bold-columns th.IsleworthWarehouseAfterOpen,
.bold-columns th.BoundsGreenAfterOpen,
.bold-columns th.HanworthAfterOpen,
.bold-columns th.CrawleyAfterOpen,
.bold-columns th.FarnboroughWarehouseAfterOpen {
    font-weight: bold;
}

</style>
