
<template>

<span>
{{toptime}}
</span>

</template>



<script>






export default {
    name: "showrealtime",



    data() {
     return {
         toptime:''
     }
    },
  created() {
     this.showtoptime()
    },

    methods: {
        showtoptime(){
            setInterval(()=>{
                const options = {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true,
                    daySuffix: 'numeric',
                    timeZone: 'Europe/London'
                };

                const date = new Date();
                const datetime = date.toLocaleString('en-US', options);


                this.toptime=datetime
            },1000)
        },
    }
}
</script>

<style scoped>




</style>


