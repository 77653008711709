<template>
    <div>
        <h3 class="text-3xl">Role Permissions</h3>
        <div id="permissionsGrid" class='grid gap-6 mt-5'>
            <b-form-checkbox @change="togglePermission(permission.id,$event)" v-for="permission in permissions"
                             :checked="rolePermissions.includes(permission.id)"
                             :name="permission.name" :key="permission.id" switch>
                {{ permission.name }}
            </b-form-checkbox>
        </div>
        <div class="text-right mt-3">
            <button @click="save" class="primary-btn green">Save</button>
        </div>
    </div>
</template>

<script>
import {errorAlert, successAlert} from "@/components/Alerts";

export default {
    name: "RolePermissions",
    data() {
        return {
            rolePermissions: []
        }
    },
    props: {
        role: {
            type: Object,
        },
        permissions: {
            type: Array
        }
    },
    created() {
        this.rolePermissions = this.role.permissions.map(permission => permission.id);

    },
    methods: {
        togglePermission(id, value) {
            if (value) {
                this.rolePermissions.push(id)
            } else {
                this.rolePermissions = this.rolePermissions.filter(item => item !== id);
            }
        },
        async save() {
            const data = {
                permissions: this.rolePermissions
            }
            try {
                await this.$axios.post(`updatePermissions/${this.role.id}`, data)
                await successAlert('Permissions', 'updated')
                this.$emit('close')
            } catch (e) {
                await errorAlert('Permissions', 'updating');
            }
        }
    },
}
</script>

<style scoped lang="scss">
@import "~@/assets/sass/_mixins.scss";

#permissionsGrid {
    grid-template-columns: repeat(2, 1fr);
    @include lg {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
