import {extend} from 'vee-validate';
import {email, required} from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: (name) => {
        return `${name} is required`
    }
})


extend('email', email)
