<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
          <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut">Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light ">
                <div class="row mb-2 text-center">
                    <h1 class="col-md-12">Dispatch Planner</h1>
                </div>

                <div class="row mt-3 ">
                <div class="col-md-3 offset-1  text-dark">
                   <label class="text-light">
                       Select Year and Month
                   </label>
                    <vue-monthly-picker
                        v-model="wareghousefilters.selectedMonth"
                        placeHolder="Select Month"
                        @input="getinvoices"
                    >

                    </vue-monthly-picker>

                </div>
                    <div class="col-md-3   text-dark">
                        <label class="text-light">
                            Select Order Type
                        </label>
                        <v-select
                            v-model="wareghousefilters.orderType"
                            :disabled="wareghousefilters.selectedMonth?false:true"
                            class=""
                            placeholder="Choose Order Type"
                            :options="['Pick up from warehouse','Deliver to Customer','All']"

                            @input="getinvoices(false)"
                        />

                    </div>
                    <div class="col-md-3   text-dark">
                        <label class="text-light">
                            Select Branch
                        </label>

                        <v-select
                            v-model="wareghousefilters.selectedBranch"
                            :disabled="wareghousefilters.selectedMonth?false:true"
                            class=""
                            placeholder="Choose Branch"
                            :options="userbranches"

                            label="name"
                            :reduce="option => option.id"
                            @input="getinvoices(false)"
                        />

                    </div>

                </div>
                <div class="row mt-3">
                    <div v-if="showloader" class="col-md-10 offset-1 text-center">
                        <loader/>
                    </div>
                    <div class="col-md-10 offset-1 text-light" v-if="result">
                        <div class="row">
                            <h1 class="col-md-12 "> <hr class="hr0"></h1>
<!--                            <div class="col-md  " v-for="day in result.week_1" :key="day.date">-->
<!--                             <h2> <ul> {{day.day}}</ul></h2>-->
<!--                            </div>-->
                            <div class="col-md " >
                                <h2> <ul> Sunday</ul></h2>
                            </div>
                            <div class="col-md " >
                             <h2> <ul> Monday</ul></h2>
                            </div>
                            <div class="col-md " >
                                <h2> <ul> Tuesday</ul></h2>
                            </div>
                            <div class="col-md " >
                                <h2> <ul> Wednesday</ul></h2>
                            </div>
                            <div class="col-md " >
                                <h2> <ul> Thursday</ul></h2>
                            </div>
                            <div class="col-md " >
                                <h2> <ul> Friday</ul></h2>
                            </div>
                            <div class="col-md " >
                                <h2> <ul> Saturday</ul></h2>
                            </div>


                            <h1 class="col-md-12 "> <hr class="hr0"></h1>
                        </div>
                       <div class="row">
                          <div class="col-md border box-height" v-for="day in result.week_1" :key="day.date">
                          <div class="row">
                             <div class="col-md-12">
                                 <u > {{day.singledate}} </u>
                             </div>
                           <section style="margin:0px; height: 90px; max-height: 90px; overflow: auto; ">
                               <div class="col-md-12" v-for="dispatch in day.dispatches" :key="dispatch.id" style="overflow: auto; border-bottom: 1px solid green">
                                   <span :class="dispatch.mode=='Delivery'?'deliveryclass':'pickupclass'" @click.prevent="showmodal(dispatch)" class="cursor-pointer">
                                   {{dispatch.invoice.customer.lastName}} - {{dispatch.mode}} <span v-if="dispatch.invoice.customer.postcode">- {{dispatch.invoice.customer.postcode}} </span>
                                   </span>

                               </div>
                           </section>
                          </div>

                          </div>

                      </div>
                        <div class="row">
                            <div class="col-md border box-height" v-for="day in result.week_2" :key="day.date">

                                <div class="row">
                                    <div class="col-md-12">
                                        <u > {{day.singledate}} </u>
                                    </div>
                                    <section style="margin:0px; height: 90px; max-height: 90px; overflow: auto; ">
                                        <div class="col-md-12" v-for="dispatch in day.dispatches" :key="dispatch.id" style="overflow: auto; border-bottom: 1px solid green">
                                   <span :class="dispatch.mode=='Delivery'?'deliveryclass':'pickupclass'" @click.prevent="showmodal(dispatch)" class="cursor-pointer">
                                   {{dispatch.invoice.customer.lastName}} - {{dispatch.mode}} <span v-if="dispatch.invoice.customer.postcode">- {{dispatch.invoice.customer.postcode}} </span>
                                   </span>

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md border box-height" v-for="day in result.week_3" :key="day.date">
                                <div class="row">
                                    <div class="col-md-12">
                                        <u > {{day.singledate}} </u>
                                    </div>
                                    <section style="margin:0px; height: 90px; max-height: 90px; overflow: auto; ">
                                        <div class="col-md-12" v-for="dispatch in day.dispatches" :key="dispatch.id" style="overflow: auto; border-bottom: 1px solid green">
                                   <span :class="dispatch.mode=='Delivery'?'deliveryclass':'pickupclass'" @click.prevent="showmodal(dispatch)"  class="cursor-pointer">
                                   {{dispatch.invoice.customer.lastName}} - {{dispatch.mode}} <span v-if="dispatch.invoice.customer.postcode">- {{dispatch.invoice.customer.postcode}} </span>
                                   </span>

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md border box-height" v-for="day in result.week_4" :key="day.date">

                                <div class="row">
                                    <div class="col-md-12">
                                        <u > {{day.singledate}} </u>
                                    </div>
                                    <section style="margin:0px; height: 90px; max-height: 90px; overflow: auto; ">
                                        <div class="col-md-12" v-for="dispatch in day.dispatches" :key="dispatch.id" style="overflow: auto; border-bottom: 1px solid green">
                                   <span :class="dispatch.mode=='Delivery'?'deliveryclass':'pickupclass'" @click.prevent="showmodal(dispatch)" class="cursor-pointer">
                                   {{dispatch.invoice.customer.lastName}} - {{dispatch.mode}} <span v-if="dispatch.invoice.customer.postcode">- {{dispatch.invoice.customer.postcode}} </span>
                                   </span>

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md border box-height" v-for="day in result.week_5" :key="day.date">

                                <div class="row">
                                    <div class="col-md-12">
                                        <u > {{day.singledate}} </u>
                                    </div>
                                    <section style="margin:0px; height: 90px; max-height: 90px; overflow: auto; ">
                                        <div class="col-md-12" v-for="dispatch in day.dispatches" :key="dispatch.id" style="overflow: auto; border-bottom: 1px solid green">
                                   <span :class="dispatch.mode=='Delivery'?'deliveryclass':'pickupclass'" @click.prevent="showmodal(dispatch)" class="cursor-pointer">
                                   {{dispatch.invoice.customer.lastName}} - {{dispatch.mode}} <span v-if="dispatch.invoice.customer.postcode">- {{dispatch.invoice.customer.postcode}} </span>
                                   </span>

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

                <div class="row mt-5 mb-3">
                    <div class="col-md-8 offset-2">
                        <div class="row">
                            <div class="col-md-3">

                            </div>
                            <div class="col-md-6">
                                <h1 class="text-light text-center">Dispatch</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                              <button :class="selected_status=='Draft'?'btn btn-success':'btn btn-light'" @click="handle_selected_status('Draft')">New Orders</button>
                            </div>
                            <div class="col-md-2">
                                <button :class="selected_status=='Picked'?'btn btn-success':'btn btn-light'" @click="handle_selected_status('Picked')">Picked</button>
                            </div>
                            <div class="col-md-2">
                                <button :class="selected_status=='Approved'?'btn btn-success':'btn btn-light'" @click="handle_selected_status('Approved')">Dispatched</button>
                            </div>

                        </div>

                        <table class="table   table-responsive-sm">
                            <thead  class="thead-dark table-bordered " >
                            <tr class="">
                                <th scope="col">Date </th>
                                <th scope="col" class="col-4">Name</th>
                                <th scope="col" >Order No</th>
                                <th scope="col" >Postcode</th>
                                <th scope="col" >Type</th>
                                <th scope="col" >Action</th>
                                <th scope="col" >Dispatched</th>

                            </tr>
                            </thead>
                            <tbody class="text-light" >


                            <tr v-for="dis in filtered_dispatches" :key="dis.id" class="text-light">
                                <td>{{formatDate2(dis.date)}}</td>
                                <td @click.prevent="showmodal2(dis)"
                                    :class="{
                                   'Red': dis.dispatchitems.find((item) => item.color === 'Red'),
                                   'Orange': dis.dispatchitems.find((item) => item.color === 'Orange')
                                    }"
                                >
                                    {{dis.invoice.customer.lastName}}</td>
                                <td> {{dis.invoice.invoice_ref}} </td>
                                <td> {{dis.invoice.customer.postcode}}</td>

                                <td>{{dis.mode}}</td>
                                <td>
                                    <button v-if="(dis.status!='Draft' && authenticatedUser.permissions.includes('super'))" class="btn btn-danger mx-1" @click="dispatchundo(dis.id)" >Undo</button>
                                    <button v-if="dis.status=='Draft'" class="btn btn-light" @click="handle_picked(dis.id)" :disabled="getdate(dis.date)=='25 December'">Picked</button>
                                    <button v-if="dis.status=='Picked'" class="btn btn-light" @click="save_transaction('',true,dis)" :disabled="getdate(dis.date)=='25 December'">Complete</button>
                                    <button class="btn btn-info mx-1" @click="dispatch_print(dis)">Print</button>
                                </td>
                                <td></td>

                            </tr>

                            </tbody>
                        </table>

                    </div>
                </div>



            </div>

        </div>
        <b-modal  v-model="plannermodalShow"  centered  v-if="selected_dispatch" size="xl"   class="dispatchmodal "  @hidden="handleModalClose">
         <div class="row">
             <div class="col-md-12">
                 <h1 class="text-center" >Items</h1>
                 <table class="table table-bordered text-dark" >
                     <thead>
                     <tr>
                         <!--                           <th>ID</th>-->
                         <th>Date</th>
                         <th>Item Code</th>
                         <th>Item Description </th>
                         <th>Qty </th>
                         <th>Mode </th>
                         <th>Dispatched By </th>
                         <th v-if="selected_dispatch.invoice.refund!=1">Collected By</th>
                         <th v-else="">Returned By</th>

                     </tr>
                     </thead>
                     <tbody >

                     <tr  v-for="item in selected_dispatch.dispatchitems"  :key="item.id">

                         <!--                           <td>-->
                         <!--                               <b-link class="" :to="{ path: `/delivery-note/${dispatch.id}`, params: { id: dispatch.id } }">-->
                         <!--                                   {{dispatch.id}}-->
                         <!--                               </b-link>-->

                         <!--                           </td>-->
                         <td>{{formatDate(selected_dispatch.date)}} </td>
                         <td>{{item.itemCode}}</td>
                         <td>{{item.itemDescription}}</td>
                         <td>{{item.qty}}</td>
                         <td>{{selected_dispatch.mode}}</td>
                         <td>{{(cashiers.find(c=>c.id==selected_dispatch.cashier_id)).fullName }}</td>
                         <td>{{selected_dispatch.picked_by }}</td>

                     </tr>

                     </tbody>
                 </table>
                 <div class="col-md-3 offset-9 mb-3 d-flex justify-content-end">
<!--                     <button class="btn btn-danger" v-if="selected_dispatch.status=='Draft'" @click="cancel_transaction(selected_dispatch.id)">Delete</button>-->

<!--                     <button class="btn btn-success mx-2" v-if="selected_dispatch.status=='Draft'" @click="save_transaction('',true,dispatch.id)">Complete</button>-->

                     <b-link class="" :to="{ path: `/delivery-note/${selected_dispatch.id}`, params: { id: selected_dispatch.id } }">
                         <button class="btn btn-info">Print</button>
                     </b-link>

                 </div>
             </div>
         </div>
        </b-modal>

        <b-modal  v-model="dispatch_items_modal"  centered  v-if="selected_dispatch" size="xl"   class="dispatchmodal "  @hidden="handleModalClose">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="text-center" >Items</h1>
                    <table class="table table-bordered text-dark" >
                        <thead>
                        <tr>
                            <!--                           <th>ID</th>-->

                            <th>Item Code</th>

                            <th>Qty to be delivered </th>
                            <th>Current Branch Stock </th>
                            <th>Farnborough Warehouse Stock </th>


                        </tr>
                        </thead>
                        <tbody >

                        <tr  v-for="item in selected_dispatch.dispatchitems"  :key="item.id">

                            <!--                           <td>-->
                            <!--                               <b-link class="" :to="{ path: `/delivery-note/${dispatch.id}`, params: { id: dispatch.id } }">-->
                            <!--                                   {{dispatch.id}}-->
                            <!--                               </b-link>-->

                            <!--                           </td>-->

                            <td :class="item.color">{{item.itemCode}} </td>

                            <td>{{item.qty}}</td>
                            <td>
                                <span v-if="item.location_id!=7">{{item.quantity_at_local}} || {{item.item_sum_past_order_local}} || {{item.item_stoct_afterdispatch_local}}</span>
                                <span v-else=""> Location is Farnborough Ware House </span>
                            </td>
                              <td>{{item.quantity_at_hub}} || {{item.item_sum_past_order_hub}} || {{item.item_stoct_afterdispatch_hub}}   </td>



                        </tr>

                        </tbody>
                    </table>
<!--                    <div class="col-md-3 offset-9 mb-3 d-flex justify-content-end">-->
<!--                        &lt;!&ndash;                     <button class="btn btn-danger" v-if="selected_dispatch.status=='Draft'" @click="cancel_transaction(selected_dispatch.id)">Delete</button>&ndash;&gt;-->

<!--                        &lt;!&ndash;                     <button class="btn btn-success mx-2" v-if="selected_dispatch.status=='Draft'" @click="save_transaction('',true,dispatch.id)">Complete</button>&ndash;&gt;-->

<!--                        <b-link class="" :to="{ path: `/delivery-note/${selected_dispatch.id}`, params: { id: selected_dispatch.id } }">-->
<!--                            <button class="btn btn-info">Print</button>-->
<!--                        </b-link>-->

<!--                    </div>-->
                </div>
            </div>
        </b-modal>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import {mapActions} from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import VueMonthlyPicker from 'vue-monthly-picker'
//import apiService from "@/service/apiService";
import moment from 'moment';
import axios from 'axios';
import URL from "@/constants/baseurl";
import vSelect from "vue-select";
//import {cloneDeep} from "lodash";
import Swal from "sweetalert2";
import Loader from "@/components/loader";
import apiService from "@/service/apiService";
import {cloneDeep} from "lodash";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
import newinvoice from "@/constants/newinvoice";
import newcustomer from "@/constants/newcustomer";
import newpayment from "@/constants/newpayment";
import newitem from "@/constants/newitem";


//import vSelect from "vue-select";


export default {
    name: "settings",

    components:{Welcomenote, Loader, 'v-select': vSelect,'sidebar': sidebar,'ShowRealTime':showrealtime,VueMonthlyPicker},
    computed: {
        ...mapState('dispatch',['newtransaction','transactionshistory']),
        ...mapState('warehouse',['wareghousefilters',]),
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['cashiers','branches']),
        ...mapState('items',['searched_items']),
        userbranches(){
            if(this.authenticatedUser.permissions.includes('super') || this.authenticatedUser.permissions.includes('warehouse-manager')){
               let branches=cloneDeep(this.branches)
                branches=[{id:'all', name:'All'},...branches]
                return branches
            }
            else {
                return this.branches.filter((branch)=>branch.id==this.authenticatedUser.branch.id)
            }
        },
        // ...mapState('invoice', ['latestinvoices','cashiers','branches']),
        // ...mapState('invoice', ['branches']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
       // ...mapState('customers', ['customers']),
        filtered_dispatches(){
         if(this.result){
          let  dispatches=this.result.alldispatches

          dispatches=dispatches.filter((dis)=>dis.status==this.selected_status)
             if(this.selected_status=='Approved'){
                 dispatches=dispatches.sort((a, b) => new Date(b.date) - new Date(a.date));
             }
         // console.log(dispatches)
          return dispatches
          } else {
             return []
         }
        },
        selectedyear(){
         return    moment(this.wareghousefilters.selectedMonth).format('YYYY');
        },
        selectedmonth(){
            return    moment(this.wareghousefilters.selectedMonth).format('MMMM');
        },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {
            collected_by:'',
            pickedby:'',

            showloader:true,
            roles: [],
            user: {},
            modalShow:false,
            selected_order:{},
           result:null,
            cashier_id:'',
            order_status:'',
            branch_id:'',


            plannermodalShow:false,
            selected_dispatch:null,
            selected_status:"Draft",
            dispatch_items_modal:false,



        };
    },
    async created() {



        const promises =[
            this.getbranches(),
            this.getinvoices(true),
            this.getUsers(),
            this.getRoles()

        ]
        // Wait for all promises to complete
        await Promise.all(promises);

    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),

        ...mapActions('invoice', ['updateFullInvoice','getbranches','getcashiers']),
        ...mapActions('dispatch', ['add_selected_dispatch']),
        ...mapActions('items', ['getItems']),
        ...mapActions('dispatch',['add_dis_items','add_transaction','remove_transaction','attach_invoice_transactions','reset_transactionshistory','dispatchPicked']),



        ...mapActions('items',['add_searched_items','attachinvoiceitems']),


        ...mapActions('dispatch',['add_dis_items','add_transaction','remove_transaction','attach_invoice_transactions','reset_transactionshistory','dispatchPicked']),
        ...mapActions('customers', ['getCustomers','set_selectedcustomer_balance','set_selectedcustomer']),
        ...mapActions('invoice', ['updateFullInvoice','getcashiers' ,'getbranches']),
        ...mapActions('notes',['add_new_note','attachinvoicenotes']),
        ...mapActions('payment', ['setNewpayment']),


        async handle_picked(dispatch_id) {

            const swalResult = await Swal.fire({
                title: 'Mark as Picked',
                text: 'Picked By:',
                input: 'text',
                inputPlaceholder: 'Enter Name who is picking...',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, mark it!',
                preConfirm: (inputValue) => {
                    if (!inputValue) {
                        Swal.showValidationMessage('Please enter name who is picking');
                    }
                    this.pickedby=inputValue
                    return inputValue;
                }
            });

            if (swalResult.isConfirmed) {


                try {
                    const { data } = await apiService.get(`/mark-dispatch-picked/${dispatch_id}/${this.pickedby}`);
                   // console.log(data.status);

                    if (data.status === 'Picked') {
                        // let transactionshistory = this.transactionshistory.map((dispatch) => {
                        //     if (dispatch.id === dispatch_id) {
                        //         return { ...dispatch, status: 'Picked' };
                        //     } else {
                        //         return dispatch;
                        //     }
                        // });

                        // this.dispatchPicked(transactionshistory);
                         await this.getinvoices(false);
                         this.newpos()

                        Swal.fire('Success!', 'Transaction Saved Successfully!', 'success');
                    }
                } catch (error) {
                    // Handle errors, you might want to show a Swal alert for errors too
                    console.error('Error marking dispatch as picked:', error);
                    Swal.fire('Error!', 'An error occurred while processing the request.', 'error');
                }
            }
        },


        async save_transaction(disall = '', approve = false, dispatch = null) {
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }



            // Add a Swal confirmation alert
            const swalResult = await Swal.fire({
                title: 'Dispatch Transaction',
                text: 'Do you want to dispatch this transaction?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, dispatch it!',
                input: 'text',
                inputPlaceholder: 'Enter Name who is collecting...',
                preConfirm: (inputValue) => {
                    if (!inputValue) {
                        Swal.showValidationMessage('Please enter name who is collecting');
                    }
                    this.collected_by=inputValue
                    return inputValue;
                }
            });

            if (swalResult.isConfirmed) {
                let data = {};
                if (approve) {
                    data.id = dispatch.id;
                    data.location_id = dispatch.location_id;
                    data.invoice_id = dispatch.invoice.id;
                    data.customer_id = dispatch.invoice.customer_id;
                    data.dis_items = dispatch.dispatchitems;
                    data.status = 'Approved';
                    data.collected_by = this.collected_by;
                }
                try {
                    const res = await axios.post(`${URL.BASEURL}/api/create-transaction`, data);

                    if (res) {
                       // alert('ok')
                       // console.log(res.data,'==============data=================')
                       //  let transactionshistory = this.transactionshistory.map((dispatch) => {
                       //      if (dispatch.id === res.data.id) {
                       //          return res.data;
                       //      } else {
                       //          return dispatch;
                       //      }
                       //  });

                        // this.dispatchPicked(transactionshistory);
                        //
                        await this.getinvoices(false);
                        this.newpos()
                     //   alert('ok')



                        if (disall !== 'Dispatch_All') {
                            Swal.fire('Success!', 'Transaction Saved Successfully!', 'success');
                        }
                        await this.getItems()

                    } else {
                        // Handle the case when res is falsy
                    }
                } catch (error) {
                    console.error(error);
                    Swal.fire('Error', 'Failed to add Transaction.', 'info');
                }
            }
        },
        addsearchitem(dummy='', itemcode=null){

            // alert(this.searchitem)
            //console.log((this.searched_items).length)
            if(dummy=='yes' || (this.searched_items).length==0) {
                this.add_searched_items(newitem)
            }
            else if(itemcode) {
                const   item=this.items.find((i) => i.itemCode==itemcode)
                //console.log(item,'item')

                if(item){
                    //  console.log(item[0])


                    if(this.selected_customer.cusDiscount){
                        // console.log(item,"==============default==================")

                        item.original_price=cloneDeep(item.defaultPrice)
                        item.defaultPrice= (cloneDeep(item.defaultPrice) - Number(item.defaultPrice)*(Number(this.selected_customer.cusDiscount)/100)).toFixed(2)
                        // item.cusDiscount=this.selected_customer.cusDiscount
                        // console.log(item,"==============default==================")
                    }
                    this.add_searched_items(item)
                    this.update_invoice('subTotal', this.subTotal)
                    this.update_invoice('grandTotal', Number(this.subTotal)+Number((this.subTotal*0.2).toFixed(2)))
                    this.update_invoice('vat', (this.subTotal*0.2).toFixed(2))
                    const   dummyitem=this.searched_items.filter((i) => i.qty=='')
                    this.removeitem(dummyitem[0].mapid)
                    // console.log(dummyitem,'dummyitem')

                }
                else {
                    Swal.fire('Item Not Found', 'No item available for this code', 'info');
                    //  alert('not found')
                }
            }
            //  console.log(this.searched_items)

        },
        newpos(){
            let newinv=  cloneDeep(newinvoice)
            this.updateFullInvoice(newinv)

            this.attachinvoiceitems([])
            this.attachinvoicenotes([])
            //  this.selectedCus('0123456789','yes');
            this.$store.commit('customers/setSelectedCustomer', cloneDeep(newcustomer))

            this.addsearchitem();
            this.searchcustomer=''
            this.setNewpayment(cloneDeep(newpayment))
            this.add_dis_items([])
            this.attach_invoice_transactions([])
            this.newtransaction.id=''
            this.newtransaction.invoice_id=''
            this.newtransaction.cashier_id=''
            this.newtransaction.picked_by=''
            this.newtransaction.mode=''
            this.disradio=false
            this.notesmodalShow=false
        },



        handle_selected_status(status){
            this.selected_status=status
        },
        showmodal(dispatch){
           // console.log(dispatch)
            this.add_selected_dispatch(dispatch)
            this.selected_dispatch=dispatch
            this.plannermodalShow = ! this.plannermodalShow
        },
        dispatch_print(dispatch){
             console.log(dispatch)
            this.updateFullInvoice(dispatch.invoice)
            this.add_selected_dispatch(dispatch)
            this.set_selectedcustomer(dispatch.invoice.customer)
            const routeParams = { path: `/delivery-note/${dispatch.id}`, params: { id: dispatch.id } };

            // Navigating to the specified route
            this.$router.push(routeParams);

        },
        showmodal2(dispatch){
            // console.log(dispatch)
            this.add_selected_dispatch(dispatch)
            this.selected_dispatch=dispatch
            this.dispatch_items_modal = ! this.dispatch_items_modal
        },
        handleModalClose() {

            this.add_selected_dispatch(null)

        },
        formatDate(date){
            let date1 = new Date(date).toLocaleString('en-US', {timeZone: 'Europe/London'});
            let date2 =  moment(date1).format('D MMM, h:mm A');

            return date2;
        },
        formatDate2(date){
            let date1 = new Date(date).toLocaleString('en-US', );
            let date2 =  moment(date1).format('DD MMM, YYYY');

            return date2;
        },
        getdate(date){
            let date1 = new Date(date).toLocaleString('en-US', );
            let date2 =  moment(date1).format('DD MMMM');

            return date2;
        },


        async cancel_transaction(id){
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            const result = await Swal.fire({
                title: 'Are you sure you want to delete this transaction?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            });
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${URL.BASEURL}/api/cancel-transaction/${id}`);

                    if (res.data == 'done') {
                        // this.remove_transaction(id)
                        Swal.fire('success', 'Transaction  Deleted', 'success');

                    } else {
                        Swal.fire('info', 'Transaction Cant be Deleted', 'info');
                    }
                } catch (error) {
                    console.error(error);
                    Swal.fire('Error', 'Failed to add Transaction.', 'info');
                }
            }

        },
        async dispatchundo(dispatch_id){
            const result = await Swal.fire({
                title: 'Are you sure you want to Undo this transaction?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Undo it!',
            });
            if (result.isConfirmed) {
                const {data} = await apiService.get(`/undo-dispatch/${dispatch_id}`);
                if (data.status == 'Draft') {


                    Swal.fire('Success!', 'Un Do is Completed Successfully!', 'success');
                    await this.getinvoices(false);
                    this.newpos()

                }
            }

        },
       // ...mapActions('customers', ['getCustomers']),
       // ...mapActions('invoice', ['updateFullInvoice',]),

        async reloadcb(){
            if(this.cashiers.length==0){
                //   alert(this.cashiers.length)
             //   await this.getcashiers()



            }
           // await this.getinvoices(true)

        },

        // async getallcustomers(){
        //     let user = localStorage.getItem('user');
        //     user=  JSON.parse(user)
        //     const branch_id=user.branch.id
        //     let superadmin='yes';
        //
        //
        //     let {data} = await apiService.get(`/customers/${superadmin}/${branch_id}`);
        //     this.customers=data
        //
        // },


        async  getinvoices(start=true){
            this.showloader=true
            let user = localStorage.getItem('user');
            user=  JSON.parse(user)
            if(start){




                if(user.permissions.includes('super')){

                   if(!this.wareghousefilters.selectedBranch){
                       this.wareghousefilters.selectedBranch='all'
                   }

                } else {
                    this.wareghousefilters.selectedBranch=user.branch.id
                }
            }


            let formattedDate = moment(this.wareghousefilters.selectedMonth).format('YYYY-MM-DD HH:mm:ss');
            // if(curdate==true){
            //     formattedDate= moment().format('YYYY-MM-DD HH:mm:ss');
            //
            // }
            let data1={
                   date:formattedDate,
                   branch_id:this.wareghousefilters.selectedBranch,
                   order_type:this.wareghousefilters.orderType
              }
                const apiToken = localStorage.getItem('token');
                if (apiToken) {
                    // Set the Authorization header with the API token
                    axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                }


            axios.post(`${URL.BASEURL}/api/getplannerinvoices`, data1)
                .then(async (res) => {
                    this.result=res.data
                    this.showloader=false

                    // console.log(this.result.week_1[0].date)

                })
                .catch((error) => {
                    console.log(error)
                });

        },
        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}
.box-height{
    height: 115px;
}
.hr0{
    margin-top: 0px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid #ffffff;
    max-width: 100%;

}
.deliveryclass{
    color: orange;
}
.pickupclass{
    color: lightblue;
}

.Red{
    color: red;
}
.Orange{
    color: orange;
}


</style>
