<template>
    <div class="navWrapper">
        <div class="sticky-top" id="sideBar">
            <div
                :class="isActive('dashboard')"
                class="header d-flex align-items-center px-2 py-3"
            >
                <b-icon
                    class="ml-2"
                    icon="speedometer2"
                    scale="1.25"
                    aria-hidden="true"
                ></b-icon>
                <b-link class="ml-3 font-weight-bold" to="/dashboard">Dashboard
                </b-link>
            </div>






            <div>
                <div
                    class="header d-flex px-2 py-3 align-items-center"
                    v-b-toggle.collapse-6
                >
                    <div class="d-flex align-items-center flex-grow-1 ml-2">
                        <b-icon
                            icon="file-earmark-text"
                            scale="1.25"
                            aria-hidden="true"
                        ></b-icon>
                        <div class="font-weight-bold ml-3">Admin</div>
                    </div>
                    <b-icon
                        icon="caret-down-fill"
                        scale="0.8"
                        aria-hidden="true"
                    ></b-icon>
                </div>
                <b-collapse id="collapse-6">
                    <div
                        v-can="'view users'"
                        class="sidebar-item"
                        :class="isActive('users')"
                    >
                        <b-icon
                            icon="person"
                            scale="1.25"
                            aria-hidden="true"
                        ></b-icon>
                        <b-link to="/users">Users</b-link>
                    </div>

                </b-collapse>
            </div>
        </div>
        <div>

            <div
                class="header d-flex px-2 py-3 align-items-center">
                <div class="d-flex align-items-center flex-grow-1 ml-2">


                    <b-link class="ml-3 font-weight-bold" to="/">
                        <div class="font-weight-bold ml-3"> POS</div>
                    </b-link>
                </div>

            </div>

    </div>
        <div class="d-flex ml-auto">
            <div class="mr-4">



            </div>
            <b-dropdown
                variant="link"
                toggle-class="p-0 pr-2 text-white notification-dropdown"
            >
                <template #button-content>
                    <b-icon
                        font-scale="1.4"
                        class="mb-0 ms-3 text-dark"
                        icon="person"
                    ></b-icon>
                </template>
                <b-dropdown-item to="profile">profile</b-dropdown-item>
                <b-dropdown-item @click.prevent="signOut"
                >Logout
                </b-dropdown-item
                >
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";



export default {
    name: "Header",
    components: {},
    data() {
        return {


        };
    },

    computed: {


    },
    watch: {

    },
    mounted() {
        // document.getElementById('sideBar').click();



    },

    created() {

    },
    methods: {
        ...mapActions(["processLogoutManual"]),



        isActive(path) {
            return {"bg-green": this.$route.path.slice(1) === path};
        },
        async signOut() {
            await this.processLogoutManual();
            await this.$router.push({name: "Login"});
        },
    },
};
</script>
<style lang="scss">
@import "@/assets/sass/_variables";

.navWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #f1f1f1;
}

#sideBar {
    // background-color: #f1f1f1;
    // max-height: calc(100vh - 255px);
    top: 0;
    // overflow-y: auto;
    display: flex;
    gap: 1rem;

    .sidebar-item {
        // @extend .border-bottom;
        @extend .d-flex;
        @extend .px-4;
        @extend .py-2;
        @extend .align-items-center;
        background: white;

        a {
            @extend .ml-2 !optional;
            color: #343a40;
        }
    }

    .header {
        position: relative;

        a {
            color: $dark;
        }

        &.bg-green {
            a {
                color: white;
            }
        }
    }

    & .bg-green {
        background: $green-color;
        color: white !important;

        & > a {
            color: white !important;

            &:hover {
                color: white;
            }
        }
    }

    .collapse {
        &.collapsing {
            position: absolute;
        }

        &.show {
            position: absolute;
            top: 45px;
        }
    }

    @media all and (max-width: 800px) {
        display: none;
    }
}

#item {
    background-color: rgb(255, 255, 255);
    width: 100%;
}

.li a {
    text-decoration: none;
    color: black;
}

:focus {
    outline: 0;
}

.notification-icon {
    position: relative;

    .badge {
        font-size: 9px;
        position: absolute;
        top: -4px;
        right: 0px;
    }
}

.notification-dropdown {
    box-shadow: none;
    outline: none;
    position: relative;

    &::after {
        color: #343a40;
    }
}

.dropdown.b-dropdown.show {
    .dropdown-menu {
        &.show {
            left: auto;
            right: 0;
        }
    }
}
</style>
