import Vue from 'vue'
import Vuex from 'vuex'
import users from "./modules/users";
import devices from "./modules/devices";
import invoice from "./modules/invoice";
import payment from "./modules/payment";
import items from "./modules/items";
import notes from "./modules/notes";
import dispatch from "./modules/dispatch";
import customers from "./modules/customers";
import orders from "./modules/orders";
import collections from "./modules/collections";
import warehouse from "./modules/warehouse";
import purchaseorder from "./modules/purchaseorder";
import apiService from "../service/apiService";

Vue.use(Vuex)
const store = new Vuex.Store({
    strict: false,
    state: {
        user: {},


    },
    getters: {
        AuthenticatedUser: (state) => state.user,
    },
    mutations: {
        setUser: (state, user) => state.user = user,
    },
    actions: {
        async login({commit}, credentials) {
            const user = (await apiService.post('/login', credentials)).data;
            localStorage.setItem('token', user.user.token);

            localStorage.setItem('user', JSON.stringify(user.user));
            commit('setUser', user)
        },

        async processLogoutManual({commit}) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            commit('setUser', {})

        },
    },

    modules: {

        users,
        devices,
        invoice,
        items,
        customers,
        payment,
        notes,
        dispatch,
        orders,
        collections,
        warehouse,
        purchaseorder

    },

})

export default store
