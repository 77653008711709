<template>
    <section>
        <b-card>
            <div v-can="'create permission'" class="text-right">
                <b-button v-b-modal.permission-form class="primary-btn ml-3" variant="secondary" size="sm">Add New Permission
                </b-button>
            </div>
            <b-table bordered show-empty small head-variant="dark" class=" mt-3 text-center" :fields="fields"
                     :items="permissions.data">
                <template #cell(created_at)="data">
                    {{ moment(data.item.created_at).format('DD/MM/YYYY') }}
                </template>
                <template #cell(actions)="data">
                    <div class='d-flex justify-content-center'>
                        <a v-can="'edit permission'" @click.prevent="editPermission(data.item)"
                           :href="`/edit-permission/${data.item.id}`">
                            <b-icon variant="info" font-scale="1.5" icon="pencil-square"></b-icon>
                        </a>
                        <a v-can="'delete permission'" @click.prevent="deletePermission(data.item.id)"
                           href="/permissions/delete"
                           class="ml-2">
                            <b-icon variant="danger" font-scale="1.5" icon="trash"></b-icon>
                        </a>
                    </div>
                </template>
            </b-table>
            <b-pagination
                aria-controls="my-table"
                size="sm"
                :total-rows="permissions.total"
                v-model="currentPage"
                @input="getPermissions"
                per-page="15"
                prev-text="Prev"
                next-text="Next"
                class="justify-content-center"
            ></b-pagination>
        </b-card>
        <b-modal id="permission-form" hide-header hide-footer centered size="md">
            <PermissionForm :permission-prop="permission" @close="closeModal" :form-type="formType"/>
        </b-modal>
    </section>

</template>
<script>
import moment from "moment";
import {confirmDeleteAlert, serverErrorAlert, successAlert} from "@/components/Alerts";
import PermissionForm from "@/components/Pemission/PermissionForm";

export default {
    name: "Permissions",
    components: {PermissionForm},
    computed:{
        fields() {
            return [
                {
                    key: 'name',
                    label:'Name'
                },
                {
                    key: 'created_at',
                    label: 'Created Date'
                },
                {
                    key: 'actions',
                    label: 'Actions'
                }
            ]
        }
    },
    data() {
        return {

            permissions: [],
            formType: 'add',
            permission: {},
            currentPage: 1
        }
    },
    created() {
        this.getPermissions()
    },
    methods: {
        async getPermissions() {
            const {data} = await this.$axios.get(`permissions?page=${this.currentPage}`);
            this.permissions = data
        },
        async deletePermission(id) {
            const {isConfirmed} = await confirmDeleteAlert('permission')
            if (isConfirmed) {
                try {
                    await this.$axios.delete(`permissions/${id}`)
                    await successAlert('Permission', 'deleted')
                    await this.getPermissions()
                } catch (e) {
                    await serverErrorAlert();
                }
            }
        },
        closeModal() {
            this.$bvModal.hide("permission-form");
            this.getPermissions()
            this.formType = 'add';
        },
        editPermission(item) {
            this.formType = 'edit'
            this.permission = {...item};
            this.$bvModal.show('permission-form')
        },
        moment() {
            return moment()
        },
    }
}
</script>

<style scoped>

</style>
