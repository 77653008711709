<template>

<span>
     Welcome {{authenticatedUser.first_name}} {{authenticatedUser.last_name}}, {{authenticatedUser.branch.name}} Branch
</span>
</template>

<script>

import { mapGetters} from 'vuex';





export default {
    name: "welcomenote",


    computed: {
        ...mapGetters(['AuthenticatedUser']),




        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },


    },




}
</script>



