<template>
    <div>
        <Header/>
        <PageHeader>
            <template #left>
                <b-icon-cloud-download/>
                Data Export
            </template>
            <!--            <template #right>
                            <b-ico Alarmsn-clipboard-plus />

                        </template>-->
        </PageHeader>
        <b-container fluid>
            <div class="my-3">
                <div class="">
                    <div class="m-2">

                        <b-row>
                            <b-col col lg="6" class="mb-3">
                                <h3 class="section-heading">Alarm History</h3>
                                <b-card body-class="" class="shadow-sm border h-100">
<!--                                    <div class="d-flex items-center justify-content-end">-->
<!--                                        <div class="d-flex items-center justify-content-end"><h5 class="mr-2">Devices</h5>-->
<!--                                            <b-form-select value-field="id" text-field="device_name" style="min-width: 190px; max-width: 380px; " class="bg-white dropdownFix" v-model="device_selected" :options="get_all_devices"></b-form-select>-->
<!--                                        </div>-->
<!--                                    </div>-->
                                    <b-form-select value-field="id" text-field="device_name" style="width: 170px;" class="bg-white dropdownFix" v-model="device_selected" :options="get_all_devices"></b-form-select>
                                    <DatePicker v-model="start_date" style="width: 170px" format="YYYY-MM-DD" type="date" :id="'start'" :placeholder="'Start Date Start'" class="bg-white ml-2"/>
                                    <DatePicker class="bg-white ml-2" style="width: 170px" v-model="end_date" :id="'end'" format="YYYY-MM-DD" type="date" :placeholder="'End Date End'"/>
                                    <export-excel
                                        class="btn px-3 ml-2 pr-2 btn-info"
                                        :fetch="fetchAlarmHistoryDataToExport"
                                        :fields="excel_export_fields_name_alarm_history"
                                        :before-generate="startDownload"
                                        :before-finish="finishDownload"
                                        worksheet="Alarm-History"
                                        :name="'Alarm-History-' +Math.floor(Math.random() * 100) +'-.xls'">
                                        {{ "Export To Excel" }}
                                    </export-excel>
                                </b-card>
                            </b-col>
                            <b-col col lg="6" class="mb-3">
                                <h3 class="section-heading">Tracking Data</h3>
                                <b-card body-class="" class="shadow-sm border h-100">
                                    <div class="d-flex flex-wrap gap-1 w-100">
                                        <b-form-select value-field="id" text-field="device_name" style="width: 170px;" class="bg-white dropdownFix" v-model="device_selected_tracking" :options="get_all_devices"></b-form-select>
                                        <DatePicker v-model="start_date_tracking" style="width: 170px" format="YYYY-MM-DD" type="date" :id="'start_tracking'" :placeholder="'Start Date Start'" class="bg-white ml-2"/>
                                        <DatePicker class="bg-white ml-2" style="width: 170px" v-model="end_date_tracking" :id="'end_tracking'" format="YYYY-MM-DD" type="date" :placeholder="'End Date End'"/>
                                        <export-excel
                                            class="btn px-3 ml-2 pr-2 btn-info"
                                            :fetch="fetchDeviceTrackingHistoryDataToExport"
                                            :fields="excel_export_fields_name_tracking_history"
                                            :before-generate="startDownload"
                                            :before-finish="finishDownload"
                                            worksheet="Tracking Data"
                                            :name="'Alarm-Tracking-Data-' +Math.floor(Math.random() * 100) +'-.xls'">
                                            {{ "Export To Excel" }}
                                        </export-excel>
                                    </div>
                                </b-card>
                            </b-col>
                        </b-row>
                    </div>

                </div>
            </div>
        </b-container>
    </div>
</template>

<script>
import moment from "moment";

import DatePicker from "vue2-datepicker";

import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";
import apiService from "../service/apiService";

export default {
    name: "DataExport",
    components: {
        Header,
        PageHeader,
        DatePicker
    },
    data() {
        return {
            device_selected: {},
            device_selected_tracking: {},
            get_all_devices: [],
            start_date: null,
            start_date_tracking: null,
            end_date: null,
            end_date_tracking: null,
            excel_export_fields_name_alarm_history: {
                "Device Name": "devices.device_name",
                "Activity": "log_name",
                // "Action Performed": "user.first_name",
                "Description": "description",
                "Date": {
                    field: "created_at",
                    callback: (value) => {
                        return moment(value).format("DD/MM/YYYY HH:mm:ss");
                    },
                },
            },
            excel_export_fields_name_tracking_history: {
                "Device Name": "device.device_name",
                "Date": {
                    field: "created_at",
                    callback: (value) => {
                        return moment(value).format("DD/MM/YYYY HH:mm:ss");
                    },
                },
                "Movement": "moving",
                "Sos/Panic": "sos",
                "Fall Down/Shock": "shock",
            },


        };
    },
    created() {
        this.$isLoading(true)
        this.getDevices();
        this.$isLoading(false)
    },
    mounted() {

    },
    beforeDestroy() {

    },
    computed: {},
    methods: {
        async getDevices() {
            const {data} = await apiService.get(`get_all_devices`);
            this.get_all_devices = data;
            /*if (this.get_all_devices.length) {
                this.device_moving = this.get_all_devices[0].id;
                this.device_sos = this.get_all_devices[0].id;
                this.device_shock = this.get_all_devices[0].id;
                this.device_id = this.get_all_devices[0].id;
                this.device_selected = this.get_all_devices[0];

                await this.getDeviceLastStat(this.device_id);
                await this.getBarData("moving");
                await this.getBarData("sos");
                await this.getBarData("shock");
            }*/
        },
        startDownload() {
            console.log("show loading");
        },
        finishDownload() {
            console.log("hide loading");
        },
        async fetchAlarmHistoryDataToExport() {
            const start_date =
                this.start_date !== null
                    ? moment(this.start_date).format("YYYY-MM-DD")
                    : null;
            const end_date =
                this.end_date !== null
                    ? moment(this.end_date).format("YYYY-MM-DD")
                    : null;
            if(this.device_selected == null){
                alert('Device Not selected');
                return false;
            }
            this.$isLoading(true);
            try{
                const {data} = await apiService.get(
                    `get_alarms_history?device_id=${this.device_selected}&start_date=${start_date}&end_date=${end_date}&export=1`
                );
                this.$isLoading(false);
                return data.data;
            } catch (e) {
                console.log(e);
                this.$isLoading(false);
                return false;
            }
        },
        async fetchDeviceTrackingHistoryDataToExport() {
            const start_date =
                this.start_date_tracking !== null
                    ? moment(this.start_date_tracking).format("YYYY-MM-DD")
                    : null;
            const end_date =
                this.end_date_tracking !== null
                    ? moment(this.end_date_tracking).format("YYYY-MM-DD")
                    : null;
            if(this.device_selected_tracking == null){
                alert('Device Not selected');
                return false;
            }
            this.$isLoading(true);
            try{
                const {data} = await apiService.get(
                    `get_device_tracking_history?device_id=${this.device_selected_tracking}&start_date=${start_date}&end_date=${end_date}&export=1`
                );
                this.$isLoading(false);
                return data.data;
            } catch (e) {
                console.log(e);
                this.$isLoading(false);
                return false;
            }

        },


    },
    filters: {
        moment: function (date) {
            return moment(date).format("MMM D h:mm a");
        },
    },
};
</script>

<style lang="scss">
</style>
