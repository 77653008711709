<template>
  <div id="app">
    <main>
      <router-view :key="$route.fullPath"></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
</style>
