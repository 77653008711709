export default {
    namespaced: true,
    state: () => ({

        selected_collection:{}

    }),
    mutations: {

        addSelectedCollection(state, data){
            state.selected_collection = data
        }


    },
    actions: {

        add_selected_collection({commit},col){
            commit('addSelectedCollection', col)


        },

    },


}
