<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">
                <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                Welcome, {{authenticatedUser.branch.name}} Branch

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light">

                <div class="row  mt-2">

                    <b-link class="" :to="{ path: '/customerform',query: { type: 'add'} }">
                        <b-button class="btn-info mb-2" > Add New Customer </b-button>
                    </b-link>

                    <div class="col-md-2 ">
                        <b-input v-model="searchcustname" placeholder="Name"></b-input>
                    </div>
                    <div class="col-md-2">
                        <b-input v-model="searchcustphone" placeholder="Telephone"></b-input>
                    </div>
                    <div class="col-md-2">
                        <b-input v-model="searchcustemail" placeholder="Email"></b-input>
                    </div>
                    <div class="col-md">
                        <v-select
                            v-model="custtype"

                            class=""
                            placeholder="Type"
                            :options="['Retail','Trade']"

                        />
                    </div>
                    <div class="col-md">
                        <b-form-checkbox

                            v-model="credit"

                        >
                            Customers With Credit
                        </b-form-checkbox>
                    </div>




                    <b-table id="customers-table"  class="bg-light" :items="filteredCustomers" :fields="fields" striped hover responsive :per-page="perPage" :current-page="currentPage">


                        <template #cell(actions)="data">

                            <b-link class="" :to="{ path: '/customerform',query: { type: 'edit', 'id': data.item.id } }">
                                <b-button class="btn-info mb-2" > Edit </b-button>
                            </b-link>
                            <b-link class="mx-1" :to="{ path: '/customerform',query: { type: 'view', 'id': data.item.id } }">
                                <b-button class="btn-info mb-2" > View </b-button>
                            </b-link>
<!--                            <b-link class="mx-1" :to="{ path: '/customerform',query: { type: 'view', 'id': data.item.id } }">-->
<!--                                <b-button class="btn-info mb-2" > Orders </b-button>-->
<!--                            </b-link>-->

                        </template>

                    </b-table>
                    <b-pagination
                        class=""
                        v-model="currentPage"
                        :total-rows="customers.length"
                        :per-page="perPage"
                        aria-controls="customers-table"
                    ></b-pagination>
                </div>



            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import vSelect from "vue-select";
//import vSelect from "vue-select";


export default {
    name: "customers",

    components:{ 'v-select': vSelect,'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
         ...mapState('customers', ['customers']),
        // ...mapState('invoice', ['branches']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },
        filteredCustomers() {


           let  fcustomers=this.customers;

            if(this.branch_id){
                fcustomers=fcustomers.filter(dfo=>dfo.branch_id===this.branch_id)
            }
            if(this.cashier_id){
                fcustomers=fcustomers.filter(dfo=>dfo.cashier_id===this.cashier_id)
            }
            if(this.order_status){
                fcustomers=fcustomers.filter(dfo=>dfo.status===this.order_status)
            }
            if(this.searchcustname){
                let searchcustname=this.searchcustname.toLowerCase();

                fcustomers=fcustomers.filter(customer=> {
                    const name = customer.lastName ? customer.lastName.toLowerCase() : '';
                    return name.includes(searchcustname)
                })
            }
            if(this.searchcustphone){
                let searchcustphone=this.searchcustphone.toLowerCase();

                fcustomers=fcustomers.filter(customer=> {
                    const mob = customer.mobile ? customer.mobile.toLowerCase() : '';
                    return mob.includes(searchcustphone)
                })
            }

            if(this.searchcustemail){
                let searchcustemail=this.searchcustemail.toLowerCase();

                fcustomers=fcustomers.filter(customer=> {
                    const email = customer.email ? customer.email.toLowerCase() : '';
                    return email.includes(searchcustemail)
                })
            }
            if(this.custtype){
                let custtype=this.custtype.toLowerCase();

                fcustomers=fcustomers.filter(customer=> {
                    const customerType = customer.customerType ? customer.customerType.toLowerCase() : '';
                    return customerType.includes(custtype)
                })
            }

            if(this.credit){


                fcustomers=fcustomers.filter(customer=> {
                    const balance = customer.walletBalance ? Number(customer.walletBalance)>0:false;
                    return balance
                })
            }



            return fcustomers
        },

    },
    data() {
        return {
            roles: [],
            user: {},
            perPage:5,
            currentPage: 1,

            cashier_id:'',
            order_status:'',
            branch_id:'',
            searchcustname:'',
            searchcustphone:'',
            searchcustemail:'',
            custtype:'',
            credit:null,

            fields: [

                { key: 'lastName', label: 'Name' },
                { key: 'mobile', label: 'Mobile' },
                { key: 'customerType', label: 'Customer Type' },
                { key: 'email', label: 'Email' },
                { key: 'walletBalance', label: 'Balance' },
                'actions'

            ],

        };
    },
    async created() {
        await this.getUsers();
        await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()
          await this.getCustomers();

    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('customers', ['getCustomers']),


        async signOut() {
            await this.processLogoutManual();
           // await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
