<template>
    <div class="">
        <Header/>
        <PageHeader>
            <template #left>
                <b-icon-clipboard-plus class="ml-3"/>
                Create User
            </template>
        </PageHeader>
        <b-container fluid class="my-5">
            <b-card class="shadow-sm">
                <UserForm></UserForm>
            </b-card>
      </b-container>
    </div>
</template>

<script>
import UserForm from "@/components/User/UserForm";
import Header from "@/components/layout/Header";
import PageHeader from "@/components/layout/PageHeader";
export default {
    name: "CreateUser",
    components: {Header, PageHeader,UserForm},
}
</script>

<style scoped>

</style>
