
import moment from "moment";

export default {
    namespaced: true,
    state: () => ({

        wareghousefilters:{
            selectedBranch:'',
            selectedMonth:moment().format('YYYY-MM-DD HH:mm:ss'),
            orderType:'Deliver to Customer',

        }

    }),
    mutations: {



    },


    actions: {


    },


}
