export default {
     //  BASEURL: "http://127.0.0.1:8000"


   //    BASEURL: "http://posapi.skymovers.co.uk"




    BASEURL: "https://devpostiles.dev.techliance.com"
  //  BASEURL: 'http://postiles.dev.techliance.com',
    // baseURL: 'http://posapi.righttiles.co.uk',
    // baseURL: 'http://devpostiles.dev.techliance.com',


}
