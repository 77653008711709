<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
              <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white">

                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-light text-center">
                            InBound Stock
                        </h1>
                    </div>
                    <div class="col-md-12 text-light">
                        <h1 class="text-center">Details</h1>
                        <h1 class="col-12 "> <hr class="hr0"></h1>
                        <div class="row mt-1">

                            <div class="col-md-4">
                                <label><strong>Enter Purchase Order Reference</strong></label>
                                <b-input v-model="por" disabled></b-input>
                            </div>
                            <div class="col-md-4">
                                <label><strong>Select Date</strong></label>
                                <b-form-datepicker

                                    :reset-button=false
                                    reset-button-variant="btn btn-warning"
                                    id="datepicsuper"

                                    v-model="date"
                                    placeholder="Date"

                                    dropup  calendar-width="100%">

                                </b-form-datepicker>
                            </div>
                            <div class="col-md-4">
                                <label><strong>Select Location</strong></label>
                                <b-form-select
                                    v-model="location_id"
                                    :options="locations"
                                    class="bgsecond bform text-white mb-2 "
                                    :disabled="edit"
                                    value-field="id"
                                    text-field="location_name"
                                >

                                    <template #first>
                                        <b-form-select-option value="">Select Location</b-form-select-option>
                                    </template>
                                    <template #option="{ option }">
                                        <div>{{ option.location_name }}</div>
                                    </template>

                                </b-form-select>
                            </div>

                        </div>


                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
<!--                                <b-link class="" :to="{ path: '/productform',query: { type: 'add'} }">-->
<!--                                    <b-button class="btn-info " > Add New Item </b-button>-->
<!--                                </b-link>-->
                            </div>
                            <div class="col-md-6">
                                <h1 class="text-light text-center">Items</h1>
                            </div>
                        </div>

                        <table class="table   table-responsive-sm">
                            <thead  class="thead-dark table-bordered " >
                            <tr class="">
                                <th scope="col">Code </th>
                                <th scope="col">Description</th>
                                <th scope="col" >Qty</th>

                            </tr>
                            </thead>
                            <tbody class="" >
                            <datalist id="item-list-id" >
                                <option v-for="item in items" :key="item.mapid" :value="item.itemCode"> {{ item.itemName }}  | {{item.itemDescription}}  </option>

                            </datalist>
                            <template v-if="inbound_searched_items">
                                <tr class="text-light"  v-for="item in inbound_searched_items" :key="item.mapid">
                                    <th class="col-md-3 ">
                                        <div class="input-container w-100">
                                            <i  class="bi bi-x-circle custom-icon cursor-pointer" @click="removeitem(item.mapid)" style="margin-right: 0.5rem;"></i>
                                            <input class="bg-light col-md-11 form-control" v-model="item.itemCode" :disabled="edit" list="item-list-id" @change="addsearchitem('',item.itemCode, item.mapid)"  >

                                        </div>
                                    </th>

                                    <td><b-input class=" bg-light form-control" v-model="item.itemDescription" :disabled="true"> </b-input></td>
                                    <td class="col-md-2 "><input type="number" v-model="item.qty" @change="handlechange(item.mapid)"  class="bg-light form-control" ></td>



                                </tr>
                            </template>
                            <tr>
                                <th scope="row" >

                                    <i class="fa-solid fa-plus fa-2xl  cursor-pointer" style="color: #08e234; " v-if="showplus" @click="addsearchitem('yes')"></i>
                                </th>
                                <td></td>
                                <td></td>




                            </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6 offset-3">
                        <button  type="button" class="btn custom-btn2 w-100 btn-lg text-light   mt-5 mtmb" :disabled="disablesavestock" @click="save_stock"> <span v-if="edit">Update & Receive</span>  <span v-else="">Receive</span> </button>
                    </div>
                </div>





            </div>


        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
//import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";
import newitem from "@/constants/newitem";
import Swal from "sweetalert2";
import apiService from "@/service/apiService";
import axios from "axios";
import URL from "@/constants/baseurl";
import {cloneDeep} from "lodash";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
//import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
//import axios from "axios";
//import URL from "@/constants/baseurl";
//import Swal from "sweetalert2";
//import apiService from "@/service/apiService";


export default {
    name: "reports",

    components:{Welcomenote, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['allinvoices','branches']),
        ...mapState('invoice', ['users']),
        ...mapState('items',['items','inbound_searched_items']),

        disablesavestock(){
            if(this.location_id=='' || this.date==''  || (this.inbound_searched_items.filter((is)=>is.id!=0)).length==0){
              return true
            } else return false
        },


        showitemdatalist(){
            if(this.inbound_searched_items.length==0){
                // return false uncomment and return false on demand
                return true
            }
            else {
                return true
            }
        },

        showplus(){
            let newitems = this.inbound_searched_items.filter((item)=>item.itemCode=='')
            if(newitems.length==0 && ! this.edit){
                return true
            }
            else {
                return false
            }
        },



        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {
            viewType:'add',
            dispatch_id:'',
            edit:false,
            selected_history:{},
            modalShow:false,
            history:[],
            roles: [],
            user: {},
            user_id:'',
            branch_id:'',
            searchitem:'',
            locations:[],
            location_id:'',
            por:'',
            date:new Date()

        };
    },



    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
      //   await this.getbranches()
        // await this.getcashiers()
        this.reset_inbound_searched_items()
        await this.reloadcb()
         await  this.handleenter()


    },
    async mounted() {


        await this.getlocations()

    },

    watch: {
        // inbound_searched_items: {
        //     handler(newValue) {
        //         // Note: `newValue` will be equal to `oldValue` here
        //         // on nested mutations as long as the object itself
        //         // hasn't been replaced.
        //     //    console.log(newValue,'by watch',oldValue)
        //       let targetobj=  newValue.find((n)=>Number(n.qty)<1)
        //         if (targetobj){
        //           //  console.log(targetobj.qty,'target qty')
        //             if(targetobj.id!=0 && targetobj.qty!=''){
        //                 targetobj.qty=1
        //             }
        //
        //
        //         }
        //
        //     },
        //     deep: true
        // }
    },

    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getAllInvoices','getbranches','getcashiers','getusers']),
        ...mapState('items', ['items']),
        ...mapActions('items', ['getItems']),
        ...mapActions('items', ['removeinboundSearchedItem']),
        ...mapActions('items',['setforedit_inbound_searched_items','add_inbound_searched_items','reset_inbound_searched_items']),

       async handleenter(){
            this.viewType = this.$route.query.type;
            this.dispatch_id = this.$route.query.id;
            if (this.viewType=='add'){
                this.reset_inbound_searched_items()
                this.addsearchitem()
            }
            if(this.viewType=='edit'){

                this.edit=true
                const res = await apiService.get(`/getinbound/${this.dispatch_id}`);
                if(res){
                 let dispatch=res.data

                    this.location_id=dispatch.location_id
                    this.date=dispatch.date

                    this.por=dispatch.purchase_order_ref
                    this.setforedit_inbound_searched_items(dispatch.dispatchitems)
                }
            }
        },
        handlechange(mapid) {
            //  alert(mapid);
            let item = this.inbound_searched_items.find((item) => item.mapid == mapid);
         //   console.log(item,'item==================================')
            let box_qty = Number(item.box_qty);
            let qty = Number(item.qty);
            if(qty==0){
                if(item.id!=0 && item.qty!=''){
                    item.qty=Number(item.box_qty).toFixed(2)
                }
            }
            let validqty = qty / box_qty
            // alert(validqty)
            if (box_qty) {
                if (validqty < 1) {
                    item.qty = Number(item.box_qty).toFixed(2)
                }
                    // else if (qty % box_qty == 0) {
                    //     //
                // }
                else {
                  //  console.log(item.qty/item.box_qty,'========')
                    item.qty = Number((Math.ceil(Number(qty / box_qty).toFixed(2))) * box_qty).toFixed(2)
                }
            }
            (this.inbound_searched_items.find((item)=>item.qty=='')).qty=1

        },
        showmodaldata(id){
            this.selected_history=this.history.find((hs)=>hs.id==id)
            this.modalShow = ! this.modalShow
        },
        handleempty(){
            (this.inbound_searched_items.find((item)=>item.qty=='')).qty=1
        },


        async save_stock(){
            let ledger_entry={
                dispatch_id:this.dispatch_id,
                items:this.inbound_searched_items,
                location_id:this.location_id,
                date:moment(this.date).format('YYYY-MM-DD HH:mm:ss'),
                por:this.por,
                user_id:this.authenticatedUser.id


            }
         //   console.log(ledger_entry)

            const apiToken = localStorage.getItem('token');
            let apiurl=`${URL.BASEURL}/api/add-stock`
            if(this.edit){
                 apiurl=`${URL.BASEURL}/api/update-inbound`
            }
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${apiurl}`, ledger_entry);

                if (res) {

                    if(this.edit){
                        await this.approve_inbound(this.dispatch_id)
                        Swal.fire('Success', 'In Bound Updated and Received', 'success');
                    }
                    else {
                        Swal.fire('Success', 'Stock Received', 'success');
                    }

                    // console.log(res.data)
                    this.reset_inbound_searched_items()
                    this.addsearchitem()
                    this.edit=false
                     this.location_id='';
                      this.date=new Date();
                      this.por='';
                      this.note='';

                    this.$router.back();
                }
            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to Add Stock.', 'info');
            }
        },

        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, YYYY');
          //  let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },
        async getlocations(){
            const {data} = await apiService.get(`/locations`);
            //  console.log('branches', data)
            this.locations=data


        },
        addsearchitem(dummy='', itemcode=null,mapid=null){

            // alert(this.searchitem)
            //console.log((this.searched_items).length)
            if(dummy=='yes' || (this.inbound_searched_items).length==0) {

                let mapid=Math.random()
                let item=cloneDeep(newitem)
                let datawithmapid = { ...item, mapid: mapid,qty:item.box_qty };
                this.add_inbound_searched_items(datawithmapid)
            }
            else if(itemcode) {
                const   item=this.items.find((i) => i.itemCode==itemcode)

                if(item){
                    //  console.log(item[0])
                    let mapid=Math.random()
                    let datawithmapid = { ...item, mapid: mapid,qty:Number(item.box_qty).toFixed(2) };
                    this.add_inbound_searched_items(datawithmapid)


                    const   dummyitem=(this.inbound_searched_items).find((i) => i.id==0)
                    console.log(dummyitem)
                    this.removeinboundSearchedItem(dummyitem.mapid)


                }
                else {
                    Swal.fire('Item Not Found', 'No item available for this code', 'info');
                    //  alert('not found')
                    this.removeitem(mapid)
                }
            }
            //  console.log(this.searched_items)

        },

        removeitem(id){
            //  await  Swal.fire('Removing ', 'You are removing item from invoice', 'info');
            this.removeinboundSearchedItem(id)

        },


        formattedDate(date) {

            const formattedDate = moment(date).format('DD-MM-YYYY');

            return formattedDate;
        },


        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.items.length==0){
                //   alert(this.branches.length)
                await this.getItems();

            }
            if(this.users.length==0){
                await this.getusers()
                ///console.log(this.users)
            }
        },

        async approve_inbound(dispatch_id){

            const swalResult = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to do it?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            });

            if (swalResult.isConfirmed) {
                try {
                    const { data } = await apiService.get(`/receive-inbound-stock/${dispatch_id}`);
                    console.log(data.status);
                    await this.gethistory('inbound')

                } catch (error) {
                    // Handle errors, you might want to show a Swal alert for errors too
                    console.error('Error in approving:', error);
                    Swal.fire('Error!', 'An error occurred while processing the request.', 'error');
                }
            }
        },

        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.gray{
    background-color: gray !important;
}
.input-container {
    display: inline-flex; /* Use inline-flex to place the elements in the same line */
    align-items: center; /* To vertically center the elements */
}

.hr0{
    margin-top: 5px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid green;
    max-width: 100%;

}



</style>
