<template>
    <div class="container-fluid" id="printMe">
       <div class="row">
           <div class="col-10 offset-1 ">
               <div class="row mt-4">

                   <h1 class="col-7 text-success" style="font-size: 70px; ">
                       <span> Cash Collection </span>

                   </h1>
                   <div class="col-5">
                       <img class="col-12 w-100 " src="@/assets/images/rtlogo.png" @load="print"  >
                   </div>
                   <h1 class="col-12 "> <hr class="hr0"></h1>
               </div>
               <div class="row mt-3">
                 <div class="col-6">
                     <table class="table table-bordered">

                         <tbody>
                         <tr>

                             <td> <strong>ID</strong> </td>
                             <td> <strong> {{selected_collection.id}} </strong></td>
                         </tr>

                         <tr>
                             <td><strong>Status</strong></td>
                             <td><strong>{{selected_collection.status}}</strong></td>

                         </tr>
                         <tr>
                             <td><strong>Amount</strong></td>
                             <td><strong>£ {{selected_collection.amount}}</strong></td>

                         </tr>
                         <tr >
                             <td><strong>Date</strong></td>
                             <td><strong>{{formattedDate(selected_collection.date)}}</strong></td>

                         </tr>
                         <tr >
                             <td><strong>Branch</strong></td>
                             <td><strong>{{branches.find((b)=>b.id==selected_collection.branch_id).name}}</strong></td>

                         </tr>
                         <tr >
                             <td><strong>Collected On</strong></td>
                             <td><strong>{{formattedDate(selected_collection.collected_date)}}</strong></td>

                         </tr>


                         </tbody>
                     </table>
                 </div>
                   <div class="col-6">
                       <div class="row mt-3">
                           <div class="col-2">
                               <label>Notes:</label>
                           </div>
                           <div class="col-10">
                               <b-form-textarea
                                   id="textarea"
                                   v-model="selected_collection.notes"

                                   :state="null"
                                   :disabled="true"
                                   rows="6"

                               ></b-form-textarea>
                           </div>
                       </div>

                       <div class="row mt-5">
                           <div class="col-2">
                               <label> <strong>Envelope: </strong></label>
                           </div>
                           <div class="col-10">
                               <b-input  v-model="selected_collection.envelope_id"></b-input>
                           </div>
                       </div>
                   </div>


               </div>






               <div class="row mt-3">
                   <div class="col-4">
                       <p>Given By</p>
                       <div class="dropdown  ">

                           <b-form-select
                               v-model="selected_collection.given_by"
                               :options="cashiers"

                               value-field="id"
                               text-field="fullName"
                           >

                               <template #first>
                                   <b-form-select-option value="">Select One</b-form-select-option>
                               </template>
                               <template #option="{ option }">
                                   <div>{{ option.fullName }}</div>
                               </template>

                           </b-form-select>
                       </div>

                   </div>
                   <div class="col-4 offset-4">

                       <p>Collected By</p>
                       <div class="dropdown  ">

                           <b-form-select
                               v-model="selected_collection.collected_by"
                               :options="cashiers"

                               value-field="id"
                               text-field="fullName"
                           >

                               <template #first>
                                   <b-form-select-option value="">Select One</b-form-select-option>
                               </template>
                               <template #option="{ option }">
                                   <div>{{ option.fullName }}</div>
                               </template>

                           </b-form-select>
                       </div>
                   </div>
               </div>
               <h1 class="col-12 "> <hr class="hr0"></h1>
           </div>

       </div>


    </div>
</template>

<script>


import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
//import $ from "jquery";
import moment from "moment";




export default {
    name: "delivery-note",
    props: ['mode'],
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['invoice','cashiers','branches']),
        ...mapState('collections', ['selected_collection']),


        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

        coll_id(){
            return this.selected_collection.id
        },
        Given_by(){
            return  this.cashiers.find((c)=>c.id==this.selected_collection.given_by)
        },
        Collected_by(){
            return  this.cashiers.find((c)=>c.id==this.selected_collection.collected_by)
        },



    },
    data() {
        return {


        };
    },
    created() {

      //  this.print()
//


    },
    mounted() {

    //    this.print()

    },

    methods: {
        print () {
          //  console.log(this.$route.query.selected_collection,'here')
              //  this.selected_collection=this.$route.query.selected_collection
         //   console.log(this.selected_collection.id,'here')
            if (this.mode!='send') {
                window.print()
                // window.history.back();
                this.$router.push({ path: '/cash-collections' })
            }


        },
        formattedDate(date) {

            const formattedDate = moment(date).format('DD-MM-YYYY');

            return formattedDate;
        },


    },
}
</script>

<style scoped>



#printMe{
    background-color: white !important;
}
.table-bordered {
    outline: 1px solid black;

}
tbody tr {
    outline: 1px solid black;
    border: 0px solid black;
}
.table {
    border: 0px solid black !important;
}
.hr1{
    margin-top: -5px;
    margin-bottom: 0px;
    margin-right: 6%;
    border: 0;
    border-top: 3px solid #4f4e4e;
    max-width: 100%;

}
.hr0{
    margin-top: 15px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid green;
    max-width: 100%;

}

.hr2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 2%;
    border: 0;
    border-top: 3px solid #4f4e4e;
    max-width: 100%;

}
.wrap {
    word-wrap: break-word; /* Wrap long words within the available space */
}

</style>
