<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
            <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white">

                <div class="row">
                    <div class="col-md-10 offset-1">
                        <h1 class="text-light text-center">Collections</h1>
                        <table  class="table text-light ">
                            <thead >
                            <th >ID</th>
                            <th>Date</th>
                            <th class="col-md-4">Branch</th>
                            <th class="col-md-2">Amount</th>
                            <th>Status</th>
                            <th class="col-md-3 text-center" v-if="authenticatedUser.permissions.includes('super')">Action</th>
                            </thead>

                            <tbody >
                            <tr class="" v-for="collection in all_collections " :key="collection.id">
                                <td> <a href="#" @click.prevent="showmodal(collection.id)">{{collection.id}}</a> </td>
                                <td>{{ formatDate(collection.date)}}</td>
                                <td>{{(branches.find((b)=>b.id==collection.branch_id)).name}}</td>
                                <td>{{formatNumberWithCommas(Number(collection.amount).toFixed(2))}}</td>
<!--                                <td><b-input v-model="collection.amount" type="number" step="any" :disabled="collection.status=='Approved'"></b-input></td>-->
                                <td>{{collection.status}}</td>
                                <td class="text-center" v-if="authenticatedUser.permissions.includes('super')">
                                    <button class="btn btn-info mx-2" @click="update_collections('Approved', collection.id)" v-if="collection.status=='Collected'">Approve</button>
                                    <button class="btn btn-warning" @click="update_collections('Cancelled', collection.id)" v-if="!(collection.status=='Approved' || collection.status=='Cancelled' || collection.status=='Collected')">Cancel</button></td>
                            </tr>



                            </tbody>

<!--                            <tbody >-->
<!--                            <tr class="">-->
<!--                                <td> <a href="#" @click.prevent="collectionmodalShow = !collectionmodalShow">1</a> </td>-->
<!--                                <td>{{ formatDate(collections.branch_1.date)}}</td>-->
<!--                                <td>Bounds Green</td>-->
<!--                                <td>{{formatNumberWithCommas(Number(collections.branch_1.total_collection).toFixed(2))}}</td>-->
<!--                                <td class="text-center"><button class="btn btn-info">Approve</button> <button class="btn btn-warning">Cancel</button></td>-->
<!--                            </tr>-->
<!--                            <tr class="">-->
<!--                                <td> <a href="#" @click.prevent="collectionmodalShow = !collectionmodalShow">2</a> </td>-->
<!--                                <td>{{ formatDate(collections.branch_2.date)}}</td>-->
<!--                                <td>Mega Store Isleworth</td>-->
<!--                                <td>{{formatNumberWithCommas(Number(collections.branch_2.total_collection).toFixed(2))}}</td>-->
<!--                                <td class="text-center"><button class="btn btn-info">Approve</button> <button class="btn btn-warning">Cancel</button></td>-->
<!--                            </tr>-->
<!--                            <tr class="">-->
<!--                                <td> <a href="#" @click.prevent="collectionmodalShow = !collectionmodalShow">3</a> </td>-->
<!--                                <td>{{ formatDate(collections.branch_3.date)}}</td>-->
<!--                                <td>Farnborough Showroom</td>-->
<!--                                <td>{{formatNumberWithCommas(Number(collections.branch_3.total_collection).toFixed(2))}}</td>-->
<!--                                <td class="text-center"><button class="btn btn-info">Approve</button> <button class="btn btn-warning">Cancel</button></td>-->
<!--                            </tr>-->
<!--                            <tr class="">-->
<!--                                <td> <a href="#" @click.prevent="collectionmodalShow = !collectionmodalShow">4</a> </td>-->
<!--                                <td>{{ formatDate(collections.branch_4.date)}}</td>-->
<!--                                <td>Hanworth Superstore</td>-->
<!--                                <td>{{formatNumberWithCommas(Number(collections.branch_4.total_collection).toFixed(2))}}</td>-->
<!--                                <td class="text-center"><button class="btn btn-info">Approve</button> <button class="btn btn-warning">Cancel</button></td>-->
<!--                            </tr>-->
<!--                            <tr class="">-->
<!--                                <td> <a href="#" @click.prevent="collectionmodalShow = !collectionmodalShow">5</a> </td>-->
<!--                                <td>{{ formatDate(collections.branch_5.date)}}</td>-->
<!--                                <td>Crawley Showroom</td>-->
<!--                                <td>{{formatNumberWithCommas(Number(collections.branch_5.total_collection).toFixed(2))}}</td>-->
<!--                                <td class="text-center"><button class="btn btn-info">Approve</button> <button class="btn btn-warning">Cancel</button></td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
                        </table>
<!--                        <div class="row" v-if="showloader=='yes'">-->
<!--                            <div class="col-md-12 text-center">-->
<!--                                <loader></loader>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>


        </div>
        <b-modal  v-model="collectionmodalShow"  centered  >
            <div class="row mt-2">
              <div class="col-md-6">
                 ID: <strong> {{selected_collection.id}} </strong>
              </div>
                <div class="col-md-6">
               Status:  <strong> {{selected_collection.status}} </strong>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-6">
                    Amount:  <strong> £ {{selected_collection.amount}} </strong>
                </div>
                <div class="col-md-6">
                  Date: <strong> {{formattedDate(selected_collection.date)}} </strong>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-6">
                   Branch: <strong v-if="selected_collection.branch_id"> {{(branches.find((b)=>b.id==selected_collection.branch_id)).name}} </strong>
                </div>
                <div class="col-md-6">
                    Collected On:   <b-form-datepicker

                    :reset-button=true
                    reset-button-variant="btn btn-warning"
                    id="datepicsuper"

                    v-model="selected_collection.collected_date"
                    placeholder="Date"

                    dropup  calendar-width="100%">

                </b-form-datepicker>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-3">
                    <label>Notes:</label>
                </div>
                <div class="col-md-9">
                    <b-form-textarea
                        id="textarea"
                        v-model="selected_collection.notes"
                        placeholder="Enter notes ..."
                        :state="null"
                        :disabled="false"
                        rows="2"

                        max-rows="4"
                    ></b-form-textarea>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-3">
                    <label>Envelope:</label>
                </div>
                <div class="col-md-9">
                   <b-input placeholder="Envelope ID" v-model="selected_collection.envelope_id"></b-input>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-4">
                    <p>Given By</p>
                    <div class="dropdown  ">

                        <b-form-select
                            v-model="selected_collection.given_by"
                            :options="cashiers"

                            value-field="id"
                            text-field="fullName"
                        >

                            <template #first>
                                <b-form-select-option value="">Select One</b-form-select-option>
                            </template>
                            <template #option="{ option }">
                                <div>{{ option.fullName }}</div>
                            </template>

                        </b-form-select>
                    </div>

                </div>
                <div class="col-md-4 offset-4">

                    <p>Collected By</p>
                    <div class="dropdown  ">

                        <b-form-select
                            v-model="selected_collection.collected_by"
                            :options="cashiers"

                            value-field="id"
                            text-field="fullName"
                        >

                            <template #first>
                                <b-form-select-option value="">Select One</b-form-select-option>
                            </template>
                            <template #option="{ option }">
                                <div>{{ option.fullName }}</div>
                            </template>

                        </b-form-select>
                    </div>
                </div>
            </div>

            <div class="row mt-4 justify-content-center"  >
                <div class="col-md">

                    <b-link class="" :to="{ path: `/print-collection` }">
                        <button class="btn btn-info" :disabled="selected_collection.status=='Draft' || selected_collection.status=='Ready'">Print</button>
                    </b-link>
                </div>
                <div class="col-md">
                    <button class="btn btn-warning " @click="update_collections('Ready')" :disabled="!(selected_collection.status=='Draft' && selected_collection.envelope_id!=null && selected_collection.envelope_id!='' &&  Number(selected_collection.amount) > 0)">Ready</button>
                </div>
                <div class="col-md">
                    <button class="btn btn-warning"  :disabled="discollected || ! showcollectedbtn" @click="update_collections('Collected')">Collected</button>
                </div>

                <div class="col-md" v-if="authenticatedUser.permissions.includes('super')">
                    <button class="btn btn-success "  @click="update_collections('Approved',selected_collection.id)" :disabled="!(selected_collection.status=='Collected')">Approve</button>
                </div>
                <div class="col-md" v-if="authenticatedUser.permissions.includes('super')">
                    <button class="btn btn-danger" @click="update_collections('Cancelled',selected_collection.id)" v-if="!(selected_collection.status=='Approved' || selected_collection.status=='Cancelled' || selected_collection.status=='Collected')">Cancel</button>
                </div>

            </div>

        </b-modal>
    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
//import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";
//import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
import axios from "axios";
import URL from "@/constants/baseurl";
import Swal from "sweetalert2";
import apiService from "@/service/apiService";
import Welcomenote from "@/pages/admin/mypos/welcomenote";


export default {
    name: "reports",

    components:{Welcomenote, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['allinvoices','cashiers','branches']),
        ...mapState('invoice', ['branches']),


       disableready(){
            if(this.selected_collection.given_by == null || this.selected_collection.given_by == '' || this.selected_collection.collected_by == null || this.selected_collection.collected_by == ''){
              return true
            }
            else {
                return false
            }
       },

        showcollectedbtn(){
            if(this.selected_collection.given_by == null || this.selected_collection.given_by == '' || this.selected_collection.collected_by == null  || this.selected_collection.collected_by == '' || this.selected_collection.collected_date == null  || this.selected_collection.collected_date == ''){
                return false
            }
            else {
                if(this.selected_collection.status=='Ready'){
                    return true
                }
                else {
                    return false
                }

            }
        },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {

            roles: [],
            user: {},
            dateFrom: '',
            dateTo: '',
            selectedRange: '',

            cashier_id:'',

            branch_id:'',

            reports:null,
            showloader:'',
            collectionmodalShow:false,
            collections:{},
            all_collections:[],
            selected_collection:{},
            discollected:false

        };
    },



    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()
        await this.reloadcb()


    },
    async mounted() {

        await this.get_amount_for_collections();
        await this.get_collection();

    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getAllInvoices','getbranches','getcashiers']),
        ...mapActions('collections', ['add_selected_collection']),



        formattedDate(date) {

            const formattedDate = moment(date).format('DD-MM-YYYY');

            return formattedDate;
        },
        showmodal(id){
        this.selected_collection=this.all_collections.find((ac)=>ac.id==id)
         this.add_selected_collection(this.selected_collection)
            this.collectionmodalShow = ! this.collectionmodalShow
        },

        async get_collection(){
            let branch_id;
            if(this.authenticatedUser.permissions.includes('super')){
                //    cbranch_id=this.invoice.branch_id
                branch_id='super'
            }
            else {
                branch_id=this.authenticatedUser.branch.id

            }
            const {data} = await apiService.get(`/get_collections/${branch_id}`);
            this.all_collections=data
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().split('T')[0];
            let collections=(this.all_collections).map((c)=>{
             if(c.date==formattedDate){
                 if(c.status=='Draft' & c.branch_id==1){
                     c.amount= (this.collections.branch_1.cash).toFixed(2)
                 }
                 if(c.status=='Draft' & c.branch_id==2){
                     c.amount= (this.collections.branch_2.cash).toFixed(2)
                 }
                 if(c.status=='Draft' & c.branch_id==3){
                     c.amount= (this.collections.branch_3.cash).toFixed(2)
                 }
                 if(c.status=='Draft' & c.branch_id==4){
                     c.amount= (this.collections.branch_4.cash).toFixed(2)
                 }
                 if(c.status=='Draft' & c.branch_id==5){
                     c.amount= (this.collections.branch_5.cash).toFixed(2)
                 }
             }
                return c
            })
            this.all_collections=collections
          //  console.log('all_collection', data)

        },

        async  update_collections(status,id=null){

            if((status=='Approved' || status=='Cancelled') && id!=null){
                this.selected_collection=this.all_collections.find((ac)=>ac.id==id)
            }

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                this.discollected=true
                this.selected_collection.status=status
                const res = await axios.post(`${URL.BASEURL}/api/update_collection`, this.selected_collection);

                if (res) {
                    this.discollected=false
                  //  console.log(res.data, 'updated collection')
                    Swal.fire('Success', 'Collection Updated successfully!', 'success');

                }
                else {
                    //
                }



            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to get collections amount', 'info');
            }
        },

        async  get_amount_for_collections(){


            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.get(`${URL.BASEURL}/api/collections`);

                if (res) {
                 //   console.log(res.data, 'collections')

                  this.collections=res.data
                }
                else {
                    //
                }



            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to get collections amount', 'info');
            }
        },

        formatNumberWithCommas(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        async  getadvancereport(){
            this.showloader='yes'
            this.reports=[]
            let reportparams={
                dateFrom:this.dateFrom,
                dateTo:this.dateTo,
                selectedRange:this.selectedRange,
                branch_ids:this.branch_id,
                cashier_id:this.cashier_id

            }
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/api/advance-report`, reportparams);

                if (res) {
                    //console.log(res.data)
                    this.showloader='no'
                    this.reports=res.data
                  //  console.log( this.reports, 'advance')
                }
                else {
                    //
                }



            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to get report', 'info');
            }
        },
        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.cashiers.length==0){
                await this.getcashiers()
            }
        },
        GiveSum(items){
            if(items.length){
                return (items.reduce((total, item) => total + Number(item.payment_amount), 0)).toFixed(2);
            }
            else{
                return 0
            }

        },
        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMM, YYYY');

            return date2;
        },

        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.gray{
    background-color: gray !important;
}



</style>
