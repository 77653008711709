

export const invoice = {
    amount: '',
    due_date: '',
    total: '',
    balance: '',
    status: ''
}

export const invoiceOptions = ['overdue', 'paid', 'due', 'undue']

export const user = {
    first_name: '',
    last_name: '',
    user_name: '',
    email: '',
    branch_id: '',
    // address: '',
    role: '',
    // department_id: '',
    // departments: [],
    // customer_id_number:'',
    // customer_number:'',
    // payer_number:'',
}

export const device = {
    device_id: '',
    device_name: '',
    shift_plan_id: '',
    employee_name: '',
    status:''

}
export const roles = {
    ADMIN: 'admin',
    CUSTOMER: 'customer',
    ACCOUNTANT: 'accountant'
}
export const parameters = {
    sunday: [
        {slug: "sunday_slot_1_start", title: "Slot 1 Start",type:"time", value: "05:36"},
        {slug: "sunday_slot_1_end",   title: "Slot 1 End",type:"time", value: "05:36"},
        {slug: "sunday_slot_2_start", title: "Slot 2 Start",type:"time", value: "05:36"},
        {slug: "sunday_slot_2_end",   title: "Slot 2 End",type:"time", value: "05:36"},
        {slug: "sunday_slot_3_start", title: "Slot 3 Start",type:"time", value: "05:36" },
        {slug: "sunday_slot_3_end",   title: "Slot 3 End",type:"time", value: "05:36", },
        {slug: "sunday_slot_4_start", title: "Slot 4 Start",type:"time", value: "05:36"},
        {slug: "sunday_slot_4_end",   title: "Slot 4 End.",type:"time", value: "05:36"},
        {slug: "sunday_slot_5_start", title: "Slot 5 Start",type:"time", value: "05:36"},
        {slug: "sunday_slot_5_end",   title: "Slot 5 End",type:"time", value: "05:36"},
    ],
    monday: [
        {slug: "monday_slot_1_start", title: "Slot 1 Start",type:"time", value: "05:36"},
        {slug: "monday_slot_1_end",   title: "Slot 1 End",type:"time", value: "05:36"},
        {slug: "monday_slot_2_start", title: "Slot 2 Start",type:"time", value: "05:36"},
        {slug: "monday_slot_2_end",   title: "Slot 2 End",type:"time", value: "05:36"},
        {slug: "monday_slot_3_start", title: "Slot 3 Start",type:"time", value: "05:36" },
        {slug: "monday_slot_3_end",   title: "Slot 3 End",type:"time", value: "05:36", },
        {slug: "monday_slot_4_start", title: "Slot 4 Start",type:"time", value: "05:36"},
        {slug: "monday_slot_4_end",   title: "Slot 4 End.",type:"time", value: "05:36"},
        {slug: "monday_slot_5_start", title: "Slot 5 Start",type:"time", value: "05:36"},
        {slug: "monday_slot_5_end",   title: "Slot 5 End",type:"time", value: "05:36"},
    ],
    tuesday: [
        {slug: "tuesday_slot_1_start", title: "Slot 1 Start",type:"time", value: "05:36"},
        {slug: "tuesday_slot_1_end",   title: "Slot 1 End",type:"time", value: "05:36"},
        {slug: "tuesday_slot_2_start", title: "Slot 2 Start",type:"time", value: "05:36"},
        {slug: "tuesday_slot_2_end",   title: "Slot 2 End",type:"time", value: "05:36"},
        {slug: "tuesday_slot_3_start", title: "Slot 3 Start",type:"time", value: "05:36" },
        {slug: "tuesday_slot_3_end",   title: "Slot 3 End",type:"time", value: "05:36", },
        {slug: "tuesday_slot_4_start", title: "Slot 4 Start",type:"time", value: "05:36"},
        {slug: "tuesday_slot_4_end",   title: "Slot 4 End.",type:"time", value: "05:36"},
        {slug: "tuesday_slot_5_start", title: "Slot 5 Start",type:"time", value: "05:36"},
        {slug: "tuesday_slot_5_end",   title: "Slot 5 End",type:"time", value: "05:36"},
    ],
    wednesday: [
        {slug: "wednesday_slot_1_start", title: "Slot 1 Start",type:"time", value: "05:36"},
        {slug: "wednesday_slot_1_end",   title: "Slot 1 End",type:"time", value: "05:36"},
        {slug: "wednesday_slot_2_start", title: "Slot 2 Start",type:"time", value: "05:36"},
        {slug: "wednesday_slot_2_end",   title: "Slot 2 End",type:"time", value: "05:36"},
        {slug: "wednesday_slot_3_start", title: "Slot 3 Start",type:"time", value: "05:36" },
        {slug: "wednesday_slot_3_end",   title: "Slot 3 End",type:"time", value: "05:36", },
        {slug: "wednesday_slot_4_start", title: "Slot 4 Start",type:"time", value: "05:36"},
        {slug: "wednesday_slot_4_end",   title: "Slot 4 End.",type:"time", value: "05:36"},
        {slug: "wednesday_slot_5_start", title: "Slot 5 Start",type:"time", value: "05:36"},
        {slug: "wednesday_slot_5_end",   title: "Slot 5 End",type:"time", value: "05:36"},
    ],
    thursday: [
        {slug: "thursday_slot_1_start", title: "Slot 1 Start",type:"time", value: "05:36"},
        {slug: "thursday_slot_1_end",   title: "Slot 1 End",type:"time", value: "05:36"},
        {slug: "thursday_slot_2_start", title: "Slot 2 Start",type:"time", value: "05:36"},
        {slug: "thursday_slot_2_end",   title: "Slot 2 End",type:"time", value: "05:36"},
        {slug: "thursday_slot_3_start", title: "Slot 3 Start",type:"time", value: "05:36" },
        {slug: "thursday_slot_3_end",   title: "Slot 3 End",type:"time", value: "05:36", },
        {slug: "thursday_slot_4_start", title: "Slot 4 Start",type:"time", value: "05:36"},
        {slug: "thursday_slot_4_end",   title: "Slot 4 End.",type:"time", value: "05:36"},
        {slug: "thursday_slot_5_start", title: "Slot 5 Start",type:"time", value: "05:36"},
        {slug: "thursday_slot_5_end",   title: "Slot 5 End",type:"time", value: "05:36"},
    ],
    friday: [
        {slug: "friday_slot_1_start", title: "Slot 1 Start",type:"time", value: "05:36"},
        {slug: "friday_slot_1_end",   title: "Slot 1 End",type:"time", value: "05:36"},
        {slug: "friday_slot_2_start", title: "Slot 2 Start",type:"time", value: "05:36"},
        {slug: "friday_slot_2_end",   title: "Slot 2 End",type:"time", value: "05:36"},
        {slug: "friday_slot_3_start", title: "Slot 3 Start",type:"time", value: "05:36" },
        {slug: "friday_slot_3_end",   title: "Slot 3 End",type:"time", value: "05:36", },
        {slug: "friday_slot_4_start", title: "Slot 4 Start",type:"time", value: "05:36"},
        {slug: "friday_slot_4_end",   title: "Slot 4 End.",type:"time", value: "05:36"},
        {slug: "friday_slot_5_start", title: "Slot 5 Start",type:"time", value: "05:36"},
        {slug: "friday_slot_5_end",   title: "Slot 5 End",type:"time", value: "05:36"},
    ],
    saturday: [
        {slug: "saturday_slot_1_start", title: "Slot 1 Start",type:"time", value: "05:36"},
        {slug: "saturday_slot_1_end",   title: "Slot 1 End",type:"time", value: "05:36"},
        {slug: "saturday_slot_2_start", title: "Slot 2 Start",type:"time", value: "05:36"},
        {slug: "saturday_slot_2_end",   title: "Slot 2 End",type:"time", value: "05:36"},
        {slug: "saturday_slot_3_start", title: "Slot 3 Start",type:"time", value: "05:36" },
        {slug: "saturday_slot_3_end",   title: "Slot 3 End",type:"time", value: "05:36", },
        {slug: "saturday_slot_4_start", title: "Slot 4 Start",type:"time", value: "05:36"},
        {slug: "saturday_slot_4_end",   title: "Slot 4 End.",type:"time", value: "05:36"},
        {slug: "saturday_slot_5_start", title: "Slot 5 Start",type:"time", value: "05:36"},
        {slug: "saturday_slot_5_end",   title: "Slot 5 End",type:"time", value: "05:36"},
    ],
}
