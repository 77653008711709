
import moment from "moment";


export default {
    namespaced: true,
    state: () => ({
        newtransaction:{
            id:'',
            invoice_id:'',
            cashier_id:'',
            picked_by:'',
            mode:'',
            date:moment().format('YYYY-MM-DD HH:mm:ss'),
            status:'Draft',
            dis_items:[],
            selected_dispatch:null
        },
        transactionshistory:[]

    }),
    mutations: {

        dispatch_picked(state,data){
            state.transactionshistory=data
        },

        setselecteddispacth(state,data){
            state.selected_dispatch=data
        },

        setdisitems(state,data){
            state.newtransaction.dis_items=data
        },
        resettransactionshistory(state){
            state.transactionshistory=[]
        },
        addtransaction(state,data){

            // Find the index of the object with the same ID, if it exists
            const exist = state.transactionshistory.find(item => item.id === data.id);
            if (exist) {
                // If an object with the same ID exists, remove it
                exist.status='Approved'
            }
            else {
                state.transactionshistory.push(data);
            }

        },

        removetransaction(state,id){

            state.transactionshistory= state.transactionshistory.filter((t)=>t.id!=id);
        },
        attachinvoicetransactions(state,data){
            state.transactionshistory=data
            state.newtransaction.id=''
            state.newtransaction.invoice_id=''
            state.newtransaction.cashier_id=''
            state.newtransaction.picked_by=''
            state.newtransaction.mode=''
        }

    },
    actions: {


         dispatchPicked({commit},data){
            commit('dispatch_picked', data)
        },

        add_selected_dispatch({commit},selecteddispatch){
            commit('setselecteddispacth', selecteddispatch)
        },
        add_dis_items({commit},items){
            commit('setdisitems', items)
        },
        reset_transactionshistory({commit}){
            commit('resettransactionshistory')
        },
        add_transaction({commit},transaction){
            commit('addtransaction', transaction)
        },

        remove_transaction({commit},transaction){
            commit('removetransaction', transaction)
        },

        attach_invoice_transactions({commit},transactions){
            commit('attachinvoicetransactions', transactions)
        }
    },


}
