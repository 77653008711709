<template>
    <div>
        <h3 class="text-3xl arabicDir">{{ ('Role') }}</h3>
        <form @submit="save" class="mt-md-3">
            <validation-observer ref="roleForm">
                <b-form-group :label=" ('Name')">
                    <text-validator v-model="role.name" name="name" rules="required"></text-validator>
                </b-form-group>
                <div class="text-right">
                    <button class="primary-btn green">{{ ('Save') }}</button>
                </div>
            </validation-observer>
        </form>
    </div>
</template>

<script>
import TextValidator from "@/components/Validators/TextValidator";
import {ValidationObserver} from "vee-validate";
import {successAlert} from "@/components/Alerts";

export default {
    name: "RoleForm",
    components: {TextValidator, ValidationObserver},
    data() {
        return {
            role: {
                name: ''
            }
        }
    },
    props: ['formType'],
    methods: {
        async save(e) {
            e.preventDefault()
            const valid = await this.$refs.roleForm.validate();
            if (valid) {
                let url, method;
                const data = this.role
                if (this.formType === 'add') {
                    url = `role`
                    method = 'post'
                } else {
                    url = `role/${data.id}`;
                    method = 'put'
                }
                await this.$axios[method](url, data);
                await successAlert('Role', 'created')
                this.$emit('close')
            }
        }
    }
}
</script>

<style scoped>

</style>
