import axios from "axios";
import {authHeader} from "../helpers/authHeader";
import router from "../router/router";
import URL from "@/constants/baseurl"

const apiService = axios.create({
    // baseURL: process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api' : 'http://lone-back.dev.techliance.com/api',
    // baseURL: process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api' : 'http://loneworkerbackend.airliquidesa.com/api',
  //  baseURL: 'http://postiles.dev.techliance.com/api',
 // baseURL: 'http://posapi.skymovers.co.uk/api',
 // baseURL: 'http://posapi.righttiles.co.uk/api',
   baseURL: `${URL.BASEURL}/api`,
    headers: authHeader()
});

apiService.interceptors.response.use(res => {
      return res
    },
    error => {
      if (error.response) {
          if (error.response.status === 401 ) {
              if (router.currentRoute.name !== 'login') {
                  localStorage.removeItem('token')
                  localStorage.removeItem('user')
                  router.push({name: "Login"}).then(() => {
                      apiService.post('logout');

                  })
              }
          }
          // if (error.response.status === 401) {
            //   if (router.currentRoute.name !== 'login') {
            //     localStorage.removeItem('token')
            //     router.push('/login').then(() => {
            //       apiService.defaults.headers = authHeader()
            //     })
            //   }
            // }

        if (error.response.status === 500) {
          try{
            localStorage.setItem('error_message', error.response.data.message);
          }catch (error){
            console.log(error);
          }
        }
      }

      return Promise.reject(error)
    }
)
export default apiService

