<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">
                <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                Welcome, {{authenticatedUser.branch.name}} Branch

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 mt-2 pb-2 bgsecond">

                <div class="row   text-light">

                    <div class="col-md-4">
                     <div class="row">
                         <div class="col-md-12 ">
                             <h1 class="ml-1 mt-1 ">
                                Trade Card Details </h1>
                             <div class="row">
                                 <div class="col-md-6">
                                     <label for="firstName" class="form-label">Trade Card No. </label>
                                     <b-form-input v-model="tradeCardNo" type="number" min="0" class="form-control" id="firstName" required :disabled="CardId?true:false" :state="card_av" > </b-form-input>
                                 </div>
                                 <div class="col-md-6">
                                     <label for="firstName" class="form-label">Find Customer </label>
                                     <b-form-input v-model="cardcustomername" type="text" class="form-control" id="firstName" required list="customer-list-id" autocomplete="off" @change="handleCustomerSelection" :disabled="card.assigned_customer?true:false || !card_av"> </b-form-input>
                                 </div>
                                 <datalist id="customer-list-id" >
                                     <option v-for="customer in cardcustomers" :key="customer.id" :value="customer.lastName+'-'+customer.id"> {{ customer.lastName }}  </option>
                                 </datalist>
                                 <div class="col-md-6 mt-3 " v-if="found=='yes'">
                                     <v-select
                                         v-model="foundcustomerId"

                                         class=" text-dark"
                                         placeholder="Select Customer"
                                         :options="cardcustomers"
                                         label="lastName"
                                         :reduce="option => option.id"

                                     />

                                 </div>



                             </div>
                             <div class="row mt-5">
                               <div class="col-md-6 offset-3">
                                   <button class="btn btn-success " @click="createCard" :disabled="card.assigned_customer?true:false || !card_av">
                                      <span v-if="CardId">
                                          Assign Trade Card
                                      </span>
                                       <span v-else="">
                                          Create Trade Card
                                      </span>
                                   </button>
                               </div>
                             </div>
                         </div>
                     </div>
                    </div>
                    <div class="col-md-8" v-if="customer_id!=null">
                        <b-form @submit.prevent="saveCustomer">
                            <div class="row ">
                               <div class="col-md-12">
                                   <h1 class=" mt-1 text-center">

                                       Customer Details </h1>
                               </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="firstName" class="form-label">Full Name</label>
                                    <b-form-input v-model="customer.lastName" type="text" class="form-control" id="firstName" required :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="title" class="form-label">Title</label>
                                    <b-form-input v-model="customer.title" type="text" class="form-control" id="title" :disabled="actiontype=='View'"> </b-form-input>
                                </div>

<!--                                <div class="col-md-3">-->
<!--                                    <label for="customerCode" class="form-label">Customer Code</label>-->
<!--                                    <b-form-input v-model="customer.customerCode" type="text" class="form-control" id="customerCode" :disabled="actiontype=='View'"> </b-form-input>-->
<!--                                </div>-->
                                <div class="col-md-3">
                                    <label for="mobile" class="form-label">Mobile</label>
                                    <b-form-input v-model="customer.mobile" type="text" class="form-control" id="mobile" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-3">
                                    <label for="companyName" class="form-label">Company Name</label>
                                    <b-form-input v-model="customer.companyName" type="text" class="form-control" id="companyName" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="customerType" class="form-label">Customer Type</label>
                                    <b-form-select
                                        v-model="customer.customerType"
                                        :options="[
                                             { value: 'Retail', text: 'Retail' },
                                             { value: 'Trade', text: 'Trade' },
                                        ]"



                                        class=""
                                        :disabled="actiontype=='View'"

                                    >



                                    </b-form-select>
                                </div>

                                <div class="col-md-3">
                                    <label for="cashier" class="form-label">Cashier</label>
                                    <b-form-select
                                        v-model="customer.cashier_id"
                                        :options="cashiers"


                                        class=""
                                        :disabled="actiontype=='View'"

                                        value-field="id"
                                        text-field="fullName"
                                    >
                                        <template #first>
                                            <b-form-select-option value="">Select Cashier</b-form-select-option>
                                        </template>
                                        <template #option="{ option }">
                                            <div>{{ option.fullName }}</div>
                                        </template>

                                    </b-form-select>
                                </div>
                                <div class="col-md-3">
                                    <label for="email" class="form-label">Email</label>
                                    <b-form-input v-model="customer.email" type="text" class="form-control" id="email" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-3">
                                    <label for="addressLine1" class="form-label">AddressLine 1</label>
                                    <b-form-input v-model="customer.addressLine1" type="text" class="form-control" id="addressLine1" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="addressLine1" class="form-label">AddressLine 2</label>
                                    <b-form-input v-model="customer.addressLine2" type="text" class="form-control" id="addressLine1" :disabled="actiontype=='View'" > </b-form-input>
                                </div>

                                <div class="col-md-3">
                                    <label for="postcode" class="form-label">Post Code</label>
                                    <b-form-input v-model="customer.postcode" type="text" class="form-control" id="postcode" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
<!--                                <div class="col-md-3">-->
<!--                                    <label for="postcode" class="form-label">Balance</label>-->
<!--                                    <b-form-input v-model="customer.walletBalance" type="text" class="form-control" id="balance" :disabled="actiontype=='View'"> </b-form-input>-->
<!--                                </div>-->
<!--                                <div class="col-md-3">-->
<!--                                    <label for="postcode" class="form-label">Customer Discount</label>-->
<!--                                    <b-form-input v-model="customer.cusDiscount" @keyup="max_dis(customer.cusDiscount)" @change="max_dis(customer.cusDiscount)" type="number" class="form-control" id="balance" :disabled="actiontype=='View'"> </b-form-input>-->
<!--                                </div>-->
                                <div class="col-md-3">
                                    <label for="postcode" class="form-label">Company Number (optional)</label>
                                    <b-form-input v-model="customer.companyNumber"   type="number" class="form-control"  :disabled="actiontype=='View'"> </b-form-input>
                                </div>
<!--                                <div class="col-md-3">-->
<!--                                    <label for="postcode" class="form-label">Contact Name</label>-->
<!--                                    <b-form-input v-model="customer.contactPerson"  type="text" class="form-control" :disabled="actiontype=='View'"> </b-form-input>-->
<!--                                </div>-->
                                <div class="col-md-3">
                                    <label for="addressLine1" class="form-label">Registered AddressLine 1</label>
                                    <b-form-input v-model="customer.raddressLine1" type="text" class="form-control" id="addressLine1" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="addressLine1" class="form-label"> Registered AddressLine 2</label>
                                    <b-form-input v-model="customer.raddressLine2" type="text" class="form-control" id="addressLine1" :disabled="actiontype=='View'" > </b-form-input>
                                </div>

                                <div class="col-md-3">
                                    <label for="postcode" class="form-label"> Registered Post Code</label>
                                    <b-form-input v-model="customer.rpostcode" type="text" class="form-control" id="postcode" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="status" class="form-label">Status</label>
                                    <b-form-checkbox  v-model="customer.status" name="check-button" switch class="mt-1" :disabled="actiontype=='View'">

                                    </b-form-checkbox>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <label for="textarea" class="form-label">Notes</label>
                                    <b-form-textarea
                                        id="textarea"
                                        v-model="customer.notes"
                                        placeholder=""
                                        rows="3"
                                        max-rows="6"
                                        :disabled="actiontype=='View'"
                                    ></b-form-textarea>
                                </div>
                            </div>

                            <button v-if="actiontype!='View'" type="submit" class="btn btn-primary mt-3"> {{actiontype}} Customer</button>
<!--                            <img  v-else class="mt-3 cursor-pointer" src="@/assets/images/downbaricons/newpos.png" @click="newpos"  >-->
                        </b-form>

                    </div>



                </div>



            </div>

<!--            <div class="col-md-11 offset-md-1 mt-2" v-if="customer.invoices">-->
<!--                <h1 class="text-light mt-2 text-capitalize">{{customer.lastName}} Orders</h1>-->

<!--                <b-table id="my-table"  class="bg-light" :items="filteredOrders" :fields="fields" striped hover responsive :per-page="perPage" :current-page="currentPage">-->

<!--                    <template #cell(customer)="data">-->
<!--                        {{data.item.customer.lastName}}-->
<!--                    </template>-->
<!--                    <template #cell(cashier)="data">-->
<!--                        {{data.item.cashier.fullName}}-->
<!--                    </template>-->
<!--                    &lt;!&ndash;                    <template #cell(actions)="data">&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <b-button class="btn-info" @click="loadorder(data.item)">Load Order </b-button>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </template>&ndash;&gt;-->

<!--                </b-table>-->
<!--            </div>-->

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import {mapActions, mapState} from "vuex";
import { mapGetters } from 'vuex';
//import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import axios from "axios";
import {cloneDeep} from "lodash";
import Swal from "sweetalert2";
import showrealtime from "@/components/showrealtime";
import URL from "@/constants/baseurl"
import newinvoice from "@/constants/newinvoice";
//import newcustomer from "@/constants/newcustomer";
import newpayment from "@/constants/newpayment";
import newitem from "@/constants/newitem";
import vSelect from "vue-select";




export default {
    name: "customerform",

    components:{
         'v-select': vSelect,
        'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['cashiers']),
        ...mapState('customers', ['customers']),

        // ...mapState('invoice', ['branches']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },
        revenue() {
            if(this.customer.invoices){
                let paidinv=cloneDeep(this.customer.invoices).filter(pi=>pi.status!='Quote')
                return paidinv.reduce(
                    (total, invoice) => total + Number(invoice.grandTotal),
                    0
                );
            }
            else return 0

        },
        filteredOrders() {
            let forders=''
            if (this.searchQuery) {
                //  alert(this.cashier_id)
                const query = this.searchQuery.toLowerCase();
                forders =this.customer.invoices.filter(order => {
                    //   const mob = order.customer.mobile ? order.customer.mobile.toLowerCase() : '';
                    //  const name = order.customer.lastName ? order.customer.lastName.toLowerCase() : '';
                    //   const adr1 = order.customer.addressLine1 ? order.customer.addressLine1.toLowerCase() : '';
                    //   const adr2 = order.customer.addressLine2 ? order.customer.addressLine2.toLowerCase() : '';
                    //   const pscode = order.customer.postcode ? order.customer.postcode.toLowerCase() : '';


                    let filteredItems=''
                    if(order.items){
                        filteredItems = order.items.filter(item =>
                            item.itemCode.toLowerCase().includes(query) ||
                            item.itemName.toLowerCase().includes(query) ||
                            item.pivot.itemDescription.toLowerCase().includes(query)
                        );

                    }
                    // order.invoice_ref.toLowerCase().includes(query) ||
                    // order.cashier.fullName.toLowerCase().includes(query) ||
                    //  order.customer.lastName.toLowerCase().includes(query) ||
                    return   filteredItems.length > 0
                    //   mob.toLowerCase().includes(query) ||
                    // adr1.toLowerCase().includes(query) ||
                    // adr2.includes(query) ||
                    // name.includes(query) ||
                    // pscode.includes(query)





                    //  order.status.toLowerCase().includes(query)
                    // order.issueDate.includes(query)
                })



            } else {
                forders=this.customer.invoices;

            }

            if(this.branch_id){
                forders=forders.filter(dfo=>dfo.branch_id===this.branch_id)
            }
            if(this.cashier_id){
                forders=forders.filter(dfo=>dfo.cashier_id===this.cashier_id)
            }
            if(this.order_status){
                forders=forders.filter(dfo=>dfo.status===this.order_status)
            }
            if(this.searchcustname){
                let searchcustname=this.searchcustname.toLowerCase();

                forders=forders.filter(dfo=> {
                    const name = dfo.customer.lastName ? dfo.customer.lastName.toLowerCase() : '';
                    return name.includes(searchcustname)
                })
            }
            if(this.searchcustphone){
                let searchcustphone=this.searchcustphone.toLowerCase();

                forders=forders.filter(dfo=> {
                    const mob = dfo.customer.mobile ? dfo.customer.mobile.toLowerCase() : '';
                    return mob.includes(searchcustphone)
                })
            }
            console.log(forders,'forders')
            return forders
        },
        actiontype(){
            if (this.$route.query.type=='add'){
                return 'Save'
            }
            else if (this.$route.query.type=='edit'){
                return 'Update'
            }
            else {
                return 'View'
            }
        }

    },
    data() {
        return {
            card_av:true,
            roles: [],
            user: {},
            perPage:10,
            currentPage: 1,
            card:'',
             tradeCardNo:'',
            found:'no',
            customer_id:null,
            CardId:null,
            cardcustomername:'',
            cardcustomers:[],
            customer: {
                id:"",
                branch_id: "",
                cashier_id: "",
                title: "",
                firstName: "",
                lastName: "",
                customerCode: "",
                mobile: "",
                companyName: "",

                customerType: "",
                discountType: 1,
                addressLine1: "",
                addressLine2: "",
                postcode: "",
                email: "",
                notes: "",
                status: true,
                walletBalance: "",
                custDiscount: "",
                companyNumber:""
            },



        };
    },

    async created() {
        await this.getUsers();
        await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()
        this.viewType = this.$route.query.type;
        this.CardId=this.$route.query.card_id;
        await this.gettradecard()
      //  this.customer_id = 4954;
        // console.log("View type:", this.viewType);
        // console.log("Customer ID:", this.customer_id);
        this.setCustomer()
        this.customer.status= this.customer.status==1 ? true:false


    },
    async mounted() {


    },
    watch: {
       async cardcustomername(newVal) {


          //  alert('cardcustomername changed. New value: ' + newVal);

            // You can also call your gettradecustomers method here if needed
            await this.gettradecustomers(newVal);
        },
        async  tradeCardNo(newVal) {
         if(newVal<0){
             this.tradeCardNo=0
         }
           await this.checkavailibilty()


        },
    },

    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['updateFullInvoice']),
        ...mapActions('payment', ['setNewpayment']),
        ...mapActions('items',['add_searched_items','attachinvoiceitems']),
        ...mapActions('payment', ['setNewpayment']),

        async createCard(){
            if(!this.tradeCardNo){
                Swal.fire('Error', 'Trade Card Is Required', 'error');
                return
            }
            if(!this.customer_id){
                Swal.fire('Error', 'Valid Customer Is Required', 'error');
                return
            }
            if(!this.customer.companyName){
                Swal.fire('Error', 'Company name Is Required', 'error');
                return
            }
            // if(!this.customer.contactPerson){
            //     Swal.fire('Error', 'Contact name Is Required', 'error');
            //     return
            // }
            if(!this.customer.raddressLine1){
                Swal.fire('Error', 'Registered Address line 1 Is Required', 'error');
                return
            }
            if(!this.customer.raddressLine2){
                Swal.fire('Error', 'Registered Address line 2 Is Required', 'error');
                return
            }
            if(!this.customer.rpostcode){
                Swal.fire('Error', 'Registered Post Code Is Required', 'error');
                return
            }

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
            try {
                let cardData={id:this.CardId,assigned_customer:this.customer_id, trade_card_no:this.tradeCardNo}
                const response = await axios.post(`${URL.BASEURL}/api/create-trade-card`,  cardData);
                 console.log(response)
                this.customer_id=null
                this.cardcustomers=[]
                this.tradeCardNo=''
                this.cardcustomername=''
              //  if(response.ok){
                    Swal.fire('Success', 'Trade Card is Created successfully!', 'success');
             //   }



            } catch (error) {
                console.error("Error getting customer:", error);
            }

        },

        async checkavailibilty(){

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
            if(!this.CardId) {

                try {
                    const response = await axios.post(`${URL.BASEURL}/api/card-exist`, {card_no: this.tradeCardNo});
                    let data = response.data

                    if (data == 'exist') {
                        this.card_av = false

                    } else {
                        this.card_av = true
                    }


                } catch (error) {
                    console.error("Error getting card:", error);
                }
            }

        },
       async gettradecustomers(name){
      //  alert(name)
         //  console.log(name)
           const apiToken = localStorage.getItem('token');
           if (apiToken) {
               // Set the Authorization header with the API token
               axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
           }
           try {
               const response = await axios.post(`${URL.BASEURL}/api/getforTrade-customers`,  {lastName:name});
               this.cardcustomers=response.data
               this.found='no'
            //   alert(this.found)
            //   console.log(this.cardcustomers,'===================')



           } catch (error) {
               console.error("Error getting customer:", error);
           }

        },
        async gettradecard(){

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
            if(this.CardId) {
                try {
                    const response = await axios.post(`${URL.BASEURL}/api/gettradecard`, {id: this.CardId});
                   let data = response.data
                  //  console.log(data)
                    this.customer=data.customer
                    this.tradeCardNo=data.trade_card_no
                    this.card=data
                    if(this.customer){
                        this.cardcustomername=data.customer.lastName
                        this.customer_id=data.customer.id
                    }



                } catch (error) {
                    console.error("Error getting card:", error);
                }
            }

        },
        handleCustomerSelection() {
            // Extract customer ID from the selected option
            const selectedCustomerId = this.cardcustomername.split('-')[1];

            // Check if a customer ID is found
            if (selectedCustomerId) {
               this.customer_id=selectedCustomerId
               this.customer= this.cardcustomers.find((customer)=>customer.id==selectedCustomerId)
               //alert(this.selectedCustomerId);

                // You can do further processing with the selectedCustomerId here
            }
        },
        max_dis(dis){
            let disc=Number(dis)

            if(disc>20){

                this.customer.cusDiscount=20
            }
            if(disc<0){
                this.customer.cusDiscount=0
            }

        },

        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },

        newpos(){
            let newinv=  cloneDeep(newinvoice)
            this.updateFullInvoice(newinv)

            this.attachinvoiceitems([])
            //  this.selectedCus('0123456789','yes');
            this.$store.commit('customers/setSelectedCustomer', cloneDeep(this.customer))

            //this.addsearchitem();
            this.add_searched_items(cloneDeep(newitem))

            this.setNewpayment(cloneDeep(newpayment))
            this.$router.push({ path: '/' })


        },

        async saveCustomer() {
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
            let customerdata=cloneDeep(this.customer)

            let cbranch_id;
            if(this.authenticatedUser.permissions.includes('super')){
                cbranch_id=this.customer.branch_id
            }
            else {
                cbranch_id=this.authenticatedUser.branch.id
            }

            customerdata.branch_id=cbranch_id
            try {
                const response = await axios.post(`${URL.BASEURL}/api/create-customer`,  customerdata);
                console.log("Customer saved:", response.data);
                await Swal.fire('Success', 'Customer added successfully!', 'success');
                this.$router.push({ path: '/customers' })

                // this.customer = {
                //     id:"",
                //     branch_id: "",
                //     cashier_id: "",
                //     title: "",
                //     firstName: "",
                //     lastName: "",
                //     customerCode: "",
                //     mobile: "",
                //     companyName: "",
                //     customerType: "",
                //     discountType: 1,
                //     addressLine1: "",
                //     addressLine2: "",
                //     postcode: "",
                //     email: "",
                //     notes: "",
                //     status: true
                // };
            } catch (error) {
                console.error("Error saving customer:", error);
            }
        },
        setCustomer(){
            if(this.viewType!=='add'){
                let cus=cloneDeep(this.customers).filter(c=>c.id==this.customer_id)
                this.customer=cus[0]
            }
        }
    }
};
</script>



<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
