<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                Welcome {{authenticatedUser.first_name}} {{authenticatedUser.last_name}}, {{authenticatedUser.branch.name}} Branch

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11">


                <div class="row mt-3 mb-3">


                    <div class="col-md">
                        <v-select
                            v-model="orderfilters.branch_id"

                            class=""
                            placeholder="Choose a Branch"
                            :options="branches"
                            label="name"
                            :reduce="option => option.id"
                            @input="latestinoivices"
                        />
                    </div>
                    <div class="col-md">
                        <v-select
                            v-model="orderfilters.cashier_id"

                            class=""
                            placeholder="Choose a Cashier"
                            :options="cashiers"
                            label="fullName"
                            :reduce="option => option.id"
                            @input="latestinoivices"
                        />
                    </div>
                    <div class="col-md">
                        <v-select
                            v-model="orderfilters.order_status"

                            class=""
                            placeholder="Order Status"
                            :options="['Quote','Deposit','Completed','Pending Collection','Pending Delivery']"
                            @input="latestinoivices"

                        />
                    </div>
                    <div class="col-md">
                        <b-input v-model="orderfilters.order_id"
                                 placeholder="Order ID"
                                 @change="latestinoivices"
                        ></b-input>
                    </div>
                    <div class="col-md">

                        <b-form-datepicker
                            :reset-button=true
                            reset-button-variant="btn btn-warning"

                            id="example-datepicker"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            v-model="orderfilters.delivery_date"
                            placeholder="Dlry Date"
                            dropdown  calendar-width="100%"
                            @input="latestinoivices"
                        ></b-form-datepicker>
                    </div>
                    <div class="col-md">
                        <b-input v-model="orderfilters.searchcustname" placeholder="Customer Name"
                                 @change="latestinoivices"
                        ></b-input>
                    </div>
                    <div class="col-md">
                        <b-input v-model="orderfilters.searchcustphone" placeholder="Telephone"
                                 @change="latestinoivices"
                        ></b-input>
                    </div>
                    <div class="col-md">
                        <b-input v-model="orderfilters.searchcustpostcode" placeholder="Post Code"
                                 @change="latestinoivices"
                        ></b-input>
                    </div>
                    <div class="col-md">
                        <b-input v-model="orderfilters.totalamount"
                                 placeholder="Total Amount"
                                 @change="latestinoivices"
                        ></b-input>
                    </div>
                    <div class="col-md-2">
                        <b-input v-model="orderfilters.searchQuery" placeholder="Item (Name/Code/Description) "
                                 @change="latestinoivices"
                        ></b-input>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md">

                    </div>
                    <div class="col-md-1">
                        <button class="btn-sm btn-info"     :disabled="latestinvoices.current_page==1" @click="getinvoices('prev')">Previous</button>
                    </div>
                    <div class="col-md-3">
<!--                        <span class="text-light mx-2 px-2" v-if="!showloader" style="border: 3px solid white; border-radius: 5px"> showing  ( <span v-if="latestinvoices.to-100>0">{{latestinvoices.to-100}}</span> <span v-else="">0</span>  to {{latestinvoices.to}}) out of {{latestinvoices.total}} records</span>-->
                    </div>
                    <div class="col-md-1">

                        <button class="btn-sm btn-info "    :disabled="latestinvoices.current_page==totalPages" @click="getinvoices('next')">Next</button>
                    </div>
                    <div class="col-md">

                    </div>


                </div>

                <div class="row" v-if="showloader">
                    <div class="col-md-12 text-center">
                        <loader/>
                    </div>
                </div>

                <b-table id="my-table"  class="bg-light "
                         v-if="!showloader"
                         :items="filteredOrders"
                         :fields="fields"
                         striped hover responsive

                >

                    <template #cell(issueDate)="data">
                        {{formatDate(data.item.issueDate)}}
                    </template>
                    <!--                    <template #cell(deliveryDate)="data">-->
                    <!--                        {{formatDate(data.item.deliveryDate)}}-->
                    <!--                    </template>-->
                    <template #cell(customer)="data">
                        {{data.item.customer.lastName}}
                    </template>
                    <template #cell(cashier)="data">
                        {{data.item.cashier.fullName}}
                    </template>
                    <template #cell(actions)="data">
                        <b-button class="btn-info" @click="loadorder(data.item)">Load Order </b-button>
                    </template>

                </b-table>





                <!--                 <b-pagination-->
                <!--                     class=""-->
                <!--                     v-model="currentPage"-->
                <!--                     :total-rows="filteredOrders.length"-->
                <!--                     :per-page="perPage"-->
                <!--                     aria-controls="my-table"-->
                <!--                 ></b-pagination>-->


            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
import {cloneDeep} from "lodash";
import moment from "moment";
import Loader from "@/components/loader";


export default {
    name: "orders",
    props: ['togglorderemodal'],
    components:{Loader, 'v-select': vSelect, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['latestinvoices','cashiers','branches']),
        ...mapState('invoice', ['branches']),
        ...mapState('orders', ['orderfilters']),
        // totalPages() {
        //     const itemsPerPage = 100;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        totalPages() {
            const perpage = this.latestinvoices.per_page;
            const total=this.latestinvoices.total;

            return Math.ceil(total / perpage);
        },

        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

        filteredOrders() {

            return this.latestinvoices.data.filter((order)=>order.status=='Pending Collection' || order.status=='Pending Delivery');



            //  //frontend filter commented start
            //  let forders=''
            //  if (this.orderfilters.searchQuery) {
            //    //  alert(this.cashier_id)
            //      const query = this.orderfilters.searchQuery.toLowerCase();
            //       forders =this.latestinvoices.data.filter(order => {
            //       //   const mob = order.customer.mobile ? order.customer.mobile.toLowerCase() : '';
            //        //  const name = order.customer.lastName ? order.customer.lastName.toLowerCase() : '';
            //       //   const adr1 = order.customer.addressLine1 ? order.customer.addressLine1.toLowerCase() : '';
            //       //   const adr2 = order.customer.addressLine2 ? order.customer.addressLine2.toLowerCase() : '';
            //       //   const pscode = order.customer.postcode ? order.customer.postcode.toLowerCase() : '';
            //
            //
            //          let filteredItems=''
            //          if(order.items){
            //               filteredItems = order.items.filter(item =>
            //                  item.itemCode.toLowerCase().includes(query) ||
            //                  item.itemName.toLowerCase().includes(query) ||
            //                  item.pivot.itemDescription.toLowerCase().includes(query)
            //              );
            //
            //          }
            //          // order.invoice_ref.toLowerCase().includes(query) ||
            //          // order.cashier.fullName.toLowerCase().includes(query) ||
            //        //  order.customer.lastName.toLowerCase().includes(query) ||
            //       return   filteredItems.length > 0
            //                 //   mob.toLowerCase().includes(query) ||
            //                 // adr1.toLowerCase().includes(query) ||
            //                 // adr2.includes(query) ||
            //                 // name.includes(query) ||
            //                 // pscode.includes(query)
            //
            //
            //
            //
            //
            //        //  order.status.toLowerCase().includes(query)
            //          // order.issueDate.includes(query)
            //      })
            //
            //
            //
            //  } else {
            //      forders=this.latestinvoices.data;
            //
            //  }
            //
            //  if(this.orderfilters.branch_id){
            //      forders=forders.filter(dfo=>dfo.branch_id===this.orderfilters.branch_id)
            //  }
            // if(this.orderfilters.cashier_id){
            //     forders=forders.filter(dfo=>dfo.cashier_id===this.orderfilters.cashier_id)
            // }
            //  if(this.orderfilters.order_status){
            //      forders=forders.filter(dfo=>dfo.status===this.orderfilters.order_status)
            //  }
            //  if(this.orderfilters.searchcustname){
            //      let searchcustname=this.orderfilters.searchcustname.toLowerCase();
            //
            //      forders=forders.filter(dfo=> {
            //          const name = dfo.customer.lastName ? dfo.customer.lastName.toLowerCase() : '';
            //          return name.includes(searchcustname)
            //      })
            //  }
            //  if(this.orderfilters.searchcustphone){
            //      let searchcustphone=this.orderfilters.searchcustphone.toLowerCase();
            //
            //      forders=forders.filter(dfo=> {
            //          const mob = dfo.customer.mobile ? dfo.customer.mobile.toLowerCase() : '';
            //          return mob.includes(searchcustphone)
            //      })
            //  }
            //  if(this.orderfilters.searchcustpostcode){
            //      let searchcustpostcode=this.orderfilters.searchcustpostcode.toLowerCase();
            //
            //      forders=forders.filter(dfo=> {
            //          const mob = dfo.customer.postcode ? dfo.customer.postcode.toLowerCase() : '';
            //          return mob.includes(searchcustpostcode)
            //      })
            //  }
            //  if(this.orderfilters.totalamount){
            //      let totalamount=this.orderfilters.totalamount
            //
            //      forders=forders.filter(dfo=> {
            //          const total = dfo.grandTotal.toString();
            //
            //          return total.includes(totalamount)
            //      })
            //  }
            //  if(this.orderfilters.order_id){
            //      let order_id=this.orderfilters.order_id.toLowerCase();
            //
            //      forders=forders.filter(dfo=> {
            //          const invref = dfo.invoice_ref ? dfo.invoice_ref.toLowerCase() : '';
            //          return invref.includes(order_id)
            //      })
            //  }
            //  if(this.orderfilters.delivery_date){
            //
            //
            //      forders=forders.filter(dfo=> {
            //          const invdelivery = dfo.deliveryDate ? dfo.deliveryDate : '';
            //          return invdelivery.includes(this.orderfilters.delivery_date)
            //      })
            //  }
            //  return forders
            //  //frontend filter commented end



        },
    },
    data() {
        return {
            roles: [],
            user: {},
            showloader:true,


            fields: [

                { key: 'invoice_ref', label: 'Order ID' },
                { key: 'issueDate', label: 'Order Date' },
                { key: 'deliveryDate', label: 'Collect/Delivery' },
                { key: 'customer', label: 'Customer' },
                { key: 'cashier', label: 'Cashier' },
                { key: 'status', label: 'Status' },
                { key: 'grandTotal', label: 'Grand Total' },
                'actions'

            ],
        };
    },
    async created() {
        // await this.getUsers();
        // await this.getRoles();
        // // await this.getbranches()
        // // await this.getcashiers()
        // await this.reloadcb()

        const promises = [
            this.getUsers(),
            this.getRoles(),
            this.reloadcb(),
            this.latestinoivices()
        ];

        // Wait for all promises to complete
        await Promise.all(promises);
        //  await  this.latestinoivices()

    },
    async mounted() {

        //   await this.latestinoivices();
    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getLatestInvoices']),
        ...mapActions('invoice', ['updateFullInvoice','getbranches','getcashiers']),
        ...mapActions('items', ['attachinvoiceitems']),
        ...mapActions('payment', ['setNewpayment']),
        ...mapActions('notes',['attachinvoicenotes']),
        ...mapActions('dispatch',['add_dis_items','attach_invoice_transactions']),
        async latestinoivices(){

            let orfilters=
                {
                    branch_id:this.orderfilters.branch_id,
                    invoice_ref:this.orderfilters.order_id,
                    status:this.orderfilters.order_status,
                    cashier_id:this.orderfilters.cashier_id,
                    deliveryDate:this.orderfilters.delivery_date,
                    grandTotal:this.orderfilters.totalamount,
                    lastName:this.orderfilters.searchcustname,
                    mobile:this.orderfilters.searchcustphone,
                    customerCode:this.orderfilters.searchcustpostcode,
                    itemsquery:this.orderfilters.searchQuery,
                    displaypage:'deliveries',
                    page:1

                }
            console.log('filters', orfilters)
            this.showloader=true
            await this.getLatestInvoices(orfilters);
            this.showloader=false

        },

        async getinvoices(button){
            let current_page= this.latestinvoices.current_page
            let orfilters=
                {
                    branch_id:this.orderfilters.branch_id,
                    invoice_ref:this.orderfilters.order_id,
                    status:this.orderfilters.order_status,
                    cashier_id:this.orderfilters.cashier_id,
                    deliveryDate:this.orderfilters.delivery_date,
                    grandTotal:this.orderfilters.totalamount,
                    lastName:this.orderfilters.searchcustname,
                    mobile:this.orderfilters.searchcustphone,
                    customerCode:this.orderfilters.searchcustpostcode,
                    itemsquery:this.orderfilters.searchQuery,
                    displaypage:'deliveries',
                    page:current_page
                }



            if(button=='next'){
                console.log(orfilters,'status check')
                orfilters.page=current_page+1
                this.showloader=true
                await   this.getLatestInvoices(orfilters)
                this.showloader=false
            }
            if(button=='prev'){
                orfilters.page=current_page-1
                this.showloader=true
                await  this.getLatestInvoices(orfilters)
                this.showloader=false

            }

        },

        async reloadcb(){
            if(this.branches.length==0){

                //   alert(this.branches.length)
                await this.getbranches()
                // console.log(this.latestinvoices.data.length,'here')

            }
            if(this.cashiers.length==0){
                await this.getcashiers()
            }
        },
        // async  myProvider(ctx) {
        //     try {
        //         const response = await axios.get(`/some/url?page=${ctx.currentPage}&size=${ctx.perPage}`)
        //         return response.items
        //     } catch (error) {
        //         return []
        //     }
        // },
        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },
        loadorder(order) {
            //   console.log(order,'loading')
            this.updateFullInvoice(order)

            this.attachinvoiceitems(order.items)
            let dispatchingitems=cloneDeep(order.items)
            let disitems=dispatchingitems.map((d)=>{

                return { ...d, qty: 0, itemDescription:d.pivot.itemDescription};
            })
            this.add_dis_items(disitems)

            this.attachinvoicenotes(order.notes)
            this.attach_invoice_transactions(order.transactions)
            //  console.log(order)
            this.$store.commit('customers/setSelectedCustomer', order.customer)
            this.setNewpayment({
                "invoice_id":null,
                "invoice_ref":null,
                "cashier_id":null,
                "payment_type": null,
                "reference":null,
                "payment_amount":0,
                "date":null
            })
            this.$router.push({ path: '/' })
            //  this.togglorderemodal()

        },
        async signOut() {
            await this.processLogoutManual();
           // await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
