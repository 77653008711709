<template >
     <div class="">
        <Header/>

        <b-container fluid class="my-5">
            <b-card class="shadow-sm mt-2" body-class="p-0">
                <div class="d-flex justify-content-end align-items-center p-3">
                    <div>
                        <label class="mb-0" size="sm">{{'Filter by role'}}</label>
                        <b-form-select @change="filterUsers" class="ml-3" size="sm" value-field="id" text-field="name"
                                    :options="roles"
                                    style="width: 150px">
                            <template #first>
                                <b-form-select-option :value="''">-- Please select an option --
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <b-button v-can="'create user'" class="primary-btn ml-3" variant="info" size="sm"
                            to="/create-user">{{'Add New User'}}
                    </b-button>
                </div>
                <b-table head-variant="dark" small :items="items" responsive bordered class="text-center" :fields="fields">
                    <template #cell(role)="data">
                        <div v-for="item in data.item.roles" :key="item.id">
                            {{ item.name }}
                        </div>
                    </template>
                    <template #cell(actions)="data">
                        <div class='d-flex justify-content-center'>
                            <router-link v-can="'edit user'" :to="`/edit-user/${data.item.id}`">
                                <b-icon variant="info" font-scale="1.5" icon="pencil-square"></b-icon>
                            </router-link>
                            <a v-can="'delete user'" @click="deleteUser(data.item.id,$event)" href="/users/delete-user"
                            class="ml-2">
                                <b-icon variant="danger" font-scale="1.5" icon="trash"></b-icon>
                            </a>
                        </div>
                    </template>
                </b-table>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import {confirmDeleteAlert, errorAlert, successAlert} from "@/components/Alerts";
import Header from "@/components/layout/Header";
//import PageHeader from "@/components/layout/PageHeader";
export default {
    name: "Users",
    mixins: [],
    components: {Header},
    computed:{
        fields() {
            return [
                {
                    label:'Sr#',
                    key: 'id'
                },
                {
                    label:'First Name',
                    key: 'first_name'
                },
                {
                    label:'Last Name',
                    key: 'last_name'
                },
                {
                    label:'Email',
                    key: 'email'
                },
                {
                    label:'User Name',
                    key: 'user_name'
                },
                // {
                //     label:'CRR ID',
                //     key: 'customer_id_number'
                // },
                // {
                //     label:'Customer No',
                //     key: 'customer_number'
                // },
                // {
                //     label:'Payer No',
                //     key: 'payer_number'
                // },
                {
                    label:'Role',
                    key: 'role'
                },
                {
                    label:'Actions',
                    key: 'actions'
                },

            ]
        }
    },
    data() {
        return {

            items: [],
            roles: [],
            user: {}
        }
    },
    created() {
        this.getUsers();
        this.getRoles();
    },
    methods: {
        async getUsers(role = '') {
            const {data} = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async deleteUser(id, e) {
            e.preventDefault()
            const {isConfirmed} = await confirmDeleteAlert('user');
            if (isConfirmed) {
                try {
                    await this.$axios.delete(`user/${id}`);
                    await successAlert('User', 'deleted')
                    await this.getUsers();
                } catch (e) {
                    await errorAlert('User', 'deleting');
                }
            }
        },
        async getRoles() {
            const {data} = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        }
    }
}
</script>

<style scoped>

</style>
