<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">
                <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                Welcome, {{authenticatedUser.branch.name}} Branch

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 mt-2 pb-2 bgsecond">

                <div class="row   text-light">

                    <div class="col-md-8">
                        <b-form @submit.prevent="savesupplier">
                            <div class="row ">
                                <h1 class="ml-1 mt-1">
                                    <span v-if="actiontype=='Save'">Enter</span>
                                    <span v-if="actiontype=='Update'">Update</span>
                                    Supplier Details </h1>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="firstName" class="form-label">Full Name</label>
                                    <b-form-input v-model="supplier.name" type="text" class="form-control" id="firstName" required :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="title" class="form-label">SKU</label>
                                    <b-form-input v-model="supplier.sku" type="text" class="form-control" id="title" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="status" class="form-label">Status</label>
                                    <b-form-checkbox v-model="supplier.status" name="check-button" switch class="mt-1" :disabled="actiontype=='View'">

                                    </b-form-checkbox>
                                </div>



                            </div>








                            <button v-if="actiontype!='View'" type="submit" class="btn btn-primary mt-3"> {{actiontype}} Supplier</button>

                        </b-form>

                    </div>



                </div>



            </div>



        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import {mapActions, mapState} from "vuex";
import { mapGetters } from 'vuex';
//import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import axios from "axios";
//import {cloneDeep} from "lodash";
import Swal from "sweetalert2";
import showrealtime from "@/components/showrealtime";
import URL from "@/constants/baseurl"
//import newinvoice from "@/constants/newinvoice";
//import newcustomer from "@/constants/newcustomer";
//import newpayment from "@/constants/newpayment";
//import newitem from "@/constants/newitem";
//import vSelect from "vue-select";
import apiService from "@/service/apiService";
//import vSelect from "vue-select";


export default {
    name: "supplierform",

    components:{ 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['cashiers']),


        // ...mapState('invoice', ['branches']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },


        actiontype(){
            if (this.$route.query.type=='add'){
                return 'Save'
            }
            else if (this.$route.query.type=='edit'){
                return 'Update'
            }
            else {
                return 'View'
            }
        }

    },
    data() {
        return {
            roles: [],
            user: {},
            perPage:10,
            currentPage: 1,

            cardId:'',
            supplier: {
                id:"",
                name: "",
                sku: "",
                status: true,

            },



        };
    },

    async created() {


        this.viewType = this.$route.query.type;
        this.supplier_id = this.$route.query.id;

      await  this.setsupplier();
        this.supplier.status= this.supplier.status==1 ? true:false



    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),




        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },





        async savesupplier() {

                if(!this.supplier.name){
                    Swal.fire('Error', 'Name Is Required', 'error');
                    return
                }

                if(!this.supplier.sku){
                    Swal.fire('Error', 'SKU Is Required', 'error');
                    return
                }


            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }


            try {
                const response = await axios.post(`${URL.BASEURL}/api/create-supplier`,  this.supplier);
                console.log("Supplier saved:", response.data);
                await Swal.fire('Success', 'Supplier Saved Successfully!', 'success');
                this.$router.push({ path: '/suppliers' })


            } catch (error) {
                console.error("Error saving supplier:", error);
            }
        },
      async  setsupplier(){
            if(this.viewType!=='add'){
                const {data} = await apiService.get(`/supplier/${this.supplier_id}`);
                this.supplier=data
            }
        }
    }
};
</script>



<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
