<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                Welcome, {{authenticatedUser.branch.name}} Branch

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white">

                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-light text-center">
                          Stock Adjustment
                        </h1>
                    </div>
                    <div class="col-md-12 text-light">
                        <h1 class="text-center">Details</h1>
                        <h1 class="col-12 "> <hr class="hr0"></h1>
                        <!--                        <div class="row mt-1">-->
                        <!--                            <div class="col-md-6">-->
                        <!--                                <p><strong>Enter Purchase Order Reference</strong></p>-->
                        <!--                            </div>-->
                        <!--                            <div class="col-md-6">-->
                        <!--                                <b-input v-model="por"></b-input>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="row ">
                            <div class="col-md-3">
                                <label><strong>Select Reason</strong></label>
                                <b-form-select
                                    v-model="adjustment_reason"
                                    :options="['Opening', 'Damage', 'Lost','Consumed','Other']"
                                    class="bgsecond bform text-white mb-2 "
                                    @change="reset_items"

                                >

                                    <template #first>
                                        <b-form-select-option value="">Select Reason</b-form-select-option>
                                    </template>
                                    <template #option="{ option }">
                                        <div>{{ option }}</div>
                                    </template>

                                </b-form-select>
                            </div>
                            <div class="col-md-3">
                                <label><strong>Select Location</strong></label>
                                <b-form-select
                                    v-model="location_id"
                                    :options="locations"
                                    class="bgsecond bform text-white mb-2 "

                                    value-field="id"
                                    text-field="location_name"
                                >

                                    <template #first>
                                        <b-form-select-option value="">Select Location</b-form-select-option>
                                    </template>
                                    <template #option="{ option }">
                                        <div>{{ option.location_name }}</div>
                                    </template>

                                </b-form-select>
                            </div>

                            <div class="col-md-3">
                                <label><strong>Select Date</strong></label>
                                <b-form-datepicker

                                    :reset-button=false
                                    reset-button-variant="btn btn-warning"
                                    id="datepicsuper"

                                    v-model="date"
                                    placeholder="Date"

                                    dropup  calendar-width="100%">

                                </b-form-datepicker>
                            </div>
                            <div class="col-md-3">
                                <label><strong>Note</strong></label>
                                <b-form-textarea
                                    id="textarea"
                                    v-model="note"
                                    placeholder="Enter Note"
                                    rows="2"
                                ></b-form-textarea>
                            </div>
                        </div>


                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
<!--                                <b-link class="" :to="{ path: '/productform',query: { type: 'add'} }">-->
<!--                                    <b-button class="btn-info " > Add New Item </b-button>-->
<!--                                </b-link>-->
                            </div>
                            <div class="col-md-6">
                                <h1 class="text-light text-center">Items</h1>
                            </div>
                        </div>


                        <table class="table   table-responsive-sm">
                            <thead  class="thead-dark table-bordered " >
                            <tr class="">
                                <th scope="col">Code </th>
                                <th scope="col">Description</th>
                                <th scope="col" >Qty</th>

                            </tr>
                            </thead>
                            <tbody class="" >
                            <datalist id="item-list-id" >
                                <option v-for="item in items" :key="item.mapid" :value="item.itemCode"> {{ item.itemName }}  | {{item.itemDescription}}  </option>

                            </datalist>
                            <template v-if="stock_adjustment_searched_items">
                                <tr class="text-light"  v-for="item in stock_adjustment_searched_items" :key="item.mapid">
                                    <th class="col-md-3 ">
                                        <div class="input-container w-100">
                                            <i  class="bi bi-x-circle custom-icon cursor-pointer" @click="removeitem(item.mapid)" style="margin-right: 0.5rem;"></i>
                                            <input class="bg-light col-md-11 form-control" v-model="item.itemCode"  list="item-list-id" :disabled="adjustment_reason==''" @change="addsearchitem('',item.itemCode, item.mapid)"  >

                                        </div>
                                    </th>

                                    <td><b-input class=" bg-light form-control" v-model="item.itemDescription" :disabled="true"> </b-input></td>
                                    <td class="col-md-2 "><input type="number" v-model="item.qty"  @change="handlechange(item.mapid)" class="bg-light form-control" ></td>



                                </tr>
                            </template>
                            <tr>
                                <th scope="row" >

                                    <i class="fa-solid fa-plus fa-2xl  cursor-pointer" style="color: #08e234; " v-if="showplus" @click="addsearchitem('yes')"></i>
                                </th>
                                <td></td>
                                <td></td>




                            </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6 offset-3">
                        <button  type="button" class="btn custom-btn2 w-100 btn-lg text-light   mt-5 mtmb" :disabled="disablesavestock" @click="adjust_stock">Adjust Stock</button>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-md-12">
                        <h1 class="text-light text-center">History</h1>
                    </div>
                    <div class="col-md-12">
                        <table class="table text-light " >
                            <thead >

                            <th class="col-md-1">ID</th>
                            <th class="col-md-2">Date</th>
                            <th class="col-md-3">User</th>
                            <th class="col-md-3">Location</th>
                            <th class="col-md-2">Reason</th>
                            <th class="col-md-1">Note</th>

                            </thead>
                            <tbody >
                            <tr v-for="his in history" :key="his.id">

                                <td> <a href="#" @click.prevent="showmodaldata(his.id)">{{his.id}}</a> </td>
                                <td>{{formatDate(his.date)}}</td>
                                <td>{{users.find(cs=>cs.id==his.user_id).last_name }}</td>

                                <td>{{locations.find(lc=>lc.id==his.location_id).location_name}}</td>
                                <td>{{his.adjustment_reason}}</td>
                                <td>{{his.note}}</td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

                <b-modal  v-model="modalShow"  centered  >
                    <div class="row mt-5">
                        <div class="col-md-12">
                            <h1 class=" text-center">Items Detail</h1>
                        </div>
                        <div class="col-md-12">
                            <table class="table table-hover" >
                                <thead >

                                <th class="col-md-1">ID</th>
                                <th class="col-md-3">Item Code</th>
                                <!--                                <th class="col-md-3">Description</th>-->
                                <th class="col-md-3">Quantity</th>


                                </thead>
                                <tbody >
                                <tr v-for="item in selected_history.dispatchitems" :key="item.id">

                                    <td> {{item.id}} </td>
                                    <td>{{item.itemCode}}</td>
                                    <!--                                    <td>{{item.itemDescription}}</td>-->
                                    <td>{{item.qty}}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>


                </b-modal>

            </div>


        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
//import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";
import newitem from "@/constants/newitem";
import Swal from "sweetalert2";
import apiService from "@/service/apiService";
import axios from "axios";
import URL from "@/constants/baseurl";
//import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
//import axios from "axios";
//import URL from "@/constants/baseurl";
//import Swal from "sweetalert2";
//import apiService from "@/service/apiService";


export default {
    name: "reports",

    components:{ 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['allinvoices','branches']),
        ...mapState('invoice', ['users']),
        ...mapState('items',['items','stock_adjustment_searched_items']),

        disablesavestock(){
            if(this.location_id=='' || this.date=='' || (this.stock_adjustment_searched_items.filter((is)=>is.id!=0)).length==0){
                return true
            } else return false
        },

        showitemdatalist(){
            if(this.stock_adjustment_searched_items.length==0){
                // return false uncomment and return false on demand
                return true
            }
            else {
                return true
            }
        },

        showplus(){
            let newitems = this.stock_adjustment_searched_items.filter((item)=>item.itemCode=='')
            if(newitems.length==0){
                return true
            }
            else {
                return false
            }
        },



        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {
            modalShow:false,
            selected_history:{},
            history:[],
            roles: [],
            user: {},
            user_id:'',
            branch_id:'',
            searchitem:'',
            locations:[],
            adjustment_reason:'',
            location_id:'',
            date:new Date(),
            note:''

        };
    },



    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        //   await this.getbranches()
        // await this.getcashiers()
        await this.reloadcb()


    },
    async mounted() {


        this.addsearchitem()
        await this.getlocations()
        await this.gethistory('Adjustment')
    },



    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getAllInvoices','getbranches','getusers']),
        ...mapActions('items', ['getItems']),
        ...mapActions('items', ['removestockadjustmentSearchedItem']),
        ...mapActions('items',['add_stock_adjustment_searched_items','reset_stock_adjustment_searched_items']),


        // watch: {
        //     stock_adjustment_searched_items: {
        //         handler(newValue) {
        //             // Note: `newValue` will be equal to `oldValue` here
        //             // on nested mutations as long as the object itself
        //             // hasn't been replaced.
        //             //    console.log(newValue,'by watch',oldValue)
        //             if(this.adjustment_reason=='Opening'){
        //                 let targetobj=  newValue.find((n)=>Number(n.qty)<1)
        //                 if (targetobj){
        //                     //  console.log(targetobj.qty,'target qty')
        //                     if(targetobj.id!=0 && targetobj.qty!=''){
        //                         targetobj.qty=1
        //                     }
        //                 }
        //             }
        //             else if(this.adjustment_reason=='Damage' || this.adjustment_reason=='Lost' || this.adjustment_reason=='Consumed'){
        //                 let targetobj=  newValue.find((n)=>Number(n.qty)>-1)
        //                 if (targetobj){
        //                     //  console.log(targetobj.qty,'target qty')
        //                     if(targetobj.id!=0 && targetobj.qty!=''){
        //                         targetobj.qty=-1
        //                     }
        //                 }
        //             }
        //             else if(this.adjustment_reason=='Other' ){
        //                 let targetobj=  newValue.find((n)=>Number(n.qty)==0)
        //                 if (targetobj){
        //                     //  console.log(targetobj.qty,'target qty')
        //                     if(targetobj.id!=0 && targetobj.qty!=''){
        //                         targetobj.qty=1
        //                     }
        //                 }
        //             }
        //
        //         },
        //         deep: true
        //     }
        // },
        handlechange(mapid) {
            //  alert(mapid);
            let item = this.stock_adjustment_searched_items.find((item) => item.mapid == mapid);
            let box_qty = Number(item.box_qty);
            let qty = Number(item.qty);
            if(this.adjustment_reason=='Opening'){

                if(qty<1){
                    if(item.id!=0 && item.qty!=''){
                        if (box_qty) {
                            item.qty = Number(item.box_qty).toFixed(2)
                        } else {
                            item.qty = 1
                        }
                    }
                }
                let validqty = qty / box_qty
                if (box_qty) {
                    if (validqty < 1) {
                        item.qty = Number(item.box_qty).toFixed(2)
                    }
                        // else if (qty % box_qty == 0) {
                        //     //
                    // }
                    else {
                        item.qty = Number((Math.ceil(Number(qty / box_qty).toFixed(2))) * box_qty).toFixed(2)
                    }
                }
            }
            else if (this.adjustment_reason=='Damage' || this.adjustment_reason=='Lost' || this.adjustment_reason=='Consumed'){

                if(qty>-1){
                    if(item.id!=0 && item.qty!=''){
                        if (box_qty) {
                            item.qty=-Number(item.box_qty).toFixed(2)
                        } else {
                            item.qty=-1
                        }

                    }
                }
                let validqty = Number(item.qty) / box_qty


                if (box_qty) {

                    if (validqty > -1 && validqty<0) {

                        item.qty = -Number(item.box_qty).toFixed(2)
                    }
                        // else if (qty % box_qty == 0) {
                        //     //
                    // }
                    else {

                        item.qty = Number((Math.floor(Number(Number(item.qty) / box_qty).toFixed(2))) * box_qty).toFixed(2)
                    }
                }
            }
            else if(this.adjustment_reason=='Other'){
                if(qty==0){
                    item.qty=1
                }
            }



        },
        showmodaldata(id){
            this.selected_history=this.history.find((hs)=>hs.id==id)
            this.modalShow = ! this.modalShow
        },
        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, YYYY');
            //  let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },
        async  gethistory(mode){
            const {data} = await apiService.get(`/stock-history/${mode}`);

            this.history=data
            // console.log('history',this.history)
        },
        reset_items(){
            this.reset_stock_adjustment_searched_items()
            this.addsearchitem()
        },
        async adjust_stock(){
            let ledger_entry={
                items:this.stock_adjustment_searched_items,
                location_id:this.location_id,
                adjustment_reason:this.adjustment_reason,
                date:moment(this.date).format('YYYY-MM-DD HH:mm:ss'),
                user_id:this.authenticatedUser.id,
                note:this.note

            }
            console.log(ledger_entry)

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/api/adjust-stock`, ledger_entry);

                if (res) {

                    Swal.fire('Success', 'Stock Adjusted', 'success');
                    console.log(res.data)
                    this.reset_stock_adjustment_searched_items()
                    this.addsearchitem()

                    this.location_id='';
                    this.adjustment_reason='';
                    this.note='';
                    this.date=new Date();
                    await this.gethistory('Adjustment')


                }
            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to Add Stock.', 'info');
            }
        },
        async getlocations(){
            const {data} = await apiService.get(`/locations`);
            //  console.log('branches', data)
            this.locations=data


        },
        addsearchitem(dummy='', itemcode=null,mapid=null){

            // alert(this.searchitem)
            //console.log((this.searched_items).length)
            if(dummy=='yes' || (this.stock_adjustment_searched_items).length==0) {
                this.add_stock_adjustment_searched_items(newitem)
            }
            else if(itemcode) {
                let   item=this.items.find((i) => i.itemCode==itemcode)
                console.log(item,'item')
                if(item){
                    //  console.log(item[0])
                    let box_qty=item.box_qty
                    if(this.adjustment_reason=='Opening'){
                        if (box_qty) {
                            item.qty=Number(item.box_qty).toFixed(2)
                        } else {
                            item.qty=1
                        }
                    } else if (this.adjustment_reason=='Damage' || this.adjustment_reason=='Lost' || this.adjustment_reason=='Consumed'){
                        if (box_qty) {
                            item.qty=-Number(item.box_qty).toFixed(2)
                        } else {
                            item.qty=-1
                        }
                    }

                    this.add_stock_adjustment_searched_items(item)


                    const   dummyitem=(this.stock_adjustment_searched_items).find((i) => i.id==0)
                    console.log(dummyitem)
                    this.removestockadjustmentSearchedItem(dummyitem.mapid)


                }
                else {
                    Swal.fire('Item Not Found', 'No item available for this code', 'info');
                    //  alert('not found')
                    this.removeitem(mapid)
                }
            }
            //  console.log(this.searched_items)

        },

        removeitem(id){
            //  await  Swal.fire('Removing ', 'You are removing item from invoice', 'info');
            this.removestockadjustmentSearchedItem(id)

        },


        formattedDate(date) {

            const formattedDate = moment(date).format('DD-MM-YYYY');

            return formattedDate;
        },


        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.items.length==0){
                //   alert(this.branches.length)
                await this.getItems();

            }
            if(this.users.length==0){
                await this.getusers()
                 // console.log(this.users,'users')
            }
        },



        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.gray{
    background-color: gray !important;
}
.input-container {
    display: inline-flex; /* Use inline-flex to place the elements in the same line */
    align-items: center; /* To vertically center the elements */
}

.hr0{
    margin-top: 5px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid green;
    max-width: 100%;

}



</style>
