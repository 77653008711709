<template>
    <div style="margin-top: 50px" class="text-light">
        <p>You have not access to this page </p>
        <router-link to="/">Back to home page</router-link>
    </div>

</template>

<script>
export default {
    name: 'Unauthorized',
}
</script>
