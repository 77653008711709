<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
            <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white">

                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-light text-center">
                            Stock Calculation
                        </h1>
                    </div>

                    <div class="col-md-6 text-light">
                        <h1 class="text-center">Choose Below</h1>
                        <h1 class="col-12 "> <hr class="hr0"></h1>

                        <div class="row mt-4 mb-3">
                            <div class="col-md-6">
                                <v-select
                                    v-model="item_id"
                                    class="text-dark"
                                    placeholder="Select Item"
                                    :options="allitems"
                                    label="itemCode"
                                    :reduce="option => option.id"
                                    @input="getcalculateditem"

                                />
                            </div>
                            <div class="col-md-6">
                                <v-select
                                    v-model="location_id"

                                    class="text-dark"
                                    placeholder="Choose a Location"
                                    :options="locations"
                                    label="location_name"
                                    :reduce="option => option.id"
                                    @input="getcalculateditem"

                                />
                            </div>
                        </div>

<!--                        <div class="row">-->
<!--                            <div class="col-md-10 offset-1">-->
<!--                                <button  type="button" class="btn custom-btn2 w-100 btn-lg text-light   mt-5 mtmb"  @click="getcalculateditem">Calculate</button>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>

                    <div class="col-md-12 text-light">
                        <h1 class="text-center">Details</h1>
                        <h1 class="col-12 "> <hr class="hr0"></h1>

                        <div class="row mt-4 mb-3 text-light">

                            <table class="table  table-responsive-sm">
                                <thead  class="thead-dark table-bordered " >
                                <tr class="">
                                    <th scope="col">Code </th>
                                    <th scope="col">Description</th>
                                    <th scope="col" >Qty</th>

                                </tr>
                                </thead>
                                <tbody class="" >


                                <tr class="text-light" v-if="seleteditem">
                                    <td >{{seleteditem.itemCode}}</td>
                                    <td>{{seleteditem.itemDescription}}</td>
                                    <td>{{item_sum}}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>


                    </div>

                </div>

            </div>


        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
//import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";

//import Swal from "sweetalert2";
import apiService from "@/service/apiService";
//import axios from "axios";
//import URL from "@/constants/baseurl";
import vSelect from "vue-select";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
//import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
//import axios from "axios";
//import URL from "@/constants/baseurl";
//import Swal from "sweetalert2";
//import apiService from "@/service/apiService";


export default {
    name: "reports",

    components:{Welcomenote, 'v-select': vSelect, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['allinvoices','cashiers','branches']),
        ...mapState('items',['items']),

        allitems(){
       return this.items
        },

        seleteditem(){
            return this.items.find((item)=>item.id==this.item_id)
        },








        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {

            roles: [],
            user: {},
            cashier_id:'',
            location_id:'',
            item_id:'',
            item_sum:0,
            locations:[]


        };
    },



    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        //   await this.getbranches()
        // await this.getcashiers()
        await this.reloadcb()


    },
    async mounted() {


        await this.getlocations()
    },



    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getAllInvoices','getbranches','getcashiers']),
        ...mapActions('items', ['getItems']),






        async getlocations(){
            const {data} = await apiService.get(`/locations`);
            //  console.log('branches', data)
            this.locations=data


        },
        async getcalculateditem(){
            let location_id=this.location_id
            if (this.location_id=='' || this.location_id==null){
                location_id=0
            }
            const {data} = await apiService.get(`/calculate-stock/${this.item_id}/${location_id}`);
            //  console.log('branches', data)
            this.item_sum=data
        },





        formattedDate(date) {

            const formattedDate = moment(date).format('DD-MM-YYYY');

            return formattedDate;
        },


        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.items.length==0){
                //   alert(this.branches.length)
                await this.getItems();

            }
            if(this.cashiers.length==0){
                await this.getcashiers()
            }
        },



        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.gray{
    background-color: gray !important;
}
.input-container {
    display: inline-flex; /* Use inline-flex to place the elements in the same line */
    align-items: center; /* To vertically center the elements */
}

.hr0{
    margin-top: 5px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid green;
    max-width: 100%;

}



</style>
