var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid",attrs:{"id":"printMe"}},[_c('div',{staticClass:"row mt-4"},[_vm._m(0),_c('div',{staticClass:"col-5"},[_c('img',{staticClass:"col-12 w-100",attrs:{"src":require("@/assets/images/rtlogo.png")},on:{"load":_vm.print}})]),_vm._m(1)]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-6 offset-1"},[_c('h4',{staticClass:"mt-5"},[_vm._v("Delivery Details")]),_c('address',[_c('p',[_vm._v(_vm._s(_vm.selected_poprint.delivery_location.location_name))]),_c('p',[_vm._v(_vm._s(_vm.selected_poprint.delivery_location.address_1))]),_c('p',[_vm._v(_vm._s(_vm.selected_poprint.delivery_location.address_2))]),_c('p',[_vm._v(_vm._s(_vm.selected_poprint.delivery_location.post_code)+", UK")])])]),_c('div',{staticClass:"col-4 mt-3",staticStyle:{"background-color":"#f6f6f6","border":"1px solid #f6f6f6","border-radius":"2px"}},[_c('div',{staticClass:"row",staticStyle:{"margin-top":"10px","border-bottom":"1px solid green"}},[_vm._m(2),_c('div',{staticClass:"col-6"},[_c('p',[_vm._v(_vm._s(_vm.selected_poprint.purchase_order_ref))])]),_vm._m(3),_c('div',{staticClass:"col-6"},[_c('p',[_vm._v(_vm._s(_vm.formattedDate(_vm.selected_poprint.created_at)))])]),_vm._m(4),_c('div',{staticClass:"col-6"},[_c('p',[_vm._v(_vm._s(_vm.formattedDate(_vm.selected_poprint.date)))])]),_vm._m(5),_c('div',{staticClass:"col-6"},[_c('p',[_vm._v(_vm._s(_vm.selected_poprint.supplier.name))])])])])]),_vm._m(6),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-10 offset-1"},[_c('table',{staticClass:"table table-borderless"},[_vm._m(7),_c('tbody',[(_vm.selected_poprint)?_vm._l((_vm.selected_poprint.po_items),function(po_item){return _c('tr',{key:po_item.id},[_c('td',[_vm._v(" "+_vm._s(po_item.item_code)+" ")]),_c('td',[_vm._v(_vm._s(po_item.item_description)+" ")]),_c('td',[_vm._v(_vm._s(po_item.supplier_sku))]),_c('td',[_vm._v(_vm._s(po_item.qty))])])}):_vm._e()],2)]),_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"col-7 text-success",staticStyle:{"font-size":"80px","margin-top":"-15px !important"}},[_c('span',[_vm._v(" Purchase Order ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"col-12"},[_c('hr',{staticClass:"hr0"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('p',[_vm._v("PO #:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('p',[_vm._v("Date: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('p',[_vm._v("Expected Date: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('p',[_vm._v("Supplier: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('h1',{staticClass:"col-12"},[_c('hr',{staticClass:"hr0"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticStyle:{"border-bottom":"3px solid black"}},[_c('th',{staticClass:"col-4"},[_vm._v("Product Code")]),_c('th',{staticClass:"col-5"},[_vm._v("Description")]),_c('th',{staticClass:"col-5"},[_vm._v("Supplier Sku")]),_c('th',{staticClass:"col-2"},[_vm._v("Quantity")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('address',[_vm._v(" Lloyds ceramics Ltd "),_c('br'),_vm._v(" Unit 16 & 17 Clock Tower Road "),_c('br'),_vm._v(" Isleworth TW76GF "),_c('br'),_vm._v(" United Kingdom "),_c('br')])])
}]

export { render, staticRenderFns }