import apiService from "../../service/apiService";
import newcustomer from "@/constants/newcustomer";
import {cloneDeep} from "lodash";

export default {
    namespaced: true,
    state: () => ({
        customers: [],
        selected_customer:cloneDeep(newcustomer)


    }),
    mutations: {

        setCustomers(state, data) {
            state.customers = data

        },
        setSelectedCustomer(state, data) {
            state.selected_customer = data

        },
        setSelectedCustomerBalance(state, data) {
            state.selected_customer.walletBalance=Number(state.selected_customer.walletBalance)+data

        },

    },
    actions: {

        async getCustomers({commit}) {

            let user = localStorage.getItem('user');
            user=  JSON.parse(user)
            const branch_id=user.branch.id
            let superadmin='no';
            if(user.permissions.includes('super')){
                superadmin='yes';
            }

            let {data} = await apiService.get(`/customers/${superadmin}/${branch_id}`);
            // console.log(data,'cus_b')

            // console.log(branch_id,'cus_bn')

          //  data=data.filter(c=>c.branch_id==branch_id)
            // console.log(data,'cus_bn2')
            commit('setCustomers', data)

            // console.log('customers',data)


        },
        set_selectedcustomer_balance({commit},balance){
            commit('setSelectedCustomerBalance', balance)
        },
        set_selectedcustomer({commit},data){
            commit('setSelectedCustomer', data)
        },



    },
    getters: {}
}
