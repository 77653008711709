<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                Welcome, {{authenticatedUser.branch.name}} Branch

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white">





                <div class="row mt-5">
                    <div class="col-md-12">
                        <b-link class="mx-1" :to="{ path: '/inbound-stock',query: { type: 'add'} }" >
                            <b-button class="btn-info mb-2" > Create New Inbound </b-button>
                        </b-link>
                        <h1 class="text-light text-center">Inbound History</h1>
                    </div>
                    <div class="col-md-12">
                        <table class="table text-light " >
                            <thead >

                            <th class="col-md-1">ID</th>
                            <th class="col-md-3">Date</th>
                            <th class="col-md-3">User</th>
                            <th class="col-md-3">Location</th>
                            <th class="col-md-2">PO Ref</th>
                            <th class="col-md-2">Status</th>
                            <th class="col-md-2">Action</th>

                            </thead>
                            <tbody >
                            <tr v-for="his in history" :key="his.id">

                                <td> <a href="#" @click.prevent="showmodaldata(his.id)">{{his.id}}</a> </td>
                                <td>{{formatDate(his.date)}}</td>
                                <td>{{users.find(cs=>cs.id==his.user_id).last_name}}</td>
                                <td>{{locations.find(lc=>lc.id==his.location_id).location_name}}</td>
                                <td>{{his.purchase_order_ref}}</td>
                                <td>


                                    <span v-if="his.status"> {{his.status}} </span>
                                    <span v-else=""> Completed </span>

                                </td>
                                <td>
                                    <span v-if="his.status=='Draft'">
                                    <button class="btn btn-info" @click="approve_inbound(his.id)">Receive</button>
                                        <!--                                    <button v-if="(! authenticatedUser.permissions.includes('super') && ! authenticatedUser.permissions.includes('warehouse-manager')) && his.user_id==authenticatedUser.id" class="btn btn-info" @click="edit_transfer(his)">Edit</button>-->
                                <b-link class="mx-1" :to="{ path: '/inbound-stock',query: { type: 'edit', id:his.id} }" >
                                   <b-button class="btn-info mt-1" > Edit </b-button>
                                    </b-link>
                                    </span>

                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>


                <b-modal  v-model="modalShow"  centered  >
                    <div class="row mt-5">
                        <div class="col-md-12">
                            <h1 class=" text-center">Items Detail</h1>
                        </div>
                        <div class="col-md-12">
                            <table class="table table-hover" >
                                <thead >

                                <th class="col-md-1">ID</th>
                                <th class="col-md-3">Item Code</th>
                                <!--                                <th class="col-md-3">Description</th>-->
                                <th class="col-md-3">Quantity</th>


                                </thead>
                                <tbody >
                                <tr v-for="item in selected_history.dispatchitems" :key="item.id">

                                    <td> {{item.id}} </td>
                                    <td>{{item.itemCode}}</td>
                                    <!--                                    <td>{{item.itemDescription}}</td>-->
                                    <td>{{item.qty}}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>


                </b-modal>

            </div>


        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
//import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";
import newitem from "@/constants/newitem";
import Swal from "sweetalert2";
import apiService from "@/service/apiService";
//import axios from "axios";
//import URL from "@/constants/baseurl";
//import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
//import axios from "axios";
//import URL from "@/constants/baseurl";
//import Swal from "sweetalert2";
//import apiService from "@/service/apiService";


export default {
    name: "reports",

    components:{ 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['allinvoices','branches']),
        ...mapState('invoice', ['users']),
        ...mapState('items',['items','inbound_searched_items']),

        disablesavestock(){
            if(this.location_id=='' || this.date==''  || (this.inbound_searched_items.filter((is)=>is.id!=0)).length==0){
                return true
            } else return false
        },


        showitemdatalist(){
            if(this.inbound_searched_items.length==0){
                // return false uncomment and return false on demand
                return true
            }
            else {
                return true
            }
        },

        showplus(){
            let newitems = this.inbound_searched_items.filter((item)=>item.itemCode=='')
            if(newitems.length==0 && ! this.edit){
                return true
            }
            else {
                return false
            }
        },



        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {
            dispatch_id:'',
            edit:false,
            selected_history:{},
            modalShow:false,
            history:[],
            roles: [],
            user: {},
            user_id:'',
            branch_id:'',
            searchitem:'',
            locations:[],
            location_id:'',
            por:'',
            date:new Date()

        };
    },



    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        //   await this.getbranches()
        // await this.getcashiers()
        await this.reloadcb()


    },
    async mounted() {


        await this.getlocations()
        await this.gethistory('inbound')
    },



    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getAllInvoices','getbranches','getcashiers','getusers']),
        ...mapState('items', ['items']),
        ...mapActions('items', ['getItems']),
        ...mapActions('items', ['removeinboundSearchedItem']),
        ...mapActions('items',['setforedit_inbound_searched_items','add_inbound_searched_items','reset_inbound_searched_items']),


        showmodaldata(id){
            this.selected_history=this.history.find((hs)=>hs.id==id)
            this.modalShow = ! this.modalShow
        },


        edit_inbound(dispatch){
          //  console.log(dispatch)
            this.dispatch_id=dispatch.id

            this.location_id=dispatch.location_id
            this.date=dispatch.date
            this.edit=true
            this.por=dispatch.purchase_order_ref
            this.setforedit_inbound_searched_items(dispatch.dispatchitems)
        },
        async approve_inbound(dispatch_id){

            const swalResult = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to do it?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            });

            if (swalResult.isConfirmed) {
                try {
                    const { data } = await apiService.get(`/receive-inbound-stock/${dispatch_id}`);
                    console.log(data.status);
                    await this.gethistory('inbound')

                } catch (error) {
                    // Handle errors, you might want to show a Swal alert for errors too
                    console.error('Error in approving:', error);
                    Swal.fire('Error!', 'An error occurred while processing the request.', 'error');
                }
            }
        },
        async  gethistory(mode){
            const {data} = await apiService.get(`/stock-history/${mode}`);

            this.history=data
            // console.log('history',this.history)
        },


        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, YYYY');
            //  let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },
        async getlocations(){
            const {data} = await apiService.get(`/locations`);
            //  console.log('branches', data)
            this.locations=data


        },
        addsearchitem(dummy='', itemcode=null,mapid=null){

            // alert(this.searchitem)
            //console.log((this.searched_items).length)
            if(dummy=='yes' || (this.inbound_searched_items).length==0) {
                this.add_inbound_searched_items(newitem)
            }
            else if(itemcode) {
                const   item=this.items.filter((i) => i.itemCode==itemcode)
                console.log(item,'item')
                if(item.length){
                    //  console.log(item[0])
                    this.add_inbound_searched_items(item[0])


                    const   dummyitem=(this.inbound_searched_items).find((i) => i.id==0)
                    console.log(dummyitem)
                    this.removeinboundSearchedItem(dummyitem.mapid)


                }
                else {
                    Swal.fire('Item Not Found', 'No item available for this code', 'info');
                    //  alert('not found')
                    this.removeitem(mapid)
                }
            }
            //  console.log(this.searched_items)

        },

        removeitem(id){
            //  await  Swal.fire('Removing ', 'You are removing item from invoice', 'info');
            this.removeinboundSearchedItem(id)

        },


        formattedDate(date) {

            const formattedDate = moment(date).format('DD-MM-YYYY');

            return formattedDate;
        },


        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.items.length==0){
                //   alert(this.branches.length)
               // await this.getItems();

            }
            if(this.users.length==0){
                await this.getusers()
                ///console.log(this.users)
            }
        },



        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.gray{
    background-color: gray !important;
}
.input-container {
    display: inline-flex; /* Use inline-flex to place the elements in the same line */
    align-items: center; /* To vertically center the elements */
}

.hr0{
    margin-top: 5px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid green;
    max-width: 100%;

}



</style>
