// import apiService from "../../service/apiService";

import apiService from "@/service/apiService";
import newpayment from "@/constants/newpayment";
import {cloneDeep} from "lodash";

export default {
    namespaced: true,
    state: () => ({
        payment: cloneDeep(newpayment),

        payments:[]


    }),
    mutations: {




        setPayments(state, payload){

            state.payments=payload
        },
        setNewpayment(state, payload){

            state.payment=payload
        },




    },


    actions: {



        async getpayments({ commit }){
            const {data} = await apiService.get(`/payments`);
            //  console.log('cashiers', data)

            commit('setPayments', data)
        },
        setNewpayment({ commit }, payload){
            commit('setNewpayment', payload);
        },




    },


}
